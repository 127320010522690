import { useTranslation } from "react-i18next";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

export default function useVendorDetailsSchemaFn() {
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      name: {
        type: "string",
        title: translate("nameOfVendor"),
      },
      vendorType: {
        title: translate("vendorType"),
        enum: ["Manufacturer", "Trader", "Broker"],
        enumNames: [
          translate("manufacturer"),
          translate("trader"),
          translate("broker"),
        ],
      },
      completeAddress: {
        type: "string",
        title: translate("address"),
      },
      licenseNumber: {
        type: "string",
        title: translate("LicenseNo"),
      },
      accreditedByAgency: {
        type: "boolean",
        default: "false",
        title: translate("manufAccreditedByAgency"),
      },
    },
    required: ["name"],
  };
}
