import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();
const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const FORTIFICATION_URL_RICE = process.env.REACT_APP_BASE_FORTIFICATION_URL_RICE;
const IAM_URL_RICE = process.env.REACT_APP_BASE_IAM_URL_RICE;

export const getAllUser = async (pageNumber, pageSize, searchCriteriaList) => {
  return _axios
    .post(
      `${IAM_URL}user/all-users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getAllCategory = async () => {
  return _axios
    .get(`${FORTIFICATION_URL}category/role-categories`)
    .catch((error) => {
      console.log(error);
    });
};

export const getAllVendorsForTargetManufacturer = async (categoryId, searchStr, pageNo, pageSize) => {
  return _axios
    .get(`${IAM_URL_RICE}manufacturer/vendor/list/${categoryId}`)
    .catch((error) => {
      console.log(error);
    });
};

export const createSourceManufacturerAsVendorForTarget = async (body) => {//
  return _axios
    .post(`${IAM_URL_RICE}manufacturer/self-declared/vendor`, body)
    .catch((error) => {
      console.log(error);
    });
};

export const getAllRole = async () => {
  return _axios.get(`${IAM_URL}role`).catch((error) => {
    console.log(error);
  });
};
export const getAllStatus = async () => {
  return _axios.get(`${IAM_URL}status`).catch((error) => {
    console.log(error);
  });
};
export const createUser = async (body) => {
  return _axios.post(`${IAM_URL}user`, body).catch((error) => {
    console.log(error);
  });
};

export const getUserDetails = async (userId) => {
  return _axios.get(`${IAM_URL}user/${userId}`).catch((error) => {
    console.log(error);
  });
};

export const editUserDetails = async (userId, body) => {
  return _axios.put(`${IAM_URL}user/${userId}`, body).catch((error) => {
    console.log(error);
  });
};

export const manufacturerDetails = async () => {
  return _axios.get(`${IAM_URL_RICE}user/details`).catch((error) => {
    console.log(error);
  });
};

export const resetPassword = async (id, body) => {
  return _axios
    .put(`${IAM_URL_RICE}user/${id}/reset-password`, body)
    .catch((error) => {
      console.log(error);
    });
};

export const getAgencyDistrictExcel = async (reportType, body, agencyName, districtGeoId, categoryId) =>{
  let agencyNameParamStr = ''
  let districtGeoIdParamStr = ''
  let categoryStr = ''
  if(agencyName){
    agencyNameParamStr = "&agencyName=" + agencyName
  }
  if(districtGeoId){
    districtGeoIdParamStr = "&districtGeoId=" + districtGeoId
  }
  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }
  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity-excel/${reportType}?type=country&geoId=2${agencyNameParamStr}${districtGeoIdParamStr}${categoryStr}`, body, { responseType: 'blob' })
  .then((response=>{
    const blob = new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      return url
  }))
  .catch((error) => {
    console.log(error);
  });
}

export const getExcelsForReportingGrids = async (body,isEmpaneledData, empanelledStateGeoId, fileName) =>{//

  const now = new Date();
  const formattedDate = now.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replaceAll('/','-');
  
  const formattedTime = now.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  .replaceAll(':','-');

  const newKey = (body['dashboardRequestDto']['type'] == 'country') ? 'sourceCountryGeoId' : (body['dashboardRequestDto']['type'] == 'state') ? 'sourceStateGeoId' : 'sourceDistrictGeoId'
  // let newBody = {
  //   ...body,
  //   [newKey]: body['dashboardRequestDto']['geoId']
  // }

  let newDashboardRequestDto = {
    ...body['dashboardRequestDto'],
    [newKey]: body['dashboardRequestDto']['geoId']
  }

  if(isEmpaneledData){
    newDashboardRequestDto = {
      ...newDashboardRequestDto,
      empanelledStateGeoId : empanelledStateGeoId
    }
  }

  const {geoId, ...updatedNewDashboardRequestDto} = newDashboardRequestDto;

  let newBody = {
    ...body,
    dashboardRequestDto: updatedNewDashboardRequestDto,
    // reportType: body['reportType']

  }

  // const {geoId, ...updatedNewBody} = newBody;

  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/quantity/generate-excel`,newBody , { responseType: 'blob' })
  .then((response=>{
    const blob = new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}_${formattedDate}T${formattedTime}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
  }))
  .catch((error) => {
    console.log(error);
  });
}

export const getAgencyAndDistrictData = async (reportType, body, agencyName, districtGeoId, categoryId) => {
  let agencyNameParamStr = ''
  let districtGeoIdParamStr = ''
  let categoryStr = ''
  if(agencyName){
    agencyNameParamStr = "&agencyName=" + agencyName
  }
  if(districtGeoId){
    districtGeoIdParamStr = "&districtGeoId=" + districtGeoId
  }
  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }
  return _axios
    .post(`${FORTIFICATION_URL}dashboard/quantity/${reportType}?type=country&geoId=2${agencyNameParamStr}${districtGeoIdParamStr}${categoryStr}`, body)
    .catch((error) => {
      console.log(error);
    });
    
};

export const getDistrictDataOnAgencyClick = async(body, agencyName, categoryId ) =>{
  let agencyNameParamStr = '';
  let categoryStr = '';
  if(agencyName){
    agencyNameParamStr = "&agencyName=" + agencyName
  }
  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }

  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/district-agency-data?type=country&geoId=2${agencyNameParamStr}${categoryStr}`, body)
  .catch((error) => {
    console.log(error);
  });
}

// export const getDistrictCombinedData = async(body, districtGeoId, categoryId ) =>{
//   let districtGeoIdParamStr = '';
//   let categoryStr = '';
//   if(districtGeoId){
//     districtGeoIdParamStr = "&districtGeoId=" + districtGeoId
//   }
//   if(categoryId){
//     categoryStr="&categoryId="+categoryId
//   }

//   return _axios
//   .post(`${FORTIFICATION_URL}dashboard/quantity/district-data?type=country&geoId=1${districtGeoIdParamStr}${categoryStr}`, body)
//   .catch((error) => {
//     console.log(error);
//   });
// }

export const getDistrictCombinedExcel = async(body, districtGeoId, categoryId ) =>{
  let districtGeoIdParamStr = '';
  let categoryStr = '';
  if(districtGeoId){
    districtGeoIdParamStr = "&districtGeoId=" + districtGeoId
  }
  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }

  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/district-excel?type=country&geoId=1${districtGeoIdParamStr}${categoryStr}`, body, { responseType: 'blob' })
  .then((response=>{
    const blob = new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      return url
  }))
  .catch((error) => {
    console.log(error);
  });
}

export const getDistrictAggregates=(categoryId, stateGeoId, sourceManufId, body)=>{
  let stateGeoIdParamStr = '';
  let categoryStr = '';
  let sourceManufIdStr = ''
  if(stateGeoId){
    stateGeoIdParamStr = "&stateGeoId=" + stateGeoId
  }
  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }
  if(sourceManufId){
    sourceManufIdStr = '&sourceManufacturerId='+sourceManufId 
  }
  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/district-aggregates?${stateGeoIdParamStr}${categoryStr}${sourceManufIdStr}`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getAgencyAggregates = (body, isEmpaneledData,empanelledStateGeoId)=>{
  // let categoryStr = '';
  // let typeStr = '&type=country&geoId=1'
  // if(categoryId){
  //   categoryStr="&categoryId="+categoryId
  // }
  // if(type){
  //   if(['2','4'].includes(categoryId)){
  //   } else{

  //     typeStr = `&type=${type}&geoId=${geoId}`
  //   }
  // }
  const newKey = (body['type'] == 'country') ? 'sourceCountryGeoId' : (body['type'] == 'state') ? 'sourceStateGeoId' : 'sourceDistrictGeoId'
  let newBody = {
    ...body,
    [newKey]: body['geoId']
  }

  const {geoId, ...updatedNewBody} = newBody;

  let bodyWithEmpaneledStateGeoId = updatedNewBody
  let isEmpaneledUrl = ''
  if(isEmpaneledData){
    isEmpaneledUrl = '/empanel'
    bodyWithEmpaneledStateGeoId = {
      ...updatedNewBody,
      empanelledStateGeoId : empanelledStateGeoId
    }
  }

  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/reports${isEmpaneledUrl}/quantity/agency`, bodyWithEmpaneledStateGeoId)
  .catch((error) => {
    console.log(error);
  });
}

export const getAllLots = (districtGeoId, sourceManuId, targetManuId, body)=>{
  let districtGeoIdParamStr = '';

  let sourceManuStr = ''
  let targetManuStr = ''

  if(sourceManuId){
    sourceManuStr='&sourceManufacturerId='+sourceManuId
  }
  if(targetManuId){
    targetManuStr='&targetManufacturerId='+targetManuId
  }
  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/reports/quantity/lots?${sourceManuStr}${targetManuStr}`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getBatchesByManufacturerForMonitor = (categoryId, fromDate, toDate, manufacturerId, responseType)=>{

const responseTypeStr = responseType ? `&responseType=${responseType}` : ""

const body= {
  'categoryId': categoryId,
  'fromDate': fromDate,
  'toDate': toDate
}

  return _axios
  .post(`${FORTIFICATION_URL}dashboard/list/batches?manufacturerId=${manufacturerId}${responseTypeStr}`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getWarehouseAggregates = (type, geoId, body) => {

  let typeStr = ''

  if(type){
    typeStr = `&type=${type}&geoId=${geoId}`
  }
  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/warehouse-aggregates?${typeStr}`, body)
  .catch((error) => {
    console.log(error);
  });

}

export const getWarehouseList = (body, isEmpaneledData, empanelledStateGeoId, categoryId) => {

  const newKey = (body['type'] == 'country') ? 'sourceCountryGeoId' : (body['type'] == 'state') ? 'sourceStateGeoId' : 'sourceDistrictGeoId'
  let newBody = {
    ...body,
    [newKey]: body['geoId']
  }

  let { geoId, ...updatedNewBody } = newBody;
  let empaneledUrl = ''

  if (isEmpaneledData) {
    updatedNewBody = {
      ...updatedNewBody,
      empanelledStateGeoId: empanelledStateGeoId
    }
    empaneledUrl = '/empanel'
  }
  return _axios
    .post(`${FORTIFICATION_URL_RICE}dashboard${empaneledUrl}/reports/quantity/warehouse?categoryId=${categoryId}`, updatedNewBody)
    .catch((error) => {
      console.log(error);
    });
}

export const getSourceWarehouseAggregates = (districtGeoId, body) => {

  let districtGeoIdStr = ''

  if(districtGeoId){
    districtGeoIdStr = `&districtGeoId=${districtGeoId}`
  }
  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/source-warehouse-aggregates?${districtGeoIdStr}`, body)
  .catch((error) => {
    console.log(error);
  });

}

export const getSourceManufacturersForTarget = (targetManufacturerId, body, isEmpaneledData, empanelledStateGeoId) =>{

  let isEmpaneledUrl = ''

  if(isEmpaneledData){
    isEmpaneledUrl = '/empanel'
    body = {
      ...body,
      empanelledStateGeoId : empanelledStateGeoId
    }
  }
  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/reports${isEmpaneledUrl}/quantity/source-manufacturer?targetManufacturerId=${targetManufacturerId}`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getSourceManufAggregates=(categoryId, districtGeoId, body)=>{//from district id
  let categoryStr = '';
  let districtGeoIdParamStr = '';

  if(categoryId){
    categoryStr="&categoryId="+categoryId
  }
  if(districtGeoId){
    districtGeoIdParamStr = '&districtGeoId=' + districtGeoId
  }
  return _axios
  .post(`${FORTIFICATION_URL}dashboard/quantity/source-manufacturer-aggregates?${districtGeoIdParamStr}${categoryStr}`, body)
  .catch((error) => {
    console.log(error);
  });
}

export const getTargetManufAggregates=(sourceManufId, body,  isEmpaneledData,empanelledStateGeoId)=>{
  // let categoryStr = '';
  // let districtGeoIdParamStr = '';
  let sourceManufIdStr = ''
  // if(categoryId){
  //   categoryStr="&categoryId="+categoryId
  // }
  // if(districtGeoId){
  //   districtGeoIdParamStr = '&districtGeoId=' + districtGeoId
  // }
  if(sourceManufId){
    sourceManufIdStr = '&sourceManufacturerId='+sourceManufId 
  }

  let isEmpaneledUrl = ''

  if(isEmpaneledData){
    isEmpaneledUrl = '/empanel'
    body = {
      ...body,
      empanelledStateGeoId : empanelledStateGeoId
    }
  }

  return _axios
  .post(`${FORTIFICATION_URL_RICE}dashboard/reports${isEmpaneledUrl}/quantity/target-manufacturer?${sourceManufIdStr}`, body)
  .catch((error) => {
    console.log(error);
  });

}

export const checkUserName = async (userName) => {
  return _axios
    .get(`${IAM_URL}user/check-details?userName=${userName}`)
    .catch((error) => {
      console.log(error);
    });
};

export const checkEmail = async (emailId) => {
  return _axios
    .get(`${IAM_URL}user/check-details?email=${emailId}`)
    .catch((error) => {
      console.log(error);
    });
};

export const removeRole = async (userName, roleCategoryId) => {
  return _axios
    .put(
      `${IAM_URL}role/remove`, {
        userName, roleCategoryId
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const addRole = async (userName, body) => {
  return _axios
    .post(`${IAM_URL}role/assign-role`, {userName, roles: body})
    .catch((error) => {
      console.log(error);
    });
};
