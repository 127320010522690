import React, { useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { notificationCount, notificationLastSeen, notificationList } from '../../services/NotifcationServices';
import { useEffect } from 'react';
import {  useAppDispatch, useAppSelector } from '../../services/StoreHooks';
import { renderNotificationTitleWithChips } from '../../pages/Notification Page/NotificationChips';
import { handleNavigate } from '../../pages/Notification Page/NotificationPath';
import useTranslatewrapper from '../useTranslateWrapper';

const IconWithContent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const {translate} = useTranslatewrapper()
  const [notificationData, setNotificationData] = useState([]);
  const notificationCountRedux = useAppSelector((state) => { return state.notificationCount.count });
  const dispatch = useAppDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigatePage = () => {
    fetchnotificationCount();
    handleClose();
    navigate(`/notification`);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [listCount, setListCount] = useState(0);

  useEffect(() => {
    fetchCount();
  }, [notificationCountRedux]);

  useEffect(() => {
    fetchNotificationList(listCount);
  }, [listCount]);


  const fetchCount = () => {
    switch (true) {
      case notificationCountRedux === 0:
        setListCount(0);
        break;
      case notificationCountRedux >= 5:
        setListCount(5);
        break;
      default:
        setListCount(notificationCountRedux);
        break;
    }
  };

  const fetchNotificationList = async (count) => {
    const data = await notificationList(1, count);
    setNotificationData(data?.data?.data);
  };

  // Fetch notification count after last seen and update redux store

  const fetchnotificationCount = async () => {
     // Call last seen API(put api) to mark notifications as seen
    const seen = await notificationLastSeen();
     if(seen){
       const data = await notificationCount();
       if (data.data || data.data === 0 ) {
         dispatch({
             type: "SHOW_COUNT",
             data: data?.data,
         });
       }
     } 
  };
  return (
    <div style={{ color: 'black' }} >
      {notificationCountRedux ? <Badge badgeContent={notificationCountRedux} onClick={handleClick} color="error" sx={{ mr: 3, fontSize: '15' }}>
        <NotificationsIcon color="primary" />
      </Badge> :
        <NotificationsIcon color="primary" onClick={handleNavigatePage} sx={{ mr: 3, fontSize: '15' }} />}
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        placement={'bottom-end'}
        style={{ maxWidth: '400px' }} 
      >
        <div style={{ maxHeight: '300px', width: '100%', overflowX: 'hidden', wordBreak: 'break-word' }}>
          {notificationData?.map((data) => (
            <MenuItem
              key={data.id} 
              value={data.id}
              onClick={() => {
                navigate(handleNavigate(data));
                handleClose();
              }}
              sx={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {renderNotificationTitleWithChips(data.notificationTitle, translate)}
            </MenuItem>
          ))}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
            <Button onClick={handleNavigatePage} sx={{ width: '100%' }}>
              {translate('viewAllNotifications')}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default IconWithContent;
