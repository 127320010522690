import React, { useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import AgencyGrid from "./AgencyGrid";
import DistrictGrid from "./DistrictGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getAgencyAggregates,
  getAgencyAndDistrictData,
  getAgencyDistrictExcel,
  getDistrictAggregates,
  // getDistrictCombinedData,
  getDistrictCombinedExcel,
  getDistrictDataOnAgencyClick,
  getExcelsForReportingGrids,
  getSourceManufAggregates,
  getSourceManufacturersForTarget,
  getSourceWarehouseAggregates,
  getTargetManufAggregates,
  getWarehouseAggregates,
  getWarehouseList,
} from "../../services/Manage_service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../services/StoreHooks";
import DistrictWithAgencyGrid from "./DistrictWithAgencyGrid";
import {
  getDistrict,
  getDistrictsByStateGeoId,
  getState,
} from "../../services/adminService";
import MenuItem from "@mui/material/MenuItem";
import PremixGrid from "../../pages/Dashboard/PremixGrid";
import KeycloakService from "../../services/KeycloakService";
import {Tab, Tabs } from '@mui/material'
import { getLabAggregates, getLabSamplesList } from "../../services/FortificationService";


export default function ReportingGrid() {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const categoryIdToNameMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.id.toString()] = current.name;
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = KeycloakService.getUserDetail();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(userDetails, 'userdetails')
  const geoId = queryParams.get('geoId') || userDetails.stateGeoId || '2'; //|| userDetails.districtGeoId
  const level = queryParams.get('level') || (userDetails.stateGeoId ? 'state' : 'country'); // : userDetails.districtGeoId ? 'district' 
  const viewFromUrlOrDefault = queryParams.get('view') || '1'
  const [isEmpaneledData, setIsEmpaneledData] = useState(viewFromUrlOrDefault == '2')
  // console.log(level, 'level')
  const routeParams = useParams();
  const [selectedTab, setSelectedTab] = useState(viewFromUrlOrDefault);
  const [stateDisabled, setStateDisabled] = useState(false)
  const [category, setCategory] = useState(null);
  const [maxStartDateAccToEndDate, setMaxStartDateAccToEndDate] = useState("");
  const [nfpbFlowCategoryType, setNfpbFlowCategoryType] = useState(categoryNameToIdMapRedux['FRK']);
  const [StartDate, setStartDate] = useState(moment().subtract(6, 'months').format('MM-DD-YYYY'));
  const [EndDate, setEndDate] = useState(moment().format('MM-DD-YYYY'));
  const [reportType, setReportType] = useState(null);
  // const [showAgencyGrid, setShowAgencyGrid] = useState(false);
  // const [showDistrictGrid, setShowDistrictGrid] = useState(false);
  // const [showDistrictWithAgencyGrid, setShowDistrictWithAgencyGrid] =
  //   useState(false);
  // const [showPremixGrid, setShowPremixGrid] = useState(false);
  const [gridsVisibility, setGridsVisibility] = useState({
    showAgencyGrid: false,
    showDistrictGrid: false,
    showPremixGrid: false,
  });

  const [gridsData, setGridsData] = useState({
    agencyRowData: [],
    districtRowData: [],
  });
  const [searchKeyWord, setSearchKeyWord] = useState("");
  // const [agencyRowData, setAgencyRowData] = useState([]);
  // const [districtRowData, setDistrictRowData] = useState([]);
  // const [districtWithAgencyGridRowData, setDistrictWithAgencyGridRowData] =
  //   useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [selectedState, setSelectedState] = useState(level=='state' ? geoId : '');

  // const getDistrictListFromState = async(stateId) => {
  //   const districtData = await getDistrictsByStateGeoId(stateId);
  //   setDistrictList(districtData.data);
  // }

  // if(level=='state'){
  //   getDistrictListFromState()
  //   // console.log(DistrictData.data)

  // }



  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [query, setQuery] = useState({
    fromDate: "",
    toDate: "",
    stateGeoId: 28,
    districtGeoId: "",
    // sourceManufacturerId: "",
    // targetManufacturerId: "",
    type: "",
    categoryId: "",
    geoId: '',
    level:'',
    selectedPremixAgencyName:'',
    selectedFrkAgencyName:'',
    selectedMillerAgencyName:'',
    selectedSchoolAgencyName:'',
    labId: '',
    labDataType: ''
  });
  // const [agencyDownloadUrl, setAgencyDownloadUrl] = useState(null);
  // const [districtDownloadUrl, setDistrictDownloadUrl] = useState(null);
  // const [agencyWithDistrictDownloadUrl, setAgencyWithDistrictDownloadUrl] =
  //   useState(null);
  const [gridsStatus, setGridsStatus] = useState({
    isDistrictDone: false,
    isSourceManufDone: false,
    isTargetManufGrid: false,
  });
  const [navigationArray, setNavigationArray] = useState([]);

  const [heading, setHeading] = useState('')

  const [gridsFlows, setGridsFlows] = useState({
    agencyFlow: [
      "agency-all",
      // "agency-districtsForAgency",
      "agency-targetManufacturers",
      "agency-lotList",
    ],
    districtFlow: [
      "district-all",
      "district-agenciesForDistrict",
      "district-targetManufacturers",
      "district-lotList",
    ],
    schoolFlow: [
      "school-all",
      "school-miller",
      "school-frk",
      "school-premix",
    ],
    labFlow: [
      "labs-all",
      "labsForParticularData"
    ]
  });

  const [fileNameForExcel, setFileNameForExcel] = useState('')
  const categoryNameMap = {
    [categoryNameToIdMapRedux['PREMIX']] : 'Premix',
    [categoryNameToIdMapRedux['FRK']]: 'FRK',
    [categoryNameToIdMapRedux['MILLER']]:'Miller',
    [categoryNameToIdMapRedux['SCHOOL']]:'School',
    [categoryNameToIdMapRedux['NFPB']]:'NFPB'

  }

  const categoryNameMapForHeadings = {
    [categoryNameToIdMapRedux['PREMIX']] : 'Premix',
    [categoryNameToIdMapRedux['FRK']]: 'FRK',
    [categoryNameToIdMapRedux['MILLER']]:'Miller(FR)',
    [categoryNameToIdMapRedux['NFPB']]:'NFPB',
    [categoryNameToIdMapRedux['SCHOOL']]:'School'
  }

  const categoryTargetNameMap = {
    [categoryNameToIdMapRedux['PREMIX']] : 'FRK',
    [categoryNameToIdMapRedux['FRK']]: 'NFPB',
    [categoryNameToIdMapRedux['MILLER']]:'School',
  }

  let targetCategoryMapForSchool = 
  // (categoryType == categoryNameToIdMapRedux['FRK'])
  // ? {
  //   'school-premix': 'Frk',
  //   'school-frk':'School'
  // } : 
  {
    'school-premix': 'Frk',
    'school-frk':'Miller',
    'school-miller': 'School'
  };



  useEffect(()=>{
    if(selectedState){

      (async ()=>{

        const districtData = await getDistrictsByStateGeoId(selectedState);
        setDistrictList(districtData.data);
      })()
    } else{
      setDistrictList([])
    }
  }, [selectedState])

  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader,
      },
    });
  };

  useEffect(() => {
    setDateFilters({ ...dateFilters, fromDate: StartDate });
  }, [StartDate]);

  useEffect(() => {
    setDateFilters({ ...dateFilters, toDate: EndDate });
  }, [EndDate]);

  const getStateData = async () => {
    const StateData = await getState(2);
    StateData && setStateList(StateData.data.data);
  };

  useEffect(() => {
    getStateData();
  }, []);

  const getHeadingFromQuery = (query) => {
    switch (query['type']) {
      case 'agency-all':
        // if(routeParams.categoryId == categoryNameToIdMapRedux['MILLER']){
          // setHeading(categoryNameMapForHeadings[categoryType] + ' Agencies')
        // } else{
          setHeading(categoryNameMapForHeadings[query['categoryId']] + ' Agencies')
          setFileNameForExcel(`${categoryNameMapForHeadings[query['categoryId']]}_agencies`)
        // }
        break;
        
      case 'labsForParticularData':
          const samplesHeadingMapForDifferentTypes = {
            'samplesReceived' : 'Samples Received at Lab',
            'samplesRejected' : 'Samples Rejected Without Testing',
            'samplesInLab': 'Samples In Lab',
            'samplesToReceive': 'Samples To Receive',
            'testsDone' : 'No. of Tests Performed'
          }
          setHeading(`${categoryNameMapForHeadings[routeParams.categoryId]} ${samplesHeadingMapForDifferentTypes[query['labDataType']]}`)
          setFileNameForExcel(`${categoryNameMapForHeadings[routeParams.categoryId]}_${samplesHeadingMapForDifferentTypes[query['labDataType']].split(' ')?.join('_')}`)
          break;
      case 'labs-all':
        setHeading(categoryNameMapForHeadings[query['categoryId']] + ' Labs')
        setFileNameForExcel(`${categoryNameMapForHeadings[query['categoryId']]}_labs`)

        break;


      case 'agency-targetManufacturers':
        setHeading(query[`selected${categoryNameMap[query['categoryId']]}AgencyName`]
          + ` agency dispatched ${categoryNameMapForHeadings[query['categoryId']]} lots to these ${categoryTargetNameMap[query['categoryId']]} agencies`);
          
        let tempFileName = `${query[`selected${categoryNameMap[query['categoryId']]}AgencyName`]?.trim()?.split(' ')?.slice(0, 3)?.join('_')}_dispatched_${categoryNameMapForHeadings[query['categoryId']]}_lots_to_these_agencies`
        setFileNameForExcel(tempFileName);
        break;

      case 'agency-batchesList':
        setHeading(categoryNameMapForHeadings[query['categoryId']] + " Batches created by " + query[`selected${categoryNameMap[query['categoryId']]}AgencyName`]  )
        // setFileNameForExcel(`${categoryNameMapForHeadings[query['categoryId']]}_batches_created_by_${query[`selected${categoryNameMap[query['categoryId']]}AgencyName`].split(' ')?.slice(0, 3)?.join('_')}`) 
        break;

      case 'school-all':
        setHeading(`School Agencies`)
        setFileNameForExcel(`school_agencies`)
        break;

      case 'school-miller':
        setHeading(`${query['selectedSchoolAgencyName']} received FR lots from these agencies`)
        break;

      case 'school-frk':
        // if(categoryType == categoryNameToIdMapRedux['FRK']){
          // setHeading(`${query['selectedSchoolAgencyName']} received FRK lots from these agencies`)

        // } else if(categoryType == categoryNameToIdMapRedux['MILLER']){

          setHeading(`${query['selectedMillerAgencyName']} received FRK lots from these agencies`)
        // }
        break;

      case 'school-premix':
        setHeading(`${query['selectedFrkAgencyName']} received Premix lots from these agencies`)
        break;

      case 'agency-lotList':
      case 'school-lotList':
        if(categoryNameMap[query['categoryId']] == 'Miller'){
          setHeading('FR Lot List')
        } else {
          setHeading(`${categoryNameMap[query['categoryId']]} Lot list`)
        }
        break;

      default:
        setHeading('')
        break;
    }
  }

  const getData = async () => {

    setReportType(routeParams.reportType);
    setCategory(routeParams.categoryId);
    if((selectedTab == '1') || (routeParams.reportType == 'lab') ){
      setSelectedState(queryParams.get('geoId') || userDetails.stateGeoId || '')
      setSelectedTab('1')
    } else{
      setSelectedState(queryParams.get('geoId') || '')
    }
    setSelectedDistrict("");
    // setDistrictList([]);
    setStartDate(moment().subtract(6, 'months').format('MM-DD-YYYY'));
    setEndDate(moment().format('MM-DD-YYYY'));

    if (routeParams.reportType == "agency") {
      let type = [categoryNameToIdMapRedux['SCHOOL']].includes(routeParams.categoryId)
        ? "school-all"
        : "agency-all";

      setQuery((prevQuery) => ({
        ...prevQuery,
        type: type,
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        sourceManufacturerId: "",
        targetManufacturerId: "",
        categoryId: routeParams.categoryId,
        level: (selectedTab == '2') ? (queryParams.get('level') || 'country') : level,
        geoId: (selectedTab == '2') ? (queryParams.get('geoId') || '2') : geoId,
        isEmpaneledData: isEmpaneledData,
        labId: '',
        labDataType : ''

      }));
    } else if (routeParams.reportType == "district") {
      let type = [categoryNameToIdMapRedux['SCHOOL']].includes(routeParams.categoryId)
        ? "school-all"
        : "district-all";
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: type,
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        sourceManufacturerId: "",
        targetManufacturerId: "",
        categoryId: routeParams.categoryId,
        level: level,
        geoId: geoId
      }));
    } else if(routeParams.reportType == "lab"){
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: 'labs-all',
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        categoryId: routeParams.categoryId,
        level: level, //(selectedTab == '2') ? (queryParams.get('level') || 'country') : level,
        geoId: geoId, //(selectedTab == '2') ? (queryParams.get('geoId') || '1') : geoId,
        isEmpaneledData: false, //isEmpaneledData
        labId: '',
        labDataType : ''

      }));
    }

  };

  useEffect(() => {

    if (
      //without this ifelse; upon clicking a row, getData is getting called again
      routeParams.categoryId != category ||
      routeParams.reportType != reportType
    ) {
      if((routeParams.reportType === 'lab') && isEmpaneledData){
        setIsEmpaneledData(false) // getData will be called in the useEffect when isEmpaneledData changes
      } else{
      getData();
      }

    }
    // }, [routeParams]);
  }, [routeParams.categoryId, routeParams.reportType]);

  const getDataOnQueryChange = async () => {
    getHeadingFromQuery(query)

    dispatchShowLoader(true);
    try {
      if (query["type"] == "agency-all") {
        let dataFromApi = [];
        if (["PREMIX", "FRK", "MILLER"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {

            dataFromApi = await getAgencyAggregates(
              {
                fromDate: query["fromDate"],
                toDate: query["toDate"],
                categoryId: routeParams.categoryId,
                type: query['level'],
                geoId: query['geoId']
              },
              isEmpaneledData,
              userDetails.stateGeoId
            );
        } else if (["NFPB"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {
          if(nfpbFlowCategoryType === categoryNameToIdMapRedux['FRK']){
            dataFromApi = await getWarehouseList({
              fromDate: query["fromDate"],
              toDate: query["toDate"],
              type: query['level'],
              geoId: query['geoId'],
              categoryId:categoryNameToIdMapRedux['FRK']
            },
              isEmpaneledData,
              userDetails.stateGeoId,
              categoryNameToIdMapRedux['NFPB'],
            )
          } else if(nfpbFlowCategoryType === categoryNameToIdMapRedux['MILLER']){
            dataFromApi = await getAgencyAggregates(
              {
                fromDate: query["fromDate"],
                toDate: query["toDate"],
                categoryId: routeParams.categoryId,
                type: query['level'],
                geoId: query['geoId']
              },
              isEmpaneledData,
              userDetails.stateGeoId
            );
          }
        }

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsData({
          agencyRowData: dataFromApi?.data ? dataFromApi?.data : [],
          districtRowData: [],
        });
        setGridsStatus({
          isDistrictDone: false,
          isSourceManufDone: true,
          isTargetManufGrid: false,
        });
      } else if (query["type"] == "agency-districtsForAgency") {
        let tempCategory = category;
        // if([9,'9'].includes(category)){
        //   tempCategory = '5'
        // } else{
        //   tempCategory = category
        // }
        //show district grid
        const districtDataForSelectedAgency = await getDistrictAggregates(
          tempCategory,
          "",
          query["sourceManufacturerId"],
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
          }
        );

        setGridsData({
          agencyRowData: [],
          districtRowData: districtDataForSelectedAgency?.data,
        });

        setGridsVisibility({
          showAgencyGrid: false,
          showDistrictGrid: true,
          showPremixGrid: false,
        });

        setGridsStatus({
          ...gridsStatus,
          isDistrictDone: true,
        });
      } else if (query["type"] == "agency-targetManufacturers") {
        const getDataFromApi = await getTargetManufAggregates(

          // query["districtGeoId"],
          query["sourceManufacturerId"],
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            categoryId: category
            // type: 'country',
            // geoId: '1'
          },
          isEmpaneledData,
          userDetails.stateGeoId,

        );

        // setHeading('Target Manufacturers')

        setGridsData({
          agencyRowData: getDataFromApi?.data,
          districtRowData: [],
        });

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsStatus({
          ...gridsStatus,
          isTargetManufGrid: true,
        });
      } else if ((query["type"] == "agency-lotList") || (query["type"] == "school-lotList")) {
        setGridsVisibility({
          showAgencyGrid: false,
          showDistrictGrid: false,
          showPremixGrid: true,
        });

        setGridsData({
          agencyRowData: [],
          districtRowData: [],
        });
      } else if(query['type'] === 'agency-batchesList'){
        setGridsVisibility({
          showAgencyGrid: false,
          showDistrictGrid: false,
          showPremixGrid: true,
        });

        setGridsData({
          agencyRowData: [],
          districtRowData: [],
        });
      } else if (query["type"] == "district-all") {
        let tempCategory = routeParams.categoryId;

        const dataFromApi = await getDistrictAggregates(tempCategory, 28, "", {
          fromDate: query["fromDate"],
          toDate: query["toDate"],
        });

        setGridsData({
          agencyRowData: [],
          districtRowData: dataFromApi?.data,
        });

        setGridsVisibility({
          showAgencyGrid: false,
          showDistrictGrid: true,
          showPremixGrid: false,
        });

        setGridsStatus({
          isDistrictDone: true,
          isSourceManufDone: false,
          isTargetManufGrid: false,
        });
      } else if (query["type"] == "district-agenciesForDistrict") {
        let dataFromApi = [];
        if (["PREMIX", "FRK", "MILLER"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {
          dataFromApi = await getSourceManufAggregates(
            category,
            query["districtGeoId"],
            {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
            }
          );
        } else {
          //in the else, data is from school
          dataFromApi = await getSourceWarehouseAggregates(
            query["districtGeoId"],
            {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
            }
          );
        }

        setGridsData({
          agencyRowData: dataFromApi?.data,
          districtRowData: [],
        });

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsStatus({
          ...gridsStatus,
          isSourceManufDone: true,
        });
      } else if (query["type"] == "district-targetManufacturers") {
        const getDataFromApi = await getTargetManufAggregates(
          category,
          query["districtGeoId"],
          query["sourceManufacturerId"],
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
          },
          isEmpaneledData,
          userDetails.stateGeoId
        );

        setGridsData({
          agencyRowData: getDataFromApi?.data,
          districtRowData: [],
        });

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsStatus({
          ...gridsStatus,
          isTargetManufGrid: true,
        });
      } else if (query["type"] == "district-lotList") {
        setGridsVisibility({
          showAgencyGrid: false,
          showDistrictGrid: false,
          showPremixGrid: true,
        });
      } else if (query['type'] == 'school-all'){

        // setHeading('List of Schools')

        const dataFromApi = await getWarehouseList({
          fromDate: query["fromDate"],
          toDate: query["toDate"],
          type: query['level'],
          geoId: query['geoId'],
          // categoryId:categoryNameToIdMapRedux['SCHOOL']
          categoryId:categoryNameToIdMapRedux['MILLER']
        },
          isEmpaneledData,
          userDetails.stateGeoId,
          categoryNameToIdMapRedux['SCHOOL'],//check
        )

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsData({
          agencyRowData: dataFromApi?.data,
          districtRowData: [],
        });
        setGridsStatus({
          isDistrictDone: false,
          isSourceManufDone: true,
          isTargetManufGrid: false,
        });


        // } else if (query['type'] == 'school-miller' || ){
      } else if(['school-miller', 'school-frk', 'school-premix'].includes(query['type'])){
        // let catId = (query['type'] == 'school-miller') ? categoryType : (query['type'] == 'school-frk') ? categoryNameToIdMapRedux['FRK'] : categoryNameToIdMapRedux['PREMIX']
        let catId = (query['type'] == 'school-miller') ? categoryNameToIdMapRedux['MILLER'] : (query['type'] == 'school-frk') ? categoryNameToIdMapRedux['FRK'] : categoryNameToIdMapRedux['PREMIX']

        const dataFromApi = await getSourceManufacturersForTarget(
          query['targetManufacturerId'],
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            categoryId: catId,
          },
          isEmpaneledData,
          userDetails.stateGeoId)

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsData({
          agencyRowData: dataFromApi?.data,
          districtRowData: [],
        });
        setGridsStatus({
          isDistrictDone: false,
          isSourceManufDone: true,
          isTargetManufGrid: false,
        });

      } else if (query['type'] == 'school-frk'){
      } else if (query['type'] == 'school-premix'){

      } else if(query['type'] === 'labs-all'){
        const dataFromApi = await getLabAggregates(
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            categoryId: routeParams.categoryId,
            type: query['level'],
            geoId: query['geoId']
          },
          isEmpaneledData,
          userDetails.stateGeoId
          )

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsData({
          agencyRowData: dataFromApi?.data?.data,
          districtRowData: [],
        });

      } else if(query['type'] === 'labsForParticularData'){
        const dataFromApi = await getLabSamplesList(
          {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            categoryId: routeParams.categoryId,
            type: query['level'],
            geoId: query['geoId']
          },
          query['labId'],
          query['labDataType'],
          isEmpaneledData,
          userDetails.stateGeoId)

        setGridsVisibility({
          showAgencyGrid: true,
          showDistrictGrid: false,
          showPremixGrid: false,
        });

        setGridsData({
          agencyRowData: dataFromApi?.data?.data,
          districtRowData: [],
        });
      }
    } catch (err) {
      dispatchShowLoader(false);
      console.log(err)
    }
    dispatchShowLoader(false);
  };

  useEffect(() => {
    getDataOnQueryChange();
  }, [query, nfpbFlowCategoryType]);

  const submitDate = () => {
    if (routeParams.reportType == "agency") {
      let type = [categoryNameToIdMapRedux['SCHOOL']].includes(routeParams.categoryId)
        ? "school-all"
        : "agency-all";
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: type,
        fromDate: StartDate ? moment(StartDate).format("YYYY-MM-DD") : null,
        toDate: EndDate ? moment(EndDate).format("YYYY-MM-DD") : null,
        districtGeoId: selectedDistrict,
        stateGeoId: selectedState,
        // sourceManufacturerId: "",
        // targetManufacturerId: "",
        // level: selectedState ? (selectedDistrict ? 'district' : 'state') : 'country',
        // geoId: selectedDistrict || selectedState || '1',
        level: selectedState ? (selectedDistrict ? 'district' : 'state') : 'country',
        geoId: selectedDistrict || selectedState || '2',
        isEmpaneledData: isEmpaneledData

      }));
    } else if (routeParams.reportType == "district") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        fromDate: StartDate ? moment(StartDate).format("YYYY-MM-DD") : null,
        toDate: EndDate ? moment(EndDate).format("YYYY-MM-DD") : null,
        districtGeoId: selectedDistrict,
        stateGeoId: selectedState,
        // sourceManufacturerId: "",
        // targetManufacturerId: "",
        type: "district-all",
      }));
    } else if(routeParams.reportType === "lab"){
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: 'labs-all',
        fromDate: StartDate ? moment(StartDate).format("YYYY-MM-DD") : null,
        toDate: EndDate ? moment(EndDate).format("YYYY-MM-DD") : null,
        districtGeoId: selectedDistrict,
        stateGeoId: selectedState,
        level: selectedState ? (selectedDistrict ? 'district' : 'state') : 'country',
        geoId: selectedDistrict || selectedState || '2',
        isEmpaneledData: isEmpaneledData

      }));
    }
  };

  const reset = () => {
    
    navigate({ search: '' });
    setStartDate(moment().subtract(6, 'months').format('MM-DD-YYYY'));
    setEndDate(moment().format('MM-DD-YYYY'));
    setMaxStartDateAccToEndDate(moment().format('MM-DD-YYYY'))
    if(selectedTab == '1'){
      setSelectedState(userDetails.stateGeoId ? userDetails.stateGeoId : '');
    } else{
      setSelectedState('')
    }
    setSelectedDistrict("");

    if(!userDetails.stateGeoId){
      setDistrictList([]);
    }

    if (routeParams.reportType == "agency") {
      let type = [categoryNameToIdMapRedux['SCHOOL']].includes(routeParams.categoryId)
        ? "school-all"
        : "agency-all";
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: type,
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        stateGeoId: "",

        sourceManufacturerId: "",
        targetManufacturerId: "",
        level: (selectedTab == '2') ? 'country' : (userDetails.stateGeoId ? 'state' : 'country'),
        geoId: (selectedTab == '2') ? '2' : userDetails.stateGeoId || '2',
        isEmpaneledData: isEmpaneledData,
        labId: '',
        labDataType : ''
      }));
    } else if (routeParams.reportType == "district") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        stateGeoId: "",
        sourceManufacturerId: "",
        targetManufacturerId: "",
        type: "district-all",
      }));
    } else if(routeParams.reportType == "lab"){
      setQuery((prevQuery) => ({
        ...prevQuery,
        type: 'labs-all',
        fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        districtGeoId: "",
        stateGeoId: "",
        categoryId: routeParams.categoryId,
        level: (selectedTab == '2') ? 'country' : (userDetails.stateGeoId ? 'state' : 'country'),
        geoId: (selectedTab == '2') ? '2' : userDetails.stateGeoId || '2',
        isEmpaneledData: isEmpaneledData,
        labId: '',
        labDataType : ''

      }));
    }
  };

  const handleRowClickAgencyGrid = async (event) => {

    if(event.rowPinned){
      return;
    }

    const cellElement = event.event.target.closest('.ag-cell');

    if (cellElement) {
      const columnId = cellElement.getAttribute('col-id');
      console.log('Column ID:', columnId);
      // if(columnId == 'lotDetailsLink'){
      if(['lotDetailsLink', 'numberOfBatches'].includes(columnId)){
        return;
      } else if(['samplesReceived', 'samplesRejected', 'samplesInLab', 'testsDone', 'samplesToReceive'].includes(columnId)){
        setQuery((prevQuery)=>({
          ...prevQuery,
          type: 'labsForParticularData',
          labId: event.data.id,
          labDataType: columnId
        })

        )
        // sessionStorage.setItem("labId", event.data.id)
      }
    }

    const body = {
      fromDate: query["fromDate"],
      toDate: query["toDate"],
    };
    const selectedRowData = event.data;

    if (query["type"] == "agency-all") {
      // query["districtGeoId"] &&
      setQuery((prevQuery) => ({
        ...prevQuery,
        // districtGeoId: "",
        sourceManufacturerId: selectedRowData.manufacturerId,
        targetManufacturerId: "",
        type: "agency-targetManufacturers",
        [`selected${categoryNameMap[query['categoryId']]}AgencyName`] : selectedRowData.agencyName,
        sourceManufacturerName: selectedRowData.agencyName
      }));
    } else if (query["type"] == "agency-targetManufacturers") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        targetManufacturerId: selectedRowData.manufacturerId,
        targetManufacturerName: selectedRowData.agencyName,
        type: "agency-lotList",
      }));
    } else if (query["type"] == "district-targetManufacturers") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        targetManufacturerId: selectedRowData.manufacturerId,
        type: "district-lotList",
      }));
    } else if (query["type"] == "district-agenciesForDistrict") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        sourceManufacturerId: selectedRowData.manufacturerId,
        type: "district-targetManufacturers",
      }));
    } else if (query['type'] == 'school-all'){
      setQuery((prevQuery) => ({
        ...prevQuery,
        targetManufacturerId: selectedRowData.manufacturerId,
        targetSchoolManufacturerId: selectedRowData.manufacturerId, //for back button
        selectedSchoolAgencyName: selectedRowData.agencyName,
        categoryId: categoryNameToIdMapRedux['MILLER'],
        type: 'school-miller'
      }))
    } else if (query['type'] == 'school-miller'){
      setQuery((prevQuery) => ({
        ...prevQuery,
        targetManufacturerId: selectedRowData.manufacturerId,
        targetMillerManufacturerId: selectedRowData.manufacturerId,
        selectedMillerAgencyName: selectedRowData.agencyName,
        categoryId: categoryNameToIdMapRedux['FRK'],
        type: 'school-frk'
      }))
    } else if (query['type'] == 'school-frk'){
      setQuery((prevQuery) => ({
        ...prevQuery,
        targetManufacturerId: selectedRowData.manufacturerId,
        targetFrkManufacturerId: selectedRowData.manufacturerId,
        selectedFrkAgencyName: selectedRowData.agencyName,
        categoryId: categoryNameToIdMapRedux['PREMIX'],
        type: 'school-premix'
      }))
    } else if(query['type'] === 'labsForParticularData'){

      const fortificationToLabMap = {
        "http://localhost:3000": "http://localhost:3001",
        "http://frqms.nfpb.gov.lk":"http://lab-frqms.nfpb.gov.lk",
        "https://uat-rice.fortification.in/": "https://uat-lab.fortification.in"
      }
          
          window.open(
            `${fortificationToLabMap[window.location.origin]}/${(categoryNameMap[routeParams.categoryId]).toLowerCase()}/${routeParams.categoryId}/details/${selectedRowData.id}?redirected=true`,
            "_blank"
          );
        // }
    }
  };

  const handleRowClickDistrictGrid = async (event) => {
    const selectedRowData = event.data;

    if (query["type"] == "district-all") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        districtGeoId: selectedRowData.districtGeoId,
        type: "district-agenciesForDistrict",
      }));
    } else if (query["type"] == "agency-districtsForAgency") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        districtGeoId: selectedRowData.districtGeoId,
        type: "agency-targetManufacturers",
      }));
    }
    setSearchKeyWord("")
  };



  const handleStateSelectChange = async (e) => {
    setSelectedState(e.target.value);
    setSelectedDistrict('')
    // const districtData = await getDistrictsByStateGeoId(e.target.value);
    // setDistrictList(districtData.data);
    // setQuery({ ...query, stateGeoId: e.target.value });
  };

  const handleDistrictSelectChange = (e) => {
    setSelectedDistrict(e.target.value);
  };

  const handleBackClick = async () => {
    const currentType = query["type"];

    let prevType = "";

    const agencyOrDistrict = (currentType == 'labsForParticularData') ? 'lab' : currentType.split("-")[0];

    // if(agencyOrDistrict == 'agency'){
    if(agencyOrDistrict != 'school'){
      if(query['type'] === 'agency-batchesList'){
        setQuery((prevQuery) => ({
          ...prevQuery,
          type: 'agency-all',
        }));
      } else{
        let currentTypeIndexInFlow =
          gridsFlows[`${agencyOrDistrict}Flow`].indexOf(currentType);
        if (currentTypeIndexInFlow != 0 && currentTypeIndexInFlow != -1) {
          setQuery((prevQuery) => ({
            ...prevQuery,
            type: gridsFlows[`${agencyOrDistrict}Flow`][currentTypeIndexInFlow - 1],
          }));
        }
      }


    } else{
      if(query['type'] == 'school-premix'){
        // if(categoryType.toString() == categoryNameToIdMapRedux['FRK']){
        //   setQuery((prevQuery)=>({
        //     ...prevQuery,
        //     type: 'school-frk',
        //     targetManufacturerId: prevQuery['targetSchoolManufacturerId']
        //   }))
        // } else if(categoryType.toString() == categoryNameToIdMapRedux['MILLER']){
          setQuery((prevQuery)=>({
            ...prevQuery,
            type: 'school-frk',
            targetManufacturerId: prevQuery['targetMillerManufacturerId']
          }))
        // }

      } else if(query['type'] == 'school-frk'){
        // if(categoryType.toString() == categoryNameToIdMapRedux['FRK']){
        //   setQuery((prevQuery)=>({
        //     ...prevQuery,
        //     type: 'school-all',
        //     // targetManufacturerId: prevQuery['targetSchoolManufacturerId']
        //   }))

        // } else if(categoryType.toString() == categoryNameToIdMapRedux['MILLER']){
          setQuery((prevQuery)=>({
            ...prevQuery,
            type: 'school-miller',
            targetManufacturerId: prevQuery['targetSchoolManufacturerId']
          }))
        // }

      } else if(query['type'] == 'school-miller'){
        setQuery((prevQuery)=>({
          ...prevQuery,
          type: 'school-all'
        }))
      } else if(query['type'] == 'school-lotList'){
        setQuery((prevQuery)=>({
          ...prevQuery,
          type: 'school-all'
        }))
      }
    }
    setSearchKeyWord("");
  };

  const handleDownloadClick = async () => {
    let body = {};
    let fileName = "Agencies"
    switch (query["type"]) {
      case "agency-all":
        //get required data from query and call the excel api
        if (["PREMIX", "FRK", "MILLER"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {
          body = {
            dashboardRequestDto: {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
              type: query['level'],
              geoId: query['geoId'],
              categoryId: routeParams.categoryId,
            },
            reportType: "agency",
          };
          fileName = `${categoryIdToNameMapRedux[routeParams.categoryId].replace('MILLER','MILLER(FR)')}_agencies`
        } else {
          //for school
          body = {
            dashboardRequestDto: {
              fromDate: query["fromDate"],
              toDate: query["toDate"],
            },
            type: query['level'],
            geoId: query['geoId'],
            reportType: "school",

          };
          fileName = 'school_agencies'
        }

        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;

      case "agency-districtsForAgency":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
          },
          categoryId: routeParams.categoryId,
          sourceManufacturerId: query["sourceManufacturerId"],
          reportType: "district",
        };
        await getExcelsForReportingGrids(body);
        break;
      case "agency-targetManufacturers":
      case "district-targetManufacturers":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            type: query['level'],
            geoId: query['geoId'],
            categoryId: routeParams.categoryId,
          },
          sourceManufacturerId: query["sourceManufacturerId"],
          // districtGeoId: query["districtGeoId"],
          reportType: "targetManufacturer",
        };
        fileName = `target_agencies_for_${categoryIdToNameMapRedux[routeParams.categoryId].replace('MILLER','MILLER(FR)')}`
        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;

      case "school-all":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            type: query['level'],
            geoId: query['geoId'],
            // categoryId: 9,
            categoryId: categoryNameToIdMapRedux['MILLER'],
          },
          // sourceManufacturerId: query["sourceManufacturerId"],
          // districtGeoId: query["districtGeoId"],
          reportType: "school",
        };
        fileName = 'school_agencies'
        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;

      case "school-miller":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            // type: query['level'],
            // geoId: query['geoId'],
            categoryId: categoryNameToIdMapRedux['MILLER'],
          },
          // sourceManufacturerId: query["sourceManufacturerId"],
          // districtGeoId: query["districtGeoId"],
          targetManufacturerId: query['targetSchoolManufacturerId'],
          reportType: "sourceManufacturer",
        };
        fileName = 'source_FR_agencies'
        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;

      case "school-frk":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            // type: query['level'],
            // geoId: query['geoId'],
            categoryId: categoryNameToIdMapRedux['FRK'],
          },
          // sourceManufacturerId: query["sourceManufacturerId"],
          // districtGeoId: query["districtGeoId"],
          // targetManufacturerId: (categoryType == categoryNameToIdMapRedux['FRK']) ? query['targetSchoolManufacturerId'] : query['targetMillerManufacturerId'],
          targetManufacturerId: query['targetMillerManufacturerId'],
          reportType: "sourceManufacturer",
        };
        fileName = 'source_FRK_agencies'
        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;

      case "school-premix":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
            // type: query['level'],
            // geoId: query['geoId'],
            categoryId: categoryNameToIdMapRedux['PREMIX'],
          },
          // sourceManufacturerId: query["sourceManufacturerId"],
          // districtGeoId: query["districtGeoId"],
          targetManufacturerId: query['targetFrkManufacturerId'],
          reportType: "sourceManufacturer",
        };
        fileName = 'source_PREMIX_agencies'
        await getExcelsForReportingGrids(body,
          isEmpaneledData,
          userDetails.stateGeoId,
          fileName);
        break;


      case "district-all":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
          },
          type: "state",
          stateGeoId: "28",
          categoryId: routeParams.categoryId,
          reportType: "district",
        };
        await getExcelsForReportingGrids(body);
        break;
      case "district-agenciesForDistrict":
        body = {
          dashboardRequestDto: {
            fromDate: query["fromDate"],
            toDate: query["toDate"],
          },
          type: "district",
          districtGeoId: query["districtGeoId"],
          categoryId: routeParams.categoryId,
          reportType: "sourceManufacturer",
        };
        await getExcelsForReportingGrids(body);
        break;

      default:
        console.log("unable to download excel because of invalid report type");
        break;
    }
  };
  useEffect(()=>{
    getData()
  },[isEmpaneledData])

  const handleChange = (event, newValue) => {
    navigate({ search: '' });
    setSelectedTab(newValue);
    // console.log(newValue, "newValue")
    if(newValue == 1){
      setIsEmpaneledData(false)
    } else if(newValue == 2){
      setSelectedState('')
      setSelectedDistrict('')
      setIsEmpaneledData(true)
    }
  };

  const onCellClicked = (params) => {

    if(params.rowPinned){
      return;
    }

    if (params.colDef.field === 'lotDetailsLink') {

      setQuery((prevQuery)=>({
        ...prevQuery,
        sourceManufacturerId: params.data.manufacturerId,
        type: 'school-lotList',
        sourceManufacturerName: params.data.agencyName,
        targetManufacturerName: prevQuery[`selected${targetCategoryMapForSchool[prevQuery['type']]}AgencyName`]
      }))
    } else if (params.colDef.field === 'numberOfBatches') {
      if(params.data.numberOfBatches === 0){
        return
      }
      setQuery((prevQuery)=>({
        ...prevQuery,
        sourceManufacturerId: params.data.manufacturerId,
        sourceManufacturerName: params.data.agencyName,
        [`selected${categoryNameMap[query['categoryId']]}AgencyName`] : params.data.agencyName,
        type: 'agency-batchesList'
      }))
    }
    setSearchKeyWord("");
  };



  return (
    <div>
      <Box className="main-content-overlay" sx={{ marginTop: "20px" }}>
        <div className="main-content-div p-4">
          <div className={(!userDetails.stateGeoId || (routeParams.reportType == 'lab')) && 'hidden'} style={{display: 'flex', justifyContent:'center', marginBottom: '15px'}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={handleChange}>
                <Tab value={'1'} label="Geographical View" />
                <Tab value={'2'} label="Empanelment View"  />
              </Tabs>
            </Box>
          </div>
          {!(["agency-all", "district-all", 'school-all', 'labs-all' ].includes(query['type']))
          // query["type"] != "agency-all" && query["type"] != "district-all" && query['type'] != 'school-all' && query['type'] != 'labs-all' 
          &&(
           
            <a
              style={{
                color: 'rgba(13, 110, 253, 1)',
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
              <u>back</u>
            </a>
          )}

          <div
            className="flex-container"
            style={{
              display: "flex",
              margin: "10px 10px 10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginRight: "10px",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginRight: "30px",
                  height: "auto",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={StartDate}
                    disableFuture={true}
                    label={"Start Date"}
                    maxDate={maxStartDateAccToEndDate}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                    onChange={(date) => {
                      setStartDate(date.$d);
                      console.log(date.$d, 'date.$d')
                    }}
                    className="input-date-picker"
                  // sx={{ mb: "1", mt: "1",width: "97%" }}
                  />
                </LocalizationProvider>
              </div>

              <div
                style={{
                  marginRight: "40px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    value={EndDate}
                    disableFuture={true}
                    minDate={StartDate}
                    label={"End Date"}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        // style={{paddingTop:'10px', paddingBottom:'10px'}}
                        size="small"
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                    onChange={(date) => {
                      setEndDate(date.$d);
                      setMaxStartDateAccToEndDate(date.$d)
                    }}
                    className="input-date-picker"
                  // sx={{paddingTop: '10px'}}
                  // sx={{ mb: "1", mt: "1",width: "97%" }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <FormControl style={{ minWidth: "150px", marginRight: "30px" }}>
                  <InputLabel id="state-select">Province</InputLabel>
                  <Select
                    labelId="state-select"
                    id="simple-state-select"
                    value={selectedState}
                    label="Province"
                    disabled={(!query['type'].includes('-all')) || (userDetails.stateGeoId && !isEmpaneledData)}
                    onChange={handleStateSelectChange}
                    size="small"
                  >
                    {stateList?.map((option) => (
                      <MenuItem key={option.uuid} value={option.geoId}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl style={{ minWidth: "150px" }}>
                  <InputLabel id="district-select">District</InputLabel>
                  <Select
                    labelId="district-select"
                    id="simple-district-select"
                    value={selectedDistrict}
                    label="District"
                    disabled={(!query['type'].includes('-all'))}
                    onChange={handleDistrictSelectChange}
                    size="small"
                  >
                    {districtList?.map((option) => (
                      <MenuItem key={option.uuid} value={option.geoId}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginLeft: "auto",
              }}
            >
              <button
                className="btn btn-primary"
                onClick={submitDate}
                style={{ marginRight: "15px" }}
              >
                submit
              </button>

              <button
                className="btn btn-secondary"
                onClick={reset}
                style={{ marginRight: "15px" }}
              >
                clear
              </button>
              {
                (routeParams.categoryId === categoryNameToIdMapRedux['NFPB']) &&
                (routeParams.reportType !== 'lab') &&
                <FormControl style={{ minWidth: "100px" }}>
                  <InputLabel id="category-select">Category</InputLabel>
                  <Select
                    labelId="category-select"
                    id="simple-category-select"
                    value={nfpbFlowCategoryType}
                    label="Category"
                    onChange={(e) => {
                      setNfpbFlowCategoryType(e.target.value)
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        type: "agency-all",
                      }));
                    }}
                    size="small"
                  >
                    <MenuItem key={categoryNameToIdMapRedux['FRK']} value={categoryNameToIdMapRedux['FRK']}> {"FRK"} </MenuItem>
                    <MenuItem key={categoryNameToIdMapRedux['MILLER']} value={categoryNameToIdMapRedux['MILLER']}> {"FR"} </MenuItem>
                  </Select>
                </FormControl>
              }
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', padding: '10px 10px 5px 10px' }}><Typography style={{ fontWeight: "400", fontFamily: "outfit" }}></Typography>{heading}</div>

          {gridsVisibility["showAgencyGrid"] && (
            <AgencyGrid
              rowData={gridsData["agencyRowData"]}
              onRowClick={handleRowClickAgencyGrid}
              onCellClicked={onCellClicked}
              handleDownloadClick={handleDownloadClick}
              isTargetManufacturerGrid={query['type'].includes('target')}
              query={query}
              searchKeyWord={searchKeyWord}
              setSearchKeyWord={setSearchKeyWord}
              headerForExcel={heading}
              fileNameForExcel={fileNameForExcel}
            // downloadUrl={agencyDownloadUrl}
            // isSchool={isSchool}
            />
          )}
          {gridsVisibility["showDistrictGrid"] && (
            <DistrictGrid
              rowData={gridsData["districtRowData"]}
              onRowClick={handleRowClickDistrictGrid}
              handleDownloadClick={handleDownloadClick}
            // downloadUrl={districtDownloadUrl}
            />
          )}

          {gridsVisibility["showPremixGrid"] && ['agency-lotList', 'agency-batchesList', 'school-lotList'].includes(query['type']) && (
            <PremixGrid
              query={query}
              showBatchesOrLotsForMonitor={gridsVisibility["showPremixGrid"]}
              isBatchList={query['type'].includes('batchesList')}
              isLotList={query['type'].includes('lotList')}
            />
          )}
        </div>
      </Box>
    </div>
  );
}
