import React from "react";
import { Chip } from "@mui/material";
import { ChipColorSelector } from "../../utils/ChipColorHandler";
import { Trans } from "react-i18next";

export const renderNotificationTitleWithChips = (
  notificationTitle,
  translate
) => {
  const parts = notificationTitle.split(/({[^}]+})/g);

  const parseAndTranslateMessage = (message) => {
    // Regex for two-segment messages
    const twoSegmentRegex =
      /(?<type>Batch|Lot) (?<batchId>\S+) has been moved from {(?<fromStatus>[^}]+)} to {(?<toStatus>[^}]+)}/;

    // Regex for one-segment messages
    const oneSegmentRegex =
      /(?<type>Batch|Lot) (?<batchId>\S+) has been {(?<status>[^}]+)}/;

    // Check for two-segment pattern
    const twoSegmentMatch = message.match(twoSegmentRegex);
    if (twoSegmentMatch) {
      const { type, batchId, fromStatus, toStatus } = twoSegmentMatch.groups;
      return {
        messageKey: "has_been_moved",
        type,
        batchId,
        fromStatus,
        toStatus,
      };
    }

    // Check for one-segment pattern
    const oneSegmentMatch = message.match(oneSegmentRegex);
    if (oneSegmentMatch) {
      const { type, batchId, status } = oneSegmentMatch.groups;
      return {
        messageKey: "has_been",
        type,
        batchId,
        status,
      };
    }

    return null; // Invalid format
  };

  const parsedMessage = parseAndTranslateMessage(notificationTitle);

  if (!parsedMessage) {
    return <div>{notificationTitle}</div>;
  }

  if (parsedMessage.messageKey === "has_been_moved") {
    return (
      <Trans
        i18nKey={parsedMessage.messageKey}
        values={{
          type: translate(parsedMessage.type),
          batchId: parsedMessage.batchId,
        }}
        components={{
          fromStatus: (
            <Chip
              label={translate(parsedMessage.fromStatus.toLowerCase())}
              size="small"
              sx={{
                backgroundColor: ChipColorSelector(parsedMessage.fromStatus),
                m: 1,
              }}
            />
          ),
          toStatus: (
            <Chip
              label={translate(parsedMessage.toStatus.toLowerCase())}
              size="small"
              sx={{
                backgroundColor: ChipColorSelector(parsedMessage.toStatus),
                m: 1,
              }}
            />
          ),
        }}
      />
    );
  }

  // For one-segment message (status)
  if (parsedMessage.messageKey === "has_been") {
    return (
      <Trans
        i18nKey={parsedMessage.messageKey}
        values={{
          type: translate(parsedMessage.type),
          batchId: parsedMessage.batchId,
        }}
        components={{
          status: (
            <Chip
              label={translate(parsedMessage.status?.toLowerCase())}
              size="small"
              sx={{
                backgroundColor: ChipColorSelector(parsedMessage.status),
                m: 1,
              }}
            />
          ),
        }}
      />
    );
  }

  return null;
};
