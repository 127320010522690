import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getDownloadedDocument, UploadFile } from "../../../services/FortificationService";
import { useAppDispatch } from "../../../services/StoreHooks";

import FileIcon from "../../../components/FileIcon/FileIconList";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver'
import { DOWNLOAD, REMOVE } from "../../../textConstant";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
export default function FileUpload({ info, callType, paths, getFileData, handleFileDelete, setShowButton,disableVendorData, isPremixAddFromFrk, idx }) {

  const {translate} = useTranslatewrapper()

  const [fileBodys, setFileBodys] = useState([]);
  const dispatch = useAppDispatch();
  let arrErr = []


  useEffect(() => {
    setFileBodys(paths || []);
  }, [paths])

  const handleFileDownload = async (path) => {
    const blob = await getDownloadedDocument(path);

    blob.data && saveAs(blob.data,path)

  }

  const onDrop = React.useCallback(async (acceptedFileItem) => {

    await Promise.all(acceptedFileItem.map(async (file) => {
      const fileUploaded = await UploadFile({
        "file": file
      })
      if (fileUploaded.status === 200 && fileUploaded.data) {
        if (setShowButton) setShowButton(true);

        const addEntity = {
          name: (isPremixAddFromFrk ? info.name :(callType === "api" ? "" : info.name)),
          categoryDocId: callType === "api" ? info.id : "",
          path: fileUploaded?.data,
          idx:idx
        }
        setFileBodys((data) => [...data, addEntity]);
        getFileData((data) => [...data, addEntity]);
      }
    }))

  }, []);
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  function fileSizeValidation(file) {

    if(file.type !== "application/pdf"){
      arrErr.push("file type should be pdf only");
      dispatchNotification("error", arrErr);
    
      return {
        code: "incorrect-file-type",
        message: `File is not pdf`,
      };
    }

    if (file.size > 1048576) {
      arrErr.push(translate('fileSizeMax1Mb'));
      dispatchNotification("error", arrErr);
    
      return {
        code: "size-too-large",
        message: `Name is larger than 1MB`,
      };
    }
    return null;
  }

  const {
    acceptedFiles: acceptedFileItem,
    fileRejections: rejectedFiles,
    getRootProps: rootPropsFileItem,
    getInputProps: inputPropsFileItem,
    open,
  } = useDropzone({
    onDrop: onDrop,
    noClick:true,
    validator: fileSizeValidation,
    maxFiles: 10,
    multiple: true,
    accept: {
      // "application/msword": [".doc", ".docx"],
      "application/pdf": [".pdf"],
      // "application/msexcel": [".xlsx",".xlsb",".xlsm",".xltx"],
      // "image/*": [".img", ".png", ".jpg", ".jpeg"]
    },
  });
  const handleDelete = (idx, file) => {
   
    if (Array.isArray(fileBodys) && !disableVendorData) {
      let data = [...fileBodys];
      data.splice(idx, 1);
      setFileBodys(data);
      handleFileDelete((item) => [...item, file]);
      if(setShowButton) setShowButton(true);
    }
  };

  return (
    <div>

      <Box sx={{ gap: "20px" }}
        {...rootPropsFileItem({ className: "dropzone" })}
      >
        <input disabled={disableVendorData} {...inputPropsFileItem()} />

        <Button
        disabled={disableVendorData}
          variant="outlined"
          color="primary"
          onClick={open}
          sx={{
            height: "auto",
            marginLeft: "0px",
            marginBottom: "0px",
            border:"1px dashed #0075ff"
          }}
        >
          {translate('dragToUpload')}
        </Button>
      </Box>
      {fileBodys && (
        <Typography
          style={{ fontSize: "12px", marginTop: "10px", marginBottom: "1rem" }}
        >
          {Array.isArray(fileBodys) &&
            fileBodys.map((file, idx) => (
              <ListItem key={file.path || file.name} sx={{height: "30px",
              width:"85%",
              padding:"5px 5px",
              marginLeft: "0px",
              marginBottom: "0px",
              border:"1px dashed #0075ff",
              justifyContent:"space-between",
              borderRadius:"5px"}}>

                <FileIcon filename={file.path} />
                <span style={{width: "25ch",overflow: "hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",color:"#0075ff",textDecoration:"underline"}}>{file.path}</span>

                <Tooltip title={<Typography sx={{ fontSize: "0.8rem"}}>{translate('download')}</Typography>} placement="top">

                  <Button sx={{ padding: "0px", minWidth: "1px" }} onClick={() => { handleFileDownload(file.path) }}>
                    <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem",color:"#0075ff" }}  m={1} />
                  </Button>
                </Tooltip>

                <Tooltip title={disableVendorData ? '' : <Typography sx={{ fontSize: "0.8rem" }}>{translate(REMOVE)}</Typography>} placement="top">
                  <DeleteOutlineOutlinedIcon
                    sx={{ fontSize: "1.2rem",color:"#0075ff"}}
                    onClick={() => handleDelete(idx, file)}
                  />
                  {/* <FontAwesomeIcon icon="fa-regular fa-trash-can" /> */}
                </Tooltip>
              </ListItem>
            ))}
        </Typography>
      )}

    </div>
  );
}
