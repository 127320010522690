const entityStatesSuperAdmin = [
  {
    name: "User Manangement",
    states: [
      {
        ids: [1],
        type: "IAM",
        name: "users",
        displayName: "Users",
        route: "/admin/iam/users",
      },
      {
        ids: [1],
        type: "IAM",
        name: "inspectionusers",
        displayName: "Inspection users",
        route: "/admin/iam/inspectionusers",
      },
      {
        ids: [1],
        type: "IAM",
        name: "manufacturers",
        displayName: "Manufacturers",
        route: "/admin/iam/manufacturer",
      },
      {
        ids: [1],
        type: "IAM",
        name: "categorydoc",
        displayName: "Manufacturer Documents",
        route: "/admin/iam/categorydoc",
      },
      {
        ids: [1],
        type: "IAM",
        name: "doctype",
        displayName: "Document Types",
        route: "/admin/iam/doctype",
      },
      {
        ids: [],
        type: "IAM",
        name: "stateManagement",
        displayName: "State Management",
        route: "/admin/iam/state",
      },
    ],
  },
  {
    name: "LAB",
    states: [
      {
        ids: [1],
        type: "LAB",
        name: "users",
        displayName: "Users",
        route: "/admin/lab/users",
      },
      {
        ids: [1],
        type: "LAB",
        name: "labs",
        displayName: "Labs",
        route: "/admin/lab/labs",
      },
      {
        ids: [1],
        type: "LAB",
        name: "categorydoc",
        displayName: "Lab Document Requirements",
        route: "/admin/lab/categorydoc",
      },
      {
        ids: [],
        type: "LAB",
        name: "labtesttype",
        displayName: "Lab Test Type",
        route: "/admin/lab/labtesttype",
      },
      {
        ids: [1],
        type: "LAB",
        name: "labtest",
        displayName: "Lab Test Document Requirement",
        route: "/admin/lab/testdoctype",
      },
      {
        ids: [1],
        type: "LAB",
        name: "doctype",
        displayName: "Document Type",
        route: "/admin/lab/doctype",
      },
    ],
  },
  {
    name: "FORTIFICATION",
    states: [
      {
        ids: [],
        name: "batch",
        displayName: "Batch",
        route: "/admin/fortification/batch",
      },
      {
        ids: [],
        type: "FORTIFICATION",
        name: "lot",
        displayName: "lot",
        route: "/admin/fortification/lot",
      },
      {
        ids: [17],
        type: "FORTIFICATION",
        name: "categorydocs",
        displayName: "Category Documents",
        route: "admin/fortification/categorymapping",
      },
      {
        ids: [17],
        type: "FORTIFICATION",
        name: "doctype",
        displayName: "Document Type",
        route: "/admin/fortification/doctype",
      },
      {
        ids: [17],
        type: "FORTIFICATION",
        name: "uom",
        displayName: "uom",
        route: "/admin/fortification/uom",
      },
    ],
  },
];
const getentityStatesMonitor = (userrole, categoryNameToIdMapRedux) => {
  const isPremixMonitor = userrole.includes("PREMIX MONITOR");
  const isFRKMonitor = userrole.includes("FRK MONITOR");
  const isMillerMonitor = userrole.includes("MILLER MONITOR");
  const isAllMonitor = isPremixMonitor && isFRKMonitor && isMillerMonitor;
  let entityStatesMonitor = [{ name: "home", states: [] },{ name: "Premix", states: [] }, {name: "Frk", states: []}, {name: "Nfpb", states: []}, {name: "Miller (FR)", states: []}, {name: "School", states: []}, {name: "Labs", states: []}];
  if (isAllMonitor) {
    entityStatesMonitor[0].states.push({
      ids: [2, 3, 4],
      type: "home",
      name: "All",
      displayName: "All",
      route: "/admin/dashboard/all/0",
    });

    entityStatesMonitor[6].states.push({
      ids: [11],
      type: "Premix",
      name: "Premix",
      displayName: "Premix",
      route: `/reporting/premix/${categoryNameToIdMapRedux['PREMIX']}/lab`,
    });
    entityStatesMonitor[6].states.push({
      ids: [12],
      type: "Frk",
      name: "Frk",
      displayName: "Frk",
      route: `/reporting/frk/${categoryNameToIdMapRedux['FRK']}/lab`,
    });
    entityStatesMonitor[6].states.push({
      ids: [13],
      type: "Miller",
      name: "Miller",
      displayName: "Miller(FR)",
      route: `/reporting/miller/${categoryNameToIdMapRedux['MILLER']}/lab`,
    });
  }
  if (isPremixMonitor) {
    entityStatesMonitor[1].states.push({
      ids: [2],
      type: "Premix",
      name: "agency",
      displayName: "agency",
      route: `/reporting/premix/${categoryNameToIdMapRedux['PREMIX']}/agency`
    });
  }
  if (isFRKMonitor) {
    entityStatesMonitor[2].states.push({
      ids: [4],
      type: "Frk",
      name: "agency",
      displayName: "agency",
      route: `/reporting/frk/${categoryNameToIdMapRedux['FRK']}/agency`
    });
  }
  if (isMillerMonitor) {
    entityStatesMonitor[3].states.push({
      ids: [5],
      type: "Nfpb",
      name: "agency",
      displayName: "agency",
      route: `/reporting/nfpb/${categoryNameToIdMapRedux['NFPB']}/agency`
    });
  }
  if (isMillerMonitor) {
    entityStatesMonitor[4].states.push({
      ids: [5],
      type: "Miller",
      name: "agency",
      displayName: "agency",
      route: `/reporting/miller/${categoryNameToIdMapRedux['MILLER']}/agency`
    });
  }
  if (isAllMonitor) {
    entityStatesMonitor[5].states.push({
      ids: [7],
      type: "School",
      name: "agency",
      displayName: "agency",
      route: `/reporting/school/${categoryNameToIdMapRedux['SCHOOL']}/agency`
    });
  }
  return entityStatesMonitor;
};

export const getadminMenu = (isSuperAdmin, isMonitor, userrole, categoryNameToIdMapRedux) => {
  let menu = [
    {
      categoryId: 99,
      categoryName: isMonitor ? "Masters" : "Masters",
      entityStates: [],
      independentBatch: false,
    },
  ];
  let finalentityStates = [];
  if (isMonitor) {
    finalentityStates = [
      ...finalentityStates,
      ...getentityStatesMonitor(userrole, categoryNameToIdMapRedux),
    ];
  }
  if (isSuperAdmin) {
    finalentityStates = [...finalentityStates, ...entityStatesSuperAdmin];
  }
  menu[0].entityStates = finalentityStates;
  return menu;
};
