import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();

const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;

export const getAllInventoryBatches = async (
  categoryId,
  type,
  searchCriteriaList,
  pageNumber,
  pageSize
) => {
  const pageNumberStr = pageNumber ? `&pageNumber=${pageNumber}` : "";
  const pageSizeStr = pageSize ? `&pageSize=${pageSize}` : "";
  return _axios
    .post(
      `${FORTIFICATION_URL}${categoryId}/${type}/inventory?${pageNumberStr}${pageSizeStr}`,
      searchCriteriaList
    )
    .catch((error) => {});
};
export const getAllInventoryLots = async (
  categoryId,
  searchCriteriaList,
  pageNumber,
  pageSize,
  approvedSourceLots = false
) => {
  return _axios
    .post(
      `${FORTIFICATION_URL}${categoryId}/lot/inventory?pageNumber=${pageNumber}&pageSize=${pageSize}&approvedSourceLots=${approvedSourceLots}`,
      searchCriteriaList
    )
    .catch((error) => {});
};
