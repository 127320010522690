import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { NotificationsOutlined as BellIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MAIN_BLUE } from '../../color';
import { formatTimestamp } from '../../utils/TimeStampConverter';
import { notificationList } from '../../services/NotifcationServices';
import InfiniteScroll from 'react-infinite-scroll-component';
import { renderNotificationTitleWithChips } from './NotificationChips';
import { handleNavigate } from './NotificationPath';
import useTranslatewrapper from '../../components/useTranslateWrapper';

function NotificationPage() {
  const {translate}= useTranslatewrapper()
  const [notificationData, setNotificationData] = useState([]);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTotalPages();
  }, []);

  useEffect(() => {
    fetchNotificationList(currentPage);
  }, [currentPage]);
  // Fetch total number of pages based on the count of notifications
  const fetchTotalPages = async () => {
    const data = await notificationList(1, pageSize);
    const totalNotifications = data?.data?.count || 0;
    const calculatedTotalPages = Math.ceil(totalNotifications / pageSize);
    setTotalPages(calculatedTotalPages);
  };

  const fetchNotificationList = async (page) => {
    const data = await notificationList(page, pageSize);
    setNotificationData((prevData) => [...prevData, ...data?.data?.data]);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Box className="main-content-overlay">
      <div className="main-content-div" style={{ maxHeight: '470px', overflowY: 'auto' }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', color: MAIN_BLUE, mx: 1 }}>
            {translate('notification')}
            <BellIcon sx={{ ml: 1 }} />
          </Typography>
          <InfiniteScroll
            dataLength={notificationData.length}
            next={handleNextPage}
            hasMore={currentPage < totalPages}
            loader={<p style={{ textAlign: 'center', backgroundColor: '#eef6ff' }}><b>{translate('loading')}</b></p>}
            endMessage={<p style={{ textAlign: 'center' }}><h6>{translate('noMoreNotificationsToLoad')}</h6></p>}
            scrollableTarget="main-content-overlay"
          >
            {notificationData?.map((notification) => (
              <Card key={notification.id} sx={{ m: 1 }} onClick={() => navigate(handleNavigate(notification))}>
                <CardContent
                  sx={{
                    bgcolor: '#f0f0f0',
                    color: '#333',
                    fontSize: '0.8rem',
                    height: '3.2rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px !important',
                    borderRadius: '5px',
                  }}
                >
                  <span style={{padding:"0px"}}>
                    {renderNotificationTitleWithChips(notification.notificationTitle, translate)}
                  </span>
                  <span>{formatTimestamp(notification.notificationDate, translate)}</span>
                </CardContent>
              </Card>
            ))}
          </InfiniteScroll>
        </Box>
      </div>
    </Box>
  );
}

export default NotificationPage;
