import HttpService from "./HttpService";
const _axios = HttpService.getAxiosClient();

const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const LAB_URL = process.env.REACT_APP_BASE_LAB_URL;
const IMMUDB_URL = process.env.REACT_APP_BASE_IMMUDB_URL;
const IAM_URL_RICE = process.env.REACT_APP_BASE_IAM_URL_RICE
export const adminCategoryDocDropDown = async () => {
  return _axios.get(`${FORTIFICATION_URL}category/`).catch((error) => {});
};
export const getAlliamDocType = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${IAM_URL}document-type?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getIamDocTypeByID = async (id) => {
  return _axios
    .get(
      `${IAM_URL}document-type/${id}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getAlliamCategoryDocType = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${IAM_URL}category-document?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getIamCategoryDocTypeByID = async (id) => {
  return _axios
    .get(
      `${IAM_URL}category-document/${id}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getDocTypeFormDropdown = async (id) => {
  return _axios.get(`${IAM_URL}document-type/`).catch((error) => {
    console.log(error);
  });
};
export const getLabDocTypeFormDropdown = async (id) => {
  return _axios.get(`${LAB_URL}document/type/`).catch((error) => {
    console.log(error);
  });
};

export const getLabDocTypeGrid = async (type , pageNumber, pageSize) => {
  return _axios.get(`${LAB_URL}document/category/type/${type}?pageNumber=${pageNumber}&pageSize=${pageSize}`).catch((error) => {
    console.log(error);
  });
};
export const getLabDocTypeByID = async (id) => {
  return _axios.get(`${LAB_URL}document/${id}`).catch((error) => {
    console.log(error);
  });
};

export const createLabDocTypeByID = async (body) => {
  return _axios.post(`${LAB_URL}document/`, body).catch((error) => {
    console.log(error);
  });
};
 
export const editLabDocTypeByID = async (id,body) => {
  return _axios.put(`${LAB_URL}document/${id}`,body).catch((error) => {
    console.log(error);
  });
};

export const getAllTestTypes = (requestBody, pageNumber, pageSize) => {
  return _axios
    .post(
      `${LAB_URL}test/type/getAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, requestBody
    )
    .catch((error) => { });
}

export const createTestType = (requestBody) => {
  return _axios
    .post(
      `${LAB_URL}test/type`, requestBody
    )
    .catch((error) => { });
}

export const updateTestType = (requestBody, labTestTypeId) => {
  return _axios
    .put(
      `${LAB_URL}test/type/${labTestTypeId}`, requestBody
    )
    .catch((error) => { });
}


export const getLabTestType = (labTestTypeId) => {
  return _axios
    .get(
      `${LAB_URL}test/type/${labTestTypeId}`
    )
    .catch((error) => { });
}

export const deleteRefMethod = (refMethodId) => {
  return _axios
    .delete(
      `${LAB_URL}method/${refMethodId}`
    )
    .catch((error) => { });
}

export const createRefMethod = (reqBody) => {
  return _axios
    .post(
      `${LAB_URL}method`, reqBody
    )
    .catch((error) => { });
}

export const updateRefMethod = (reqBody, refMethodId) => {
  return _axios
    .put(
      `${LAB_URL}method/${refMethodId}`, reqBody
    )
    .catch((error) => { });
}
export const getAllUser = async (type,pageNumber, pageSize, searchCriteriaList) => {
  return _axios
    .post(
      `${IAM_URL}user/admin/all-users?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      searchCriteriaList
    )
    .catch((error) => {
      console.log(error);
    });
};

export const manufacturerList = async (pageNumber,pageSize,searchString) => {
  return _axios.get(`${IAM_URL}manufacturer?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchString}`).catch((error) => {
    console.log(error);
  });
};
export const manufacturerListFilter = async () => {
  return _axios.get(`${IAM_URL}manufacturer`).catch((error) => {//
    console.log(error);
  });
};

export const getFortificationCategoryDocTypeByIDnew = async (categoryId,batchId) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}${categoryId}/batch/${batchId}/document`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const roleCategoryList = async (id) => {
  return _axios.get(`${IAM_URL}manufacturer/roleCategories/${id}`).catch((error) => {
    console.log(error);
  });
};
export const editIamCatDocTypeByID = async (id,body) => {
  return _axios.put(`${IAM_URL}category-document/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const createIamCatDoc= async (body) => {
  return _axios.post(`${IAM_URL}category-document`, body).catch((error) => {
    console.log(error);
  });
};
 
export const createiamDocType = async (body) => {
  return _axios
    .post(
      `${IAM_URL}document-type`,body
    )
    .catch((error) => {
      console.log(error);
    });
};

export const editIamDocTypeByID = async (id,body) => {
  return _axios
    .put(
      `${IAM_URL}document-type/${id}`,body
    )
    .catch((error) => {
      console.log(error);
    });
};
export const getAllLabDocType = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${LAB_URL}document/type?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getLabDocumentTypeByID = async (id) => {
  return _axios
    .get(
      `${LAB_URL}document/type/${id}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const createLabDocumentTypeByID = async (body) => {
  return _axios.post(`${LAB_URL}document/type`, body).catch((error) => {
    console.log(error);
  });
};
 
export const editLabDocumentTypeByID = async (id,body) => {
  return _axios.put(`${LAB_URL}document/type/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const getAllFortificationDocType = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}docType?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getFortificationDocumentTypeByID = async (id) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}docType/${id}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const createFortificationDocumentTypeByID = async (body) => {
  return _axios.post(`${FORTIFICATION_URL}docType`, body).catch((error) => {
    console.log(error);
  });
};
 
export const editFortificationDocumentTypeByID = async (id,body) => {
  return _axios.put(`${FORTIFICATION_URL}docType/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const getFortifiacationDocTypeFormDropdown = async (id) => {
  return _axios.get(`${FORTIFICATION_URL}docType/`).catch((error) => {
    console.log(error);
  });
};
export const getFortificationCategoryDocTypeByID = async (categoryId,id) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}category/${categoryId}/document/${id}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const editFortificationCatDocTypeByID = async (categoryId,id,body) => {
  return _axios.put(`${FORTIFICATION_URL}category/${categoryId}/document/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const createFortificationCatDoc= async (categoryId,body) => {
  return _axios.post(`${FORTIFICATION_URL}category/${categoryId}/document`, body).catch((error) => {
    console.log(error);
  });
};

export const getFortificationCategoryDocType = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${FORTIFICATION_URL}category/document?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const labList = async (pageNumber,pageSize,searchString) => {
  return _axios.post(`${LAB_URL}getAll?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchString}`).catch((error) => {
    console.log(error);
  });
};

export const roleCategoryLabList = async (id) => {
  return _axios.get(`${LAB_URL}roleCategories/${id}`).catch((error) => {
    console.log(error);
  });
};



export const getManufacturerGrid = async (pageNumber, pageSize,string="") => {
  return _axios.get(`${IAM_URL}manufacturer/?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${string}`).catch((error) => {
    console.log(error);
  });
};

export const createManufacturerGrid = async (body) => {
  return _axios.post(`${IAM_URL}manufacturer/`,body).catch((error) => {
    console.log(error);
  });
};

export const editManufacturerGrid = async (id,body) => {
  return _axios.put(`${IAM_URL}manufacturer/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const editManufacturerRating = async (id,body) => {
  return _axios.put(`${IAM_URL}manufacturer-attributes/${id}`,body).catch((error) => {
    console.log(error);
  });
};
export const addManufacturerRating = async (body) => {
  return _axios.post(`${IAM_URL}manufacturer-attributes/`,body).catch((error) => {
    console.log(error);
  });
};


export const getManufacturerRating = async () => {
  return _axios.get(`${IAM_URL}attribute-category/`).catch((error) => {
    console.log(error);
  });
};

export const getManufacturerByID = async (id) => {
  return _axios.get(`${IAM_URL}manufacturer/${id}`).catch((error) => {
    console.log(error);
  });
};

export const createManufacturerByID = async (body) => {
  return _axios.post(`${IAM_URL_RICE}manufacturer/`, body).catch((error) => {
    console.log(error);
  });
};
 
export const editManufacturerByID = async (id,body) => {
  return _axios.put(`${IAM_URL}manufacturer/${id}`,body).catch((error) => {
    console.log(error);
  });
};


export const getManufacturerByCategory = async (id,type) => {
  return _axios.get(`${IAM_URL}manufacturer/categoryIds?categoryIds=${id}&type=${type}`).catch((error) => {
    console.log(error);
  });
};





export const getCountry = async () => {
  return _axios.get(`${IAM_URL}country`).catch((error) => {
    console.log(error);
  });
};
export const getState = async (id) => {
  return _axios.get(`${IAM_URL}state/${id}/states`).catch((error) => {
    console.log(error);
  });
};
export const getDistrict = async (id) => {
  return _axios.get(`${IAM_URL}district/${id}/districts`).catch((error) => {
    console.log(error);
  });
};

export const getDistrictsByStateGeoId = async (id) => {
  return _axios.get(`${IAM_URL}district/stateGeoId/${id}`).catch((error) => {
    console.log(error);
  });
};

export const getVillage = async (id) => {
  return _axios.get(`${IAM_URL}village/${id}/villages`).catch((error) => {
    console.log(error);
  });
};

export const createVillage = async (body) => {
  return _axios.post(`${IAM_URL}village/`,body).catch((error) => {
    console.log(error);
  });
};

export const getVillageByDistrict = async (districtId) => {
  return _axios.get(`${IAM_URL}village/${districtId}/villages`).catch((error) => {
    console.log(error);
  });
};




export const getNextCategoryId = async (id) => {
  return _axios.get(`${FORTIFICATION_URL}category/${id}/next`).catch((error) => {
    console.log(error);
  });
};

export const getStatus = async () => {
  return _axios.get(`${IAM_URL}status`).catch((error) => {
    console.log(error);
  });
};

export const getIamStatus = async () => {
  return _axios.get(`${IAM_URL}status`).catch((error) => {
    console.log(error);
  });
};
export const getLabStatus = async () => {
  return _axios.get(`${LAB_URL}status`).catch((error) => {
    console.log(error);
  });
};


export const getLabPageGrid = async (pageNumber, pageSize, searchCriteria) => {
  let searchStr = ''
  if(searchCriteria?.searchCriteriaList[0]?.value){
    searchStr = `&search=${searchCriteria.searchCriteriaList[0]?.value}`
  }
  return _axios.post(`${LAB_URL}getAll/?pageNumber=${pageNumber}&pageSize=${pageSize}${searchStr}`, searchCriteria).catch((error) => {
    console.log(error);
  });
};

export const createLabPageGrid = async (body) => {
  return _axios.post(`${LAB_URL}`,body).catch((error) => {
    console.log(error);
  });
};

export const editLabPageGrid = async (id,body) => {
  return _axios.put(`${LAB_URL}${id}`,body).catch((error) => {
    console.log(error);
  });
};


export const getLabPageByID = async (id) => {
  return _axios.get(`${LAB_URL}${id}`).catch((error) => {
    console.log(error);
  });
};
export const getlabManufacturerByCategory = async (id) => {
  return _axios.get(`${LAB_URL}manufacturer/manufacturer-lab-list/${id}`).catch((error) => {
    console.log(error);
  });
};


export const getLabCountry = async () => {
  return _axios.get(`${LAB_URL}country`).catch((error) => {
    console.log(error);
  });
};
export const getLabState = async (id) => {
  return _axios.get(`${LAB_URL}state/country/${id}/states`).catch((error) => {
    console.log(error);
  });
};
export const getLabDistrict = async (id) => {
  return _axios.get(`${LAB_URL}district/state/${id}/districts`).catch((error) => {
    console.log(error);
  });
};
export const getLabVillage = async (id) => {
  return _axios.get(`${LAB_URL}village/${id}/villages`).catch((error) => {
    console.log(error);
  });
};

export const createLabVillage = async (body) => {
  return _axios.post(`${LAB_URL}village/`,body).catch((error) => {
    console.log(error);
  });
};

export const getLabVillageByDistrict = async (districtId) => {
  return _axios.get(`${LAB_URL}village/district/${districtId}/villages`).catch((error) => {
    console.log(error);
  });
};

export const activeLab = async (manufacturerId, categoryId , pageNumber,pageSize,searchString) => {
  return _axios.get(`${LAB_URL}manufacturer/${manufacturerId}/category/${categoryId}/active-labs?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchString}`).catch((error) => {
    console.log(error);
  });
};

export const selectLabAccess = async (manufacturerId, categoryId, type) => {
  return _axios.get(`${IAM_URL}state-lab-test-access/manufacturer/${manufacturerId}/${categoryId}/${type}/LAB_SELECTION`).catch((error) => {
    console.log(error);
  });
};
export const getAllStatesManage = async (pageNumber, pageSize) => {
  return _axios
    .get(
      `${IAM_URL}category-document?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const createStateLabAccess = async (body) => {
  return _axios
    .post(
      `${IAM_URL}state-lab-test-access`,body
    )
    .catch((error) => {
      console.log(error);
    });
};
export const getStateLabAccessByID = async (categoryId,entityType,stateId) => {
  return _axios
    .get(
      `${IAM_URL}state-lab-test-access/${categoryId}/${entityType}/${stateId}`,
    )
    .catch((error) => {
      console.log(error);
    });
};
export const editStateLabAccessByID = async (categoryId,entityType,stateId, body) => {
  return _axios
    .put(
      `${IAM_URL}state-lab-test-access/${categoryId}/${entityType}/${stateId}`,body
    )
    .catch((error) => {
      console.log(error);
    });
};
export const getAllStateLabAccess = (requestBody, pageNumber, pageSize) => {
  return _axios
    .post(
      `${IAM_URL}state-lab-test-access/get-all?pageNumber=${pageNumber}&pageSize=${pageSize}`, requestBody
    )
    .catch((error) => { });
}

export const getManufacturerGeoList = async (stateId,districtId) => {
  return _axios
    .get(
      `${IAM_URL}manufacturer/geo/list?stateId=${stateId}${districtId?`&districtId=${districtId}`:""}`,
    )
    .catch((error) => {
      console.log(error);
    });
};

