import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useTranslatewrapper from "../../components/useTranslateWrapper";
import { useTranslation } from "react-i18next";

function BatchAndVendorInfo({ isInspector }) {
  const {translate} = useTranslatewrapper()
  const routeParams = useParams();
  const [requiredID, setRequiredID] = React.useState("");
  const [QRBatchDetails, setQRBatchDetails] = React.useState();
  const [batchDetails, setBatchDetails] = React.useState({});
  const FORTIFICATION_URL = process.env.REACT_APP_BASE_FORTIFICATION_URL;
  const [vendorDetails, setVendorDetails] = React.useState({});
  const [title, setTitle] = React.useState("")
  const [selectedLanguage, setSelectedLanguage] = React.useState("en");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (routeParams.uuid) {
      getUUIDData();
    }
  }, []);

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setSelectedLanguage(event.target.value);
  };

  let percentagePremix;
  let tempBatchDetails = {};
  let tempVendorDetails = {};
  let QRCodeData;
  const getUUIDData =  () => {
     fetch(`${FORTIFICATION_URL}${routeParams?.categoryId}/${routeParams?.type}/code/${routeParams?.uuid}/details`)
    .then(response => response.json())
    .then(data => {
      QRCodeData = data;
      setRequiredID(QRCodeData?.id);
      setQRBatchDetails(QRCodeData);
      console.log(QRBatchDetails);
      let batchPropLocation;

      if(routeParams.type=="batch"){batchPropLocation = QRCodeData?.batchProperties}
      else if (routeParams.type=="lot"){batchPropLocation = QRCodeData?.batch.batchProperties}
      batchPropLocation.map((newData) => {
  
        switch (newData.name) {
          case "manufacture_name":
            tempVendorDetails["name"] = newData.value;
            break;
          case "manufacture_type":
            tempVendorDetails["vendorType"] = newData.value;
            break;
          case "manufacture_accreditedByAgency":
            tempVendorDetails["isManufacturerAccreditedByAnyAgency"] =
              newData.value ? 'Yes' : 'No';
            break;
          case "manufacture_licenseNumber":
            tempVendorDetails["License"] = newData.value;
            break;
          case "manufacture_completeAddress":
            tempVendorDetails["address"] = newData.value;
  
            break;
          case "percentage_of_premix_required":
            percentagePremix =
              newData.value && newData.value !== "undefined"
                ? Number(newData.value)
                : "";
            tempBatchDetails["percentToBeUsedInMix"] = percentagePremix;
            break;
          case "manufacture_batchNumber":
            // debugger;
              tempBatchDetails["UserDefinedBatchNo"] = newData.value;
              break;
          case "raw_material_type":
            // debugger;
              tempBatchDetails["RawMaterialType"] = newData.value;
              break;
          case "batch_name":
            tempBatchDetails["name"] = newData.value;
            break;
        }
        setVendorDetails(tempVendorDetails);
      });

      if(routeParams.type=="batch"){tempBatchDetails["batchNumber"] = QRCodeData.batchNo}
      else if (routeParams.type=="lot"){tempBatchDetails["batchNumber"] = QRCodeData.batch.batchNo}
      

      setBatchDetails({
        ...tempBatchDetails,
        manufacturingDate: QRCodeData.dateOfManufacture,
        expiryDate: QRCodeData.dateOfExpiry,
        totalQuantityInKgs: QRCodeData.totalQuantity,
        remainingQuantityInKgs: QRCodeData.remainingQuantity,
      });
    })

    let pathname = window.location.pathname
    
    let capitalizedWord = pathname.split('/')[1].toUpperCase()

    if(capitalizedWord == "MILLER"){
      capitalizedWord = "FORTIFIED RICE"
    }
    
    setTitle(capitalizedWord + " "+routeParams?.type.toUpperCase() + " DETAILS")
  };

  return (
    <div style={{ fontSize: "1rem", padding: "10px", }}>
      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <div>
      <img src={'/assets/images/Emblem_of_Sri_Lanka.png'} alt="path logo" height={110} style={{objectFit:'cover', marginLeft: '12px'}} />
      </div>
      <div style={{textAlign:'center', flexGrow:'1'}}>
      <h2 style={{color: '#228B22', fontWeight: "bolder"}}>FORTIFIED RICE QUALITY MANAGEMENT SYSTEM</h2>
      <h2 >{title}</h2>
      </div>
      </div>
       {/* marginTop: "20px" */}
      <div style={{ padding: "10px" }}>
        <div style={{display:'flex', justifyContent:"space-between", alignItems:'center'}}>

        {/* <h3>Vendor Details</h3> */}
        <h3>{t('vendorDetails')}</h3>
        <div>
        <FormControl variant="outlined" style={{ width: 'auto', height: '50%' }}>
          <Select
            labelId="language-select-label"
            // value={i18n.language}
            value={selectedLanguage}
            onChange={handleChange}
            label=""
            size="small"
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ta">தமிழ்</MenuItem>
            <MenuItem value="si">සිංහල</MenuItem>
          </Select>
        </FormControl>
        </div>
        </div>
        <Grid container className="Gridclass" spacing={0.5}>
          <Grid item md={6} sm={12}>
            <strong>{t('nameOfVendor')}</strong> : {vendorDetails["name"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('LicenseNo')}</strong> : {vendorDetails["License"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('vendorType')}</strong> : {t(vendorDetails["vendorType"]?.toLowerCase())}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('manufAccreditedByAgency')}</strong> :
            {t(vendorDetails["isManufacturerAccreditedByAnyAgency"]?.toLowerCase())}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('address')}</strong> : {vendorDetails["address"]}
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "10px" }}>
        <h3>
          {routeParams?.type == "batch" ? t('batchDetails') : t('lotDetails')}
        </h3>
        <Grid container spacing={0.5}>
          <Grid item md={6} sm={12}>
            <strong>{t("name")}</strong> : {batchDetails["name"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('batchNumber')}</strong> : {batchDetails["batchNumber"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('manufacturingDate')}(yyyy-mm-dd)</strong> :{" "}
            {batchDetails["manufacturingDate"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('expiryDate')}(yyyy-mm-dd)</strong> :{" "}
            {batchDetails["expiryDate"]}
          </Grid>
          {batchDetails["percentToBeUsedInMix"] && (
            <Grid item md={6} sm={12}>
              <strong>{t('percentInMix')}</strong> :{" "}
              {batchDetails["percentToBeUsedInMix"]}
            </Grid>
          )}
          <Grid item md={6} sm={12}>
            <strong>{t('totalQuantityInKgs')}</strong> :{" "}
            {batchDetails["totalQuantityInKgs"]}
          </Grid>
          <Grid item md={6} sm={12}>
            <strong>{t('remainingQuantityInKgs')}</strong> :{" "}
            {batchDetails["remainingQuantityInKgs"]}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BatchAndVendorInfo;
