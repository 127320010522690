import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import UnitConverterWeight from "../../utils/UnitConverter";

function HighchartPie(props, translate) {
  // drilldown(Highcharts);
  const options = {
    chart: {
      type: "pie",
      height: "180px",
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },


    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {},
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: { enabled: false },
        showInLegend: true,
      },
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
      },
     
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth:1,

    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.quan} </b>',
    },

    series: [
      {
        name: translate("stats"),
        colorByPoint: true,
        data: props,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
export default HighchartPie;
