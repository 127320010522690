import React, { useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import UnitConverterWeight from "../../utils/UnitConverter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import LinkRedirect from "../../pages/Form/UsageGrid/LinkRedirect";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useAppSelector } from "../../services/StoreHooks";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default function AgencyGrid(props) {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryNameToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name] = current.id.toString();
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const routeParams = useParams();
  const [columnDefs, setColumnDefs] = useState([]);
  const [columnHeaderMap, setColumnHeaderMap] = useState({});
  const [query, setQuery] = useState(props.query);

  useEffect(() => {
    if(routeParams.reportType === "lab"){
      if(props.query["type"] === "labs-all"){
      setColumnDefs([
        {
          headerName: "S.No",
          // valueGetter: "node.rowIndex + 1",
          cellRenderer: params => params.node.rowPinned ? "" : params.node.rowIndex + 1,
          sortable:false,
          width: 100,
        },
        {
          headerName: "Lab Name",
          field: "name",
          tooltipField: "name",
          width: "auto",
        },
        {
          headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
          field: "nablCertificate",
          tooltipField: "nablCertificate",
          width: "auto",
        },
        {
          headerName: "Province",
          field: "state",
          tooltipField: "state",
          width: "auto",
        },
        {
          headerName: "District",
          field: "district",
          tooltipField: "district",
          width: "auto",
        },
        {
          headerName: "Samples To Receive",
          field: "samplesToReceive",
          width: "auto",
        },
        {
          headerName: "Samples Received at Lab",
          field: "samplesReceived",
          width: "auto",
          // cellRendererFramework: (params) => <a href ={'javascript:void(0)'} style>{params.data.samplesReceived}</a> 
        },
        {
          headerName: "Samples in Lab",
          field: "samplesInLab",
          width: "auto",
        },
        {
          headerName: "No. of Tests Performed",
          field: "testsDone",
          width: "auto",
        },
        {
          headerName: "Samples Rejected Without Testing",
          field: "samplesRejected",
          width: "auto",
        },
        

      ]);
    } else if(props.query["type"] === "labsForParticularData"){
      setColumnDefs([
        {
          headerName: "S.No",
          // valueGetter: "node.rowIndex + 1",
          cellRenderer: params => params.node.rowPinned ? "" : params.node.rowIndex + 1,
          sortable:false,
          width: 100,
        },
        {
          headerName: "Shipment No.",
          field: "batchNo",
          tooltipField: "batchNo",
          width: "auto",
          valueFormatter: (params) => {
            return (params.data.batchNo ? params.data.batchNo : params.data.lotNo);
          },
        },
        {
          headerName: "Sample Receiving Date",
          field: "receivedDate",
          width: "auto",
        },
        {
          headerName: "Sample Test Date",
          field: "testDate",
          width: "auto",
        },
        {
          headerName: "status",
          field: "state.displayName",
          width: "auto",
        },
        {
          headerName: "Lab Name",
          field: "lab.name",
          width: "auto",
        },
        {
          headerName: "TC Number",
          field: "lab.certificateNo",
          width: "auto",
        },
      ]);
    }
      return;
    }
    if (["PREMIX", "FRK", "MILLER"].map(key=>categoryNameToIdMapRedux[key]).includes(routeParams.categoryId)) {
      if (props.query["type"].includes("target")) {
        //setting columns for target manufacturers grid
        setColumnDefs([
          {
            headerName: "S.No",
            // valueGetter: "node.rowIndex + 1",
            cellRenderer: params => params.node.rowPinned ? "" : params.node.rowIndex + 1,
            sortable:false,
            width: 100,
          },
          {
            headerName: "Agency Name",
            field: "agencyName",
            tooltipField: "agencyName",
            width: "auto",
          },
          {
            headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
            field: "licenseNumber",
            tooltipField: "licenseNumber",
            width: "auto",
          },
          {
            headerName: "Province",
            field: "stateName",
            tooltipField: "stateName",
            width: "auto",
          },
          {
            headerName: "District",
            field: "districtName",
            tooltipField: "districtName",
            width: "auto",
          },
          // {
          //   headerName: "Total Supply",
          //   field: "totalSupply",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalSupply);
          //   },
          //   // aggFunc: customAggregation,
          // },
          {
            headerName: "Lot Approved Quantity",
            field: "lotApprovedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Rejected Quantity",
            field: "lotRejectedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotRejectedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Pending For Approval",
            field: "lotApprovalPending",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovalPending);
            },
            // aggFunc: customAggregation,
          },

        ]);
      } 
      else {
        setColumnDefs([
          //columns for source manufacturer grid
          {
            headerName: "S.No",
            // valueGetter: "node.rowIndex + 1",
            cellRenderer: params => params.node.rowPinned ? "" : params.node.rowIndex + 1,
            sortable: false,
            width: 100,
          },
          {
            headerName: "Agency Name",
            field: "agencyName",
            tooltipField: "agencyName",
            width: "auto",
          },
          {
            headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
            field: "licenseNumber",
            tooltipField: "licenseNumber",
            width: "auto",
          },
          {
            headerName: "Province",
            field: "stateName",
            tooltipField: "stateName",
            width: "auto",
          },
          {
            headerName: "District",
            field: "districtName",
            tooltipField: "districtName",
            width: "auto",
          },
          {
            headerName: "No. Of Batches",
            field: "numberOfBatches",
            width: "auto",
            hide: !(props.query["type"].includes("agency-all")),
            cellRendererFramework: (params) => ((params.data.numberOfBatches === 0) || params.node.rowPinned) ? params.data.numberOfBatches : <a href={'javascript:void(0)'} style={{ textDecoration: 'none' }}>{params.data.numberOfBatches}</a>
          },
          {
            headerName: "Total Production",
            field: "totalProduction",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.totalProduction);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Batch Test Approved",
            field: "batchTestApproved",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.batchTestApproved);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Batch Test Rejected",
            field: "batchTestRejected",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.batchTestRejected);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Not Tested Quantity",
            field: "notTestedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.notTestedQuantity);
            },
            // aggFunc: customAggregation,
          },
          // {
          //   headerName: "Total Demand",
          //   field: "totalDemand",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalDemand);
          //   },
          //   hide: !["4"].includes(routeParams.categoryId)
          //   // aggFunc: customAggregation,
          // },
          // {
          //   headerName: "Total Supply",
          //   field: "totalSupply",
          //   width: "auto",
          //   valueFormatter: (params) => {
          //     return UnitConverterWeight(params.data.totalSupply);
          //   },
          //   // aggFunc: customAggregation,
          // },
          {
            headerName: "Lot Approved Quantity",
            field: "lotApprovedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Rejected Quantity",
            field: "lotRejectedQuantity",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotRejectedQuantity);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Lot Pending For Approval",
            field: "lotApprovalPending",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.lotApprovalPending);
            },
            // aggFunc: customAggregation,
          },
          {
            headerName: "Tested Available Quantity",
            field: "availableTested",
            width: "auto",
            valueFormatter: (params) => {
              return UnitConverterWeight(params.data.availableTested);
            },
            // aggFunc: customAggregation,
          },

        ]);
      }
    } else {//here it is school

      setColumnDefs([
        {
          headerName: "S.No",
          // valueGetter: "node.rowIndex + 1",
          cellRenderer: params => params.node.rowPinned ? "" : params.node.rowIndex + 1,
          sortable:false,
          width: 100,
        },
        {
          headerName: "Agency Name",
          field: "agencyName",
          tooltipField: "agencyName",
          width: "auto",
        },
        {
          headerName: routeParams.reportType === "lab" ? "TC Number" : "License Number",
          field: "licenseNumber",
          tooltipField: "licenseNumber",
          width: "auto",
        },
        {
          headerName: "Province",
          field: "stateName",
          tooltipField: "stateName",
          width: "auto",
        },
        {
          headerName: "District",
          field: "districtName",
          tooltipField: "districtName",
          width: "auto",
        },
        // {
        //   headerName: "Total Supply",
        //   field: "totalSupply",
        //   width: "auto",
        //   valueFormatter: (params) => {
        //     return UnitConverterWeight(params.data.totalSupply);
        //   },
        //   // aggFunc: customAggregation,
        // },
        {
          headerName: "Lot Approved Quantity",
          field: "lotApprovedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotApprovedQuantity);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Lot Rejected Quantity",
          field: "lotRejectedQuantity",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotRejectedQuantity);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Lot Pending For Approval",
          field: "lotApprovalPending",
          width: "auto",
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.lotApprovalPending);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Dispatched Quantity",
          field: "totalDispatched",
          width: "auto",
          hide: !props.query["type"].includes("school-all"),
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.totalDispatched);
          },
          // aggFunc: customAggregation,
        },
        {
          headerName: "Tested Available Quantity",
          field: "totalAvailable",
          width: 200,
          valueFormatter: (params) => {
            return UnitConverterWeight(params.data.totalAvailable);
          },
          
          // aggFunc: customAggregation,
        },
        {
          headerName: "Link to view Lot Wise",
          field: "lotDetailsLink",
          width: 200,
          hide: props.query["type"].includes("school-all"),
          // valueFormatter: (params) => {
          //   return "Lot Wise Details";
          // },
          cellRendererFramework: (params) => params.node.rowPinned ? "" : <a href ={'javascript:void(0)'}>{'Lot Wise Details'}</a> 
          
          // aggFunc: customAggregation,
        },
      ]);
    }
  }, [routeParams.categoryId, routeParams.reportType, props.query]);


  useEffect(() => {
    const tempColumnHeaderMap = columnDefs.reduce((acc, column) => {
      if (column.field && !column.hide) {
        acc[column.field] = column.headerName;
      }
      return acc;
    }, {});
    setColumnHeaderMap(tempColumnHeaderMap);
  }, [columnDefs])


  const gridRef = useRef();

  const onFirstDataRendered = () => gridRef.current.api.refreshCells();

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const hideFooter = (["labsForParticularData"].includes(props.query["type"]))

  const valueFormaterKeys = ['totalQuantity', 'remainingQuantity', 'lotApprovedQuantity', 'lotRejectedQuantity', 'lotApprovalPending','totalAvailable','totalDemand', 'totalSupply', 'totalProduction','batchTestApproved', 'batchTestRejected', 'notTestedQuantity', 'totalDispatched', 'availableTested'  ];

  let fData = []
  let fDataNoUnits = []
  const generateExcelData = () => {
    fData = []
    fDataNoUnits = []
    let count = 1;
    console.log('props.rowData', props.rowData)
    props.rowData.forEach((x) => {
      if(props.query["type"] == 'labsForParticularData'){
        x['batchNo'] = x['batchNo'] || x['lotNo'];
        x['state.displayName'] = x['state'] ? x['state']['displayName'] : ''
        x['lab.name'] = x['lab'] ? x['lab']['name'] : ''

      }
      const obj = {}
      const objNoUnits = {}
      // obj['S.No'] = count;
      // count++;
      for (const key in x) {
        if (columnHeaderMap[key]) {

          if (valueFormaterKeys.includes(key)) {
            obj[columnHeaderMap[key]] = UnitConverterWeight(x[key])
          } else {
            obj[columnHeaderMap[key]] = x[key];
          }
          objNoUnits[columnHeaderMap[key]] = x[key]
        }
      }
      const reOrderedObj = {}
      reOrderedObj['S.No'] = count
      count++;
      Object.values(columnHeaderMap).forEach(key => {
        // if (key in obj) {
          reOrderedObj[key] = obj[key] !== undefined ? obj[key] : '';
        // }
      });

      // fData.push(obj)
      fData.push(reOrderedObj)
      fDataNoUnits.push(objNoUnits)
    })
  }
  // let fileName = `agency_${Date.now()}`;

  // const exportType = 'xls';

  const formatDateToDescriptive = (dateStr)=>{
    if(!dateStr){
      return "-"
    }
    const date = new Date(dateStr);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const getCurrentTimestamp = () =>{
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replaceAll('/','-');
    
    const formattedTime = now.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replaceAll(':','-');

    return (formattedDate+"Time"+formattedTime)
  }

  const getFileNameForExcel = () =>{
    let fileName;

    if(props.fileNameForExcel){
      fileName =  props.fileNameForExcel +"_"+ getCurrentTimestamp()
    } else{
      fileName = 'excel'+"_"+ getCurrentTimestamp()
    }

    return fileName
  }

  const getHeaderForExcel = () =>{

    let header;

    header =  `${props.headerForExcel}(${formatDateToDescriptive(props.query['fromDate'])} to ${formatDateToDescriptive(props.query['toDate'])})`

    return header;
  }

  const exportToExcel = async () => {
    generateExcelData()

    const headersToSum = [
      "totalQuantity",
      "Total Production",
      "Batch Test Approved",
      "Batch Test Rejected",
      "Not Tested Quantity",
      "Total Demand",
      "Total Supply",
      "Lot Approved Quantity",
      "Lot Rejected Quantity",
      "Lot Pending For Approval",
      "Total Available Quantity Excluding Non Tested",
      "Tested Available Quantity",
      "Total Available",
      "Total Dispatched",
      "Samples To Receive",
      "Samples Received at Lab",
      "Samples in Lab",
      "No. of Tests Performed",
      "No. Of Batches",
      "Samples Rejected Without Testing",

    ];
    const headersWithoutUnits =  ["No. Of Batches","Samples To Receive", "Samples Received at Lab", "Samples in Lab", "No. of Tests Performed", "Samples Rejected Without Testing"]
    const sums = headersToSum.reduce((acc, header) => {
      // const headersWithoutUnits =  ["No. Of Batches"]
      // acc[header] = UnitConverterWeight(
      //       fDataNoUnits.reduce((sum, item) => sum + (item[header] || 0), 0)
      //     );
      acc[header] = headersWithoutUnits.includes(header)
        ? fDataNoUnits.reduce((sum, item) => sum + (item[header] || 0), 0)
        : UnitConverterWeight(
            fDataNoUnits.reduce((sum, item) => sum + (item[header] || 0), 0)
          );
      return acc;
    }, {});

    // exportFromJSON({ data: fData, fileName, exportType, });
    const fileNameForExcel = getFileNameForExcel();
    const headerForExcel = getHeaderForExcel();

    const headers = Array.from(new Set(fData.flatMap(Object.keys)));
    const numberOfColumns = headers.length;
    
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const endColumn = String.fromCharCode(65 + numberOfColumns - 1);
    const mergeRange = `A1:${endColumn}1`;

    worksheet.mergeCells(mergeRange);
    worksheet.getCell('A1').value = headerForExcel;
    worksheet.getCell('A1').alignment = { horizontal: 'left' };
    worksheet.getCell('A1').font = { bold: true, size: 12 };

    worksheet.getRow(3).values = headers;

    worksheet.getRow(3).font = { bold: true };

    fData.forEach((item) => {
      const rowValues = headers.map(header => item[header] !== undefined ? item[header] : '');
      worksheet.addRow(rowValues);
    });

    if(!hideFooter){
      
      worksheet.addRow([]);
      
      const footerRow = worksheet.addRow([]);
      footerRow.getCell(1).value = 'Total';
      footerRow.getCell(1).font = { bold: true };
      
      headersToSum.forEach((header) => {
        if(headers.includes(header)){
          const columnIndex = headers.indexOf(header) + 1; // +1 because ExcelJS is 1-based index
          footerRow.getCell(columnIndex).value = sums[header];
          footerRow.getCell(columnIndex).font = { bold: true };
        }
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileNameForExcel}.xlsx`);


  }



  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return { background: 'rgb(183 210 252)', fontWeight: 'bold', color: '#333' };
    }
    if (params.node.rowIndex % 2 != 0) {
      return { background: "#F8F8F8" };
    }
  };

  // let overlayNoRowsTemplate = (props.query['level']!='country') ? '<span style="padding: 10px; display: block; text-align: center;">No Data to show</span>' : '<span style="padding: 10px; display: block; text-align: center;">Please apply filters to view data.</span>';

  let overlayNoRowsTemplate = '<span style="padding: 10px; display: block; text-align: center;">No Data to show</span>'

  const gridOptions = {
    overlayNoRowsTemplate: overlayNoRowsTemplate,
  };

  const pinnedBottomRowData = [
    {
      manufacturerName: "TOTAL",
      agencyName: "TOTAL",
      name: "TOTAL",
      isCategoryEnabled: "",
      data: props.rowData.reduce((sum, row) => sum + row.data, 0),
      lotApprovedQuantity: props.rowData.reduce((sum, row) => sum + row.lotApprovedQuantity, 0),
      lotRejectedQuantity: props.rowData.reduce((sum, row) => sum + row.lotRejectedQuantity, 0),
      lotApprovalPending: props.rowData.reduce((sum, row) => sum + row.lotApprovalPending, 0),
      totalAvailable: props.rowData.reduce((sum, row) => sum + row.totalAvailable, 0),
      numberOfBatches: props.rowData.reduce((sum, row) => sum + row.numberOfBatches, 0),
      totalProduction: props.rowData.reduce((sum, row) => sum + row.totalProduction, 0),
      batchTestApproved: props.rowData.reduce((sum, row) => sum + row.batchTestApproved, 0),
      batchTestRejected: props.rowData.reduce((sum, row) => sum + row.batchTestRejected, 0),
      notTestedQuantity: props.rowData.reduce((sum, row) => sum + row.notTestedQuantity, 0),
      totalDispatched: props.rowData.reduce((sum, row) => sum + row.totalDispatched, 0),
      availableTested: props.rowData.reduce((sum, row) => sum + row.availableTested, 0),
      samplesToReceive: props.rowData.reduce((sum, row) => sum + row.samplesToReceive, 0),
      samplesReceived: props.rowData.reduce((sum, row) => sum + row.samplesReceived, 0),
      samplesInLab: props.rowData.reduce((sum, row) => sum + row.samplesInLab, 0),
      testsDone: props.rowData.reduce((sum, row) => sum + row.testsDone, 0),
      samplesRejected: props.rowData.reduce((sum, row) => sum + row.samplesRejected, 0),
    },
  ];

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between", margin:"10px", alignItems:"center" }}
      >
        <div style={{width:"40%"}}>
          <TextField
            id="outlined-required"
            label="Search..."
            value={props.searchKeyWord}
            onChange={(e)=>{props.setSearchKeyWord(e.target.value)}}
            InputProps={{
              style: {
                borderRadius: 40,
                height: '45px',
                textAlign:"center"
              },
              endAdornment: 
                (<InputAdornment position='end'>
                  <IconButton onClick={()=>{props.setSearchKeyWord("")}}>
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>)
            }}
          />
        </div>
        <a
          // onClick={props.handleDownloadClick}
          onClick={exportToExcel}
          href="javascript:void(0)"
          hidden={['labs-all','labsForParticularData'].includes(props.query['type'])}
        >
          <span style={{ fontSize: "1rem" }}>Download</span>
          <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} m={1} />
        </a>
      </div>
      <div className="ag-theme-alpine" style={{ height: 430 }}>
        <AgGridReact
          rowData={props?.rowData?.filter(item=>
            (
              item?.agencyName?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) || 
              (item?.name?.toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || 
              (item?.licenseNumber?.toString().includes(props.searchKeyWord.toLowerCase()) ) || 
              (item?.nablCertificate?.toString().toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || 
              (item?.batchNo?.toString().toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || 
              (item?.lotNo?.toString().toLowerCase().includes(props.searchKeyWord.toLowerCase()) ) || 
              (props.query['type'] == 'labsForParticularData')
            ))}
          columnDefs={columnDefs}
          animateRows={true}
          getRowStyle={getRowStyle}
          onRowClicked={props.onRowClick}
          onCellClicked={props.onCellClicked}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          pinnedBottomRowData={!hideFooter && pinnedBottomRowData}
          onFirstDataRendered={onFirstDataRendered}
          onSortChanged={onFirstDataRendered} // Ensures order on sort change
          onFilterChanged={onFirstDataRendered} // Ensures order on filter change
          ref={gridRef}
        />
      </div>
    </>
  );
}
