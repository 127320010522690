import React, { useEffect, useMemo, useState } from "react";
import "./PremixSections.css";
import BatchDetails from "../BatchDetails/BatchDetails";
import VendorDetails from "../VendorDetails/VendorDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography, Modal, Chip, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import SourceCategory from "../SourceCategory/SorceCategory";
import SorceCategoryInfo from "../Packaging Info/SorceCategoryInfo";
import {
  getAutopopulatedData,
  getTypeDetailsById,
  createBatch,
  putBatch,
  getQRCode,
  UploadFile,
  getCategoryDocs,
  getSourceCategory,
  getManufacturerCategoryDetails,
  getUomData,
  createLot,
  getHistory,
  getAllActionData,
  updateBatchStatus,
  getBatchTree,
  getNodeModalData,
  getPackagingInfo,
  getWastageInfo,
  getVendorList,
  getCanSkipRawMaterial,
  getPurchaseOrderDetails,
  getLotTree
} from "../../../services/FortificationService";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import { labTestDetails, labTestStatus } from "../../../services/LabService";
import LabTestDataTable from "../LabTestForm/DataTable";
import { useDispatch, useSelector } from "react-redux";
import ActionsModal from "../../../components/ActionButtons/GenericForm/ActionsModal";
import {
  BACK_BUTTON,
  LOT_TEST_DETAIL,
  BATCH_TEST_DETAIL,
  USAGE_DETAIL,
  VENDOR_DETAIL,
  HISTORY,
  BATCH_HISTORY_TREE,
  MATERIAL_DETIALS,
  WASTAGE_DETAILS,
  PACKAGING_INFO,
  DETAILS_PAGE,
  SOURCE_LOTS,
  TARGET_LOTS,
  MILLER_UI_TEXT,
} from "../../../textConstant";
import Usage from "../UsageGrid/UsageGrid";
import { LISTING_USAGE_HEADER } from "../UsageGrid/ColumnHeader";
import { USAGE_LOT_HEADERS } from "../UsageGrid/UsageLotHeaders";
import DropDownFRk from "../../../components/DropdownFRK/DropDownFRk";
import ErrorSwitchCase from "../../../services/Error_Handler_Service";
import BasicStepper from "../../../components/Timeline/BasicStepper";
import StepperModal from "../../../components/Modal/StepperModal";
import Horizontal_Tree from "../../../components/Timeline/HorizontalTree";
import { ChipColorSelector } from "../../../utils/ChipColorHandler";
import { SuccessMessageSelector } from "../../../utils/SuccessMessages";
import Wastage from "../Wastage Grid/WastageGrid";
import { LISTING_WASTAGE_HEADER } from "../Wastage Grid/ColumnHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getMenuDetails } from "../../../modules/menuSlice";
import moment from "moment";

//changes
//lot detail 249 coming
//Material Detail not in raw material
//instruction bigger
//batch detail topMargin less
//text button (Click To Expand) and (Click To Collapse)
//batchtestdetail if empty not show
//history empty
//hide everything if data not thr except wastage detail

import { INFO_HEADER } from "../Packaging Info/ColumnHeader";
import PackageInfo from "../Packaging Info/PackagingInfoGrid";
import { MAIN_BLUE } from "../../../color";
import KeycloakService from "../../../services/KeycloakService";
import { getFortificationCategoryDocTypeByIDnew, selectLabAccess } from "../../../services/adminService";
import { SOURCE_LOTS_HEADERS } from "../UsageGrid/SourceLotHeaders";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
import { SelfDeclaredMaterials } from "../SourceCategory/SelfDeclaredMaterials";
import { useAppSelector } from "../../../services/StoreHooks";

function PremixSections({ categoryId, generatedId, QRBatchDetails,isInspector }) {

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  const categoryIdToNameMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.id.toString()] = current.name;
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const categoryNameLowerToIdMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.name.toLowerCase()] = current.id.toString()
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const {translate} = useTranslatewrapper()
  const navigate = useNavigate();
  const getId = useParams();
  const hasHistory = window?.history?.length > 1;
  const userdetails = KeycloakService.getUserDetail();

  const isSuperAdmin = userdetails?.isSuperAdmin;
  const isMonitor = userdetails?.isMonitor
  if (getId.uuid) getId.id = generatedId;
  const pathNameCapitalize = useLocation().pathname.split("/")[1];
  const currentPath = !isSuperAdmin ?  `/${pathNameCapitalize}/${getId.categoryId}/${getId.type}` : `/${pathNameCapitalize}/fortification/${getId.type}/${getId.categoryId}`;
  const dispatch = useDispatch();
  const [VendorData, setVendorData] = React.useState({});
  const [BatchData, setBatchData] = React.useState();
  const [formData, setFormData] = useState();
  const [MaterialData, setMaterialData] = React.useState([]);
  const [searchString, setSearchString] = useState("");
  const [canSkipRawMaterial,setCanSkipRawMaterial]=useState();
  const [batchState, setBatchState] = useState("")

  const setQueryparam = (value) => {
    if(value){
      setSearchString(value);
    }else{
      setSearchString("")
    }
  };

  const [createFileUploadFields, setCreateFileUploadFields] = React.useState(
    []
  );
  const [loader, setLoader] = React.useState(false);
  const [loader1, setLoader1] = React.useState(false);

  const [batchSizeLot, setBatchSizeLot] = React.useState([]);
  const [batchLotDetails, setBatchLotDetails] = React.useState([]);
  const [sendBatchDocs, setSendBatchDocs] = React.useState();
  const [sourceCategoryData, setSourceCategoryData] = React.useState({});

  const [fileBody, setFileBody] = React.useState([]);
  const [deleteFiles, setDeleteFiles] = React.useState([]);

  const [tableData, setTableData] = useState([]);
  const [mixes, setMixes] = useState();
  const [comments, setComments] = useState("");
  const [mixComments, setMixComments] = useState([]);
  const [mixesData, setMixesData] = useState([]);
  const [removedNames, setRemovedNames] = useState([]);

  const [fileTableData, setFileTableData] = useState([]);
  const [img, setImg] = useState(null);
  const [rowWastageData, setRowWastageData] = useState([]);
  const [packageInfoData, setPackageInfoData] = useState([]);
  const [columnDefs, setColumnDefs] = React.useState(LISTING_USAGE_HEADER(translate));
  const [columnWastageDefs, setColumnWastageDefs] = React.useState(
    LISTING_WASTAGE_HEADER(translate)
  );
  const [columnInfoDefs, setColumnInfoDefs] = React.useState(INFO_HEADER);
  const [rowData, setRowData] = useState([]);
  const [sourceLots, setSourceLots] = useState([]);
  const [targetLots, setTargetLots] = useState([]);
  const [actionButtons, setActionButtons] = useState();
  const [showButton, setShowButton] = useState(false);
  const [showButtonAction, setShowButtonAction] = useState(false);

  const [modalState, setModalState] = React.useState(false);
  const [isBatchReadyForDispatch, setIsBatchReadyForDispatch] = useState(false);
  const [isDispatchDisable, setIsDispatchDisable] = useState(false);
  const [frkManufacturerDetails, setFrkManufacturerDetails] = useState([]);
  const [manufacturerCount , setManufacturerCount] = useState(0);
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState([]);
  const [batchRemainingQuantity, setBatchRemainingQuantity] = useState(0);
  const [batchHistory, setBatchHistory] = useState([]);
  const [entireDocData, setEntireDocData] = useState({});
  const [lotTableData, setLotTableData] = useState([]);
  const [lotFileTableData, setLotFileTableData] = useState([]);
  const [disableVendorData, setDisableVendorData] = useState(false);
  const [disableQuantityEdit,setDisableQuantityEdit] = useState(false);
  const [disablePackageInfo, setDisablePackageInfo] = useState(false);
  const [currentDetailChipLabel, setCurrentDetailChipLabel] = useState("");
  const [getDate, setGetDate] = useState([]);
  const [dateData, setDateData] = useState([]);

  const stateData = useSelector((state) => state.stateData);
  const [batchTreeData, setbatchTreeData] = useState([]);
  const [lastActionDate, setLastActionDate] = useState();
  const [showBatchStepper, setshowBatchStepper] = useState(null);
  const [nodeDataMap, setnodeDataMap] = useState({});
  const [nodeStepperData, setnodeStepperData] = useState([]);
  const [chipList, setChipList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [saveButtonCondition, setSaveButtonCondition] = useState(false);
  const [batchTestDocs, setBatchTestDocs] = useState()
  const [lotTestDocs, setLotTestDocs] = useState()
  const [selfDeclaredTestDocs, setSelfDeclaredTestDocs] = useState()
  

  const [frkType, setFrkType] = useState("PUBLIC");
  const setFRKType = (string) => {
    setFrkType(string);
  };

  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(true);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [isExpanded9, setIsExpanded9] = useState(false);
  const [isExpanded10, setIsExpanded10] = useState(false);
  const [isExpanded11, setIsExpanded11] = useState(false);
  const [isExpanded12, setIsExpanded12] = useState(false);
  const [independentBatch, setIndependentBatch] = useState(null);
  const [remainingQuantity, setRemaningQuantity] = useState();
  const [totalQuantity, setTotalQuantity] = useState();
  const [sampleId, setSampleId] = useState();
  const [qrData, setQrData] = useState();
  const [manufacturerId, setManufacturerId]= useState(null);
  const [showAddWastage , setShowAddWastage] = useState(false)
  const [labManufacturerId, setLabManufacturerId] = useState(null)
  const [handleMixDelete, setHandleMixDelete] = useState(false);
  const [sizeLoading, setSizeLoading] = useState(false);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [canInspect, setCanInspect] = useState(false)
  const [inspectModalData, setInspectModalData] = useState({})
  const [batchOrLotCategory, setBatchOrLotCategory] = useState({})
  const [inspectButton, setInspectButton] = useState(false)

  const [inspectBatchTestData, setInspectBatchTestDate] = useState([])
  const [inspectLotTestData, setInspectLotTestDate] = useState([])
  const [showLabDropDown, setShowLabDropDown] = useState(false)
  const [packageInfoNotDispatched,setPackageInfoNotDispatched] = useState([])
  const currentActionNew = useSelector(getMenuDetails);
  const location = useLocation();
  const loc = location.pathname.split("/")[1];
  const inspect = location.pathname.split("/")[2];
  const pathType = location.pathname.split("/")[4];
  const [purchaseOrderList , setPurchaseOrderList] = useState([])
  const [targetManfId, setTargetManfId]= useState("")
  const [isSourceLotsExpanded, setIsSourceLotsExpanded] = useState(false);
  const [isTargetLotsExpanded, setIsTargetLotsExpanded] = useState(false);
  const [ hideLotDetail, sethideLotDetail] = useState(false)
  const [selfDeclaredMaterialsArr, setSelfDeclaredMaterialsArr] = useState([])
  const [selfDeclaredTestsArr, setSelfDeclaredTestsArr] = useState([])
  const [testedLabName, setTestedLabName] = useState('')
  const [testedLabTcNumber, setTestedLabTcNumber] = useState('')
  const [selfDeclaredSampleTestedDate, setSelfDeclaredSampleTestedDate] = useState('')
  useEffect(() => {
    setShowButton(false);
    setLoader(true);
    setLoader1(true);

    if(independentBatch){
      fetchVendorList();
    }
    if (getId.id) {
      getBatchHistoryById();
      refreshPackagingInfo();
      editFetchData();
    } else {
      createFetchData();
      fetchCreateFileUploadFields();
    }
  }, [ independentBatch, location.pathname]);


const fetchSkipData=(manufacturerId, categoryId)=>{
 if(manufacturerId){

   const canSkipRawMaterials= getCanSkipRawMaterial(manufacturerId,categoryId);
   canSkipRawMaterials.then((res)=>setCanSkipRawMaterial(res.data))
 }
}
  useEffect(()=>{
    if(getId.id){
      setDisableQuantityEdit(true);
    }
    if(!getId.id){
      const user=sessionStorage.getItem("user");
      const data = JSON.parse(user);
      const id = data.id;
      fetchSkipData(id, getId.categoryId)
    }
  },[])
  useEffect(() => {
  
    if (loc !== 'admin') {
      const isIndBatch = currentActionNew?.filter(
        (d) => (d.categoryName.toLowerCase().split(" ").join("-") === loc || d.categoryName.toLowerCase().split(" ").join("-") === inspect)
      )[0]?.independentBatch;
      setIndependentBatch(isIndBatch);
    } else{
      setIndependentBatch(false);
    }
    if(loc === 'inspect'){
      const isInspect = currentActionNew.filter(
        (d)=> d.categoryName.toLowerCase() === inspect)[0]?.entityStates.filter(
          (d)=> d.name.toLowerCase() === pathType)[0].canInspect
      setCanInspect(isInspect)
    }
  }, [location, currentActionNew]);
  useEffect(() => {
    if(searchString || searchString === ""){
      fetchFrkManufacturers(1);
    }
  }, [searchString]);
  
  useEffect(() => {
    if(manufacturerId){
      fetchFrkManufacturers(1);
    }
  }, [frkType, manufacturerId]);
  // useEffect(() => {
  //   if(targetManfId){
  //     fetchPurchaseOrder(1);
  //   }
  // }, [targetManfId]);
  useEffect(() => {
    if(labManufacturerId){
      showLabMenu()
    }
  }, [labManufacturerId]);
  const fetchVendorList = async () => {
    let res = await getVendorList(categoryId);
    try {
      setVendorList(res.data.data);
    } catch (error) {}
  };
  const handleData = (data) => {
    setVendorData(data);
  };

  const dipatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };



  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader,
      },
    });
  };
  const showWastageActions =(name)=>{
    const actions = ["dispatched","receivedRejected" ,"sentBackRejected"];
    if(actions.includes(name)) {
      return false
    }
    else{
      return true
    }
  }
  const editFetchData = async (resId) => {
    dispatchShowLoader(true);
    let data;
    if (!QRBatchDetails)
      data = await getTypeDetailsById(
        categoryId,
        getId.type,
        resId || getId.id
      );
    else data = { data: QRBatchDetails };
    setInspectModalData(data?.data);
    setBatchOrLotCategory(data?.data?.category)


    setLastActionDate(data?.data?.lastActionDate?.slice(0, 10));
    getTestResultsData(data?.data?.id, getId.type);
    getTestResultStatus(getId.type === 'batch' ? data?.data?.batch?.id:  data?.data?.id, getId.type);
    setCurrentDetailChipLabel(data?.data?.state?.displayName);
    if (data?.data?.batch?.id) {
      getTestResultsData(data?.data?.batch?.id, "batch", true);
    }
    if(data?.data?.state?.name === "created"){
      setBatchState("created")
      if(isSuperAdmin || isMonitor){
        setDisablePackageInfo(true);
        setDisableVendorData(true);
      }
    }
    if (
      data?.data?.state?.name === "batchToDispatch" ||
      data?.data?.state?.name === "partiallyDispatched" ||
      data?.data?.state?.name === "fullyDispatched" 
      || isSuperAdmin
      || data?.data?.state?.name === "batchSampleInLab"
      || data?.data?.state?.name === "sentBatchSampleToLabTest"
      || data?.data?.state?.name === "rejected" 
      || data?.data?.state?.name === "batchSampleRejected"
    ) {
      setDisableVendorData(true);
      if(!isSuperAdmin && !isMonitor &&(data?.data?.state?.name === "batchToDispatch" || data?.data?.state?.name === "partiallyDispatched")) {
        setDisablePackageInfo(false);
      } else {
        setDisablePackageInfo(true);
      }
    }
    if (data?.data?.comments || data?.data?.batch?.comments) {
      setComments(
        data?.data?.comments
          ? data?.data?.comments
          : data?.data?.batch?.comments
      );
    }
    if(getId.type == "batch"){
      setShowAddWastage(true)
    }
    fetchSkipData( getId.type == "lot" ? data?.data?.batch?.manufacturerId : data?.data?.manufacturerId, data?.data?.category?.id);
    if (getId.type == "lot") {
      getLotTreeDataById()

      setDisableVendorData(true);
      setEntireDocData(data?.data);
      const qr = await getQRCode(
        data?.data?.qrcode,
        "FORTIFICATION_LOT_QR_CODE"
      );
      setQrData(data?.data?.qrcode);
      qr && setImg(URL.createObjectURL(qr?.data));
      if(showWastageActions(data?.data?.state?.name)
      && (userdetails.id==data?.data?.targetManufacturerId || isSuperAdmin)){
        setShowAddWastage(true)
      }
      else{
        setShowAddWastage(false)
      }
      const SorceCategoryArray = await getSourceCategory(categoryId);
      if (data?.data?.actions) setActionButtons(data?.data?.actions);
      else {
        const actions = await getAllActionData(
          categoryId,
          getId.type,
          getId?.id
        );
        actions && setActionButtons(actions.data);
      }
      data?.mixes &&
        data?.mixes.map((item) => {
          setMixesData([
            ...mixesData,
            {
              uomId: item.uom.id,
              quantityUsed: item.targetBatch.totalQuantity,
              sourceLotId: item.sourceLot.id,
            },
          ]);
        });
      data?.data?.batch?.mixes &&
        data?.data?.batch?.mixes.map((item) => {
          setMixComments([
            ...mixComments,
            {
              name: item?.sourceCategory?.name,
              comment: item?.sourceLot?.batch?.comments,
              prefetchedInstructions:
                (item?.sourceLot?.batch?.prefetchedInstructions || item?.sourceLot?.prefetchedInstructions),
            },
          ]);
        });

      if (data?.data && SorceCategoryArray) {
        if (data?.data?.batch?.mixes) {
          setMaterialData(data?.data?.batch?.mixes);
        }else{
          setCanSkipRawMaterial(true)
        }
        if(data?.data?.batch){

          setFileBody(() =>
            data?.data?.batch?.batchDocs.map((document) => {
              return {
                name: document?.categoryDoc?.id ? "" : document?.name,
                categoryDocId: document?.categoryDoc?.id
                  ? document?.categoryDoc?.id
                  : undefined,
                path: document?.path,
              };
            })
          );
        }else{
          setFileBody([])
        }
        setSourceCategoryData(SorceCategoryArray.data);

        setCreateFileUploadFields(() => [
          ...prepareSourceCategoryExtraDocs(
            data?.data?.batch?.batchDocs,
            SorceCategoryArray.data.documents.map((item) => item.docType)
          ),
        ]);

        let percentagePremix;
        let UserDefinedBatchNo;
        let rawMaterialType;
        let rawMaterialTypeOther;
        let rawMaterialName;
        let cropYear;
        let riceType = "",
          grade,
          variety;
        let batchName = "";
        const dispatchDate = data?.data?.dateOfDispatch?.slice(0, 10);
        const LotReceiveddDate = data?.data?.dateOfReceiving?.slice(0, 10);
        const expdate = data?.data?.dateOfExpiry?.slice(0, 10);
        const mnfdate = data?.data?.dateOfManufacture?.slice(0, 10);
        var VendorDataObj = {};
        let parsedSelfDeclaredTestsArr;
        let parsedSelfDeclaredMaterialsArr;
        let testedLabNameStr = ''
        let testedLabTcNumberStr = ''
        let selfDeclaredSampleTestedDateStr = ''
        
        if(data?.data?.sourceLots && data?.data?.sourceLots?.length > 0){
         setSourceLots( data?.data?.sourceLots.map(sLOt => {
            return {
              state: sLOt.state?.displayName,
              uom: sLOt?.uom?.name,
                
              totalQuantity: sLOt?.totalQuantity,
              remainingQuantity: sLOt?.remainingQuantity,
              dateOfManufacture: sLOt.dateOfDispatch,

              lotNo: sLOt?.lotNo,
              id: sLOt?.id,
              batchNo: sLOt?.lotNo,
             
              batchName:sLOt?.batch?.name || "",
              batchId:sLOt?.batch?.id || "",

            }
          }))
        }
        if(data?.data?.targetLots && data?.data?.targetLots?.length > 0){
          setTargetLots( data?.data?.targetLots.map(sLOt => {
             return {
               state: sLOt.state?.displayName,
               uom: sLOt?.uom?.name,
                 
               totalQuantity: sLOt?.totalQuantity,
               remainingQuantity: sLOt?.remainingQuantity,
               dateOfManufacture: sLOt.dateOfDispatch,
 
               lotNo: sLOt?.lotNo,
               id: sLOt?.id,
               batchNo: sLOt?.lotNo,
              
               batchName:sLOt?.batch?.name || "",
               batchId:sLOt?.batch?.id || "",
 
             }
           }))
         }
        if (data.data.batch) {
          data.data.batch.batchProperties.map((newData) => {
            switch (newData.name) {
              case "manufacture_name":
                VendorDataObj["name"] = newData.value;
                // code block
                break;
              case "manufacture_type":
                VendorDataObj["vendorType"] = newData.value;
                break;
              case "manufacture_accreditedByAgency":
                VendorDataObj["accreditedByAgency"] = newData.value || false;
                break;
              case "manufacture_licenseNumber":
                VendorDataObj["licenseNumber"] = newData.value;
                break;
              case "manufacture_completeAddress":
                VendorDataObj["completeAddress"] = newData.value;
                break;
              case "percentage_of_premix_required":
                percentagePremix =
                  newData.value && newData.value !== "undefined"
                    ? Number(newData.value)
                    : "";
                break;
              case "manufacture_batchNumber":
                UserDefinedBatchNo = newData?.value;
                break;
              case "raw_material_type":
                rawMaterialType = translate(newData?.value);
                break;
              case "raw_material_type_other":
                rawMaterialTypeOther = newData?.value;
                break;
              case "rice_CropYear":
                cropYear = newData.value;
                break;
              case "rice_RiceType":
                riceType = newData.value === "undefined" ? "" : newData.value;
                break;
              case "rice_Grade":
                grade = newData.value;
                break;
              case "rice_Variety":
                variety = newData.value;
                break;
              case "batch_name":
                batchName = newData.value;
                break;
              case "raw_material_name":
                rawMaterialName = newData.value;
                break;
              case "batchTestDetails":
                parsedSelfDeclaredTestsArr = JSON.parse(newData.value)
                break;
              case "rawMaterialDetails":
                parsedSelfDeclaredMaterialsArr = JSON.parse(newData.value)
                break;
              case "testedAtLab":
                testedLabNameStr = newData.value;
                break;
              case "testedLabTCNumber":
                testedLabTcNumberStr = newData.value;
                break;
              case "sampleTestedDate":
                selfDeclaredSampleTestedDateStr = newData.value;
                break;
            }
          });
    
            setSelfDeclaredTestsArr(parsedSelfDeclaredTestsArr);
            setSelfDeclaredMaterialsArr(parsedSelfDeclaredMaterialsArr)
            setTestedLabTcNumber(testedLabTcNumberStr)
            setTestedLabName(testedLabNameStr)
            setSelfDeclaredSampleTestedDate(selfDeclaredSampleTestedDateStr)
          setVendorData({
            name: VendorDataObj.name,
            vendorType: VendorDataObj.vendorType,
            licenseNumber: VendorDataObj.licenseNumber,
            accreditedByAgency:
              VendorDataObj.accreditedByAgency === "true" ? true : false,
            completeAddress: VendorDataObj.completeAddress,
            QR: (independentBatch === false && qr) ? URL.createObjectURL(qr.data) : null,
          });
          sethideLotDetail(false)
        } else {
          sethideLotDetail(true)
          batchName = data.data.lotNo;

          let VendorArray = null;

          if (independentBatch == false) {
            if(data?.data?.manufacturerId){
            VendorArray = await getAutopopulatedData(data?.data?.manufacturerId);
            }
            else{
              VendorArray = await getAutopopulatedData();
            }
          }

          // setSourceCategoryData(SourceData);
          VendorArray && setVendorData({ ...VendorArray?.data, QR: (independentBatch === false && qr) ? URL.createObjectURL(qr.data) : null });
        }
        if(getId.type == 'lot'){
          if(!independentBatch){

            UserDefinedBatchNo = ''
          }
          if(data?.data?.lotProperties?.find(item=>item.name=='manufacture_lotNumber')?.value){
            UserDefinedBatchNo = data?.data?.lotProperties?.find(item=>item.name=='manufacture_lotNumber')?.value
          }
        }


        // setComments(
        //   data?.data?.batch?.comments + ". " +
        //   data?.data?.batch?.prefetchedInstructions
        // )
        let tempData = {};
        if (data?.data?.sizeUnits) {
          setRemaningQuantity(data?.data?.remainingQuantity);
          tempData = {
            BatchName: batchName,
            UserDefinedBatchNo:UserDefinedBatchNo,
            RawMaterialType: rawMaterialType,
            RawMaterialTypeOther: rawMaterialTypeOther,
            
            RawMaterialName: rawMaterialName,
            BatchNoOfPremixManufracturer: data?.data?.batch?.batchNo,
            PremixPercentageNeededForFRK: percentagePremix,
            CropYear: cropYear,
            RiceType: riceType || "",
            Grade: grade,
            Variety: variety,
            ManufacturingDate: mnfdate,
            ExpiryDate: expdate,
            DispatchDate: dispatchDate,
            ReceivedDate: LotReceiveddDate,
            lot: [...data?.data?.sizeUnits],
            batchDocs: [],
            TotalQuantity: data?.data?.totalQuantity,
            RemainingQuantity: data?.data?.remainingQuantity,
            manufacturerId: data?.data?.manufacturerId,
            targetManufacturerId : data?.data?.targetManufacturerId,
            LotNo: data?.data?.lotNo
          };
        } else {
          setRemaningQuantity(data?.data?.remainingQuantity);
          tempData = {
            BatchName: batchName,
            UserDefinedBatchNo:UserDefinedBatchNo,
            RawMaterialType: rawMaterialType,
            RawMaterialTypeOther: rawMaterialTypeOther,
            RawMaterialName: rawMaterialName,
            BatchNoOfPremixManufracturer: data?.data?.batch?.batchNo,
            PremixPercentageNeededForFRK: percentagePremix,
            CropYear: cropYear,
            RiceType: riceType || "",
            Grade: grade,
            Variety: variety,
            ManufacturingDate: mnfdate,
            ExpiryDate: expdate,
            DispatchDate: dispatchDate,
            ReceivedDate: LotReceiveddDate,
            lot: [],
            batchDocs: [],
            TotalQuantity: data?.data?.totalQuantity,
            RemainingQuantity: data?.data?.remainingQuantity,
          };
        }
        if (!tempData.PremixPercentageNeededForFRK) {
          delete tempData.PremixPercentageNeededForFRK;
        }
        setManufacturerId(tempData.manufacturerId)
        setLabManufacturerId(tempData.targetManufacturerId)
        setBatchData(tempData);

        const usageData = [];
        const usageReferenceData = data?.data?.lots || data?.data?.usage;
        if (usageReferenceData && usageReferenceData.length > 0) {
          if (usageReferenceData[0]?.targetBatch)
            setColumnDefs(() => USAGE_LOT_HEADERS(translate));
          usageReferenceData.forEach((data) => {
            let item;
            usageReferenceData[0]?.targetBatch
              ? (item = data.targetBatch)
              : (item = data);
            usageData.push({
              state: item.state,
              uom: usageReferenceData[0]?.targetBatch
                ? data?.uom?.name
                : item.uom,
              totalQuantity: usageReferenceData[0]?.targetBatch
                ? data?.quantityUsed
                : item?.totalQuantity,
              dateOfManufacture: item?.dateOfManufacture,

              dateOfExpiry: item?.dateOfExpiry,

              batchNo: item?.batchNo,
              lotNo: item?.lotNo,
              manufacturerBatchNumber: data?.targetBatch?.manufacturerBatchNumber,
              id: item?.id,
              manufacturerDetails: `${item?.manufacturerName} ${formatAddress(
                item?.manufacturerAddress
              )}`,
              batchName:usageReferenceData[0].targetBatch?usageReferenceData[0]?.targetBatch?.category?.name: "",
              batchId:usageReferenceData[0].targetBatch?usageReferenceData[0]?.targetBatch?.category?.id : "",

            });
          });

          setRowData((data) => usageData);
        }
      }
      const packageInfoData = [];
      data?.data?.sizeUnits?.forEach((data) => {
        packageInfoData.push({
          size: data?.size,
          quantity: data?.quantity,
          uom: data?.uom?.name,
          conversionFactorKg: data?.uom?.conversionFactorKg,
          uomId: data?.uom?.id,
          isDispatched:data?.isDispatched
        });
      });
      setPackageInfoNotDispatched(packageInfoData.filter((data) => data.isDispatched === false ))
      setPackageInfoData((data) => packageInfoData);
      // (!packageInfoData || packageInfoData.length === 0) && setDisableVendorData(false);
      const wastageData = [];
      data?.data?.wastes?.forEach((data) => {
        wastageData.push({
          wastageQuantity: data.wastageQuantity,
          comments: data.comments,
          uom: data.uom.name,
          reportedDate: moment(data.reportedDate).local().format("DD-MMM-YYYY"),
          uomId: data?.uom?.id,
        });
      });
      setRowWastageData((date) => wastageData);
      if (
        data?.data?.state?.name === "batchToDispatch" &&
        data?.data?.remainingQuantity > 0
      ) {
        setIsBatchReadyForDispatch(true);
        setBatchRemainingQuantity({
          value: data?.data?.remainingQuantity,
          name: data?.data?.uom?.name,
          conversionFactorKg: data?.data?.uom?.conversionFactorKg,
        });
        // fetchFrkManufacturers(1);
        fetchUOMData();
      }
      setLoader(false);
    } else {
      getBatchTreeDataById(data?.data?.id);
      setEntireDocData(data?.data);
      const qr = await getQRCode(
        data?.data?.qrcode,
        "FORTIFICATION_BATCH_QR_CODE"
      );
      setQrData(data?.data?.qrcode);
      qr && setImg(URL.createObjectURL(qr?.data));
      const SorceCategoryArray = await getSourceCategory(categoryId);
      setActionButtons(data?.data?.actions);
      setIsBatchReadyForDispatch(false);

      // data?.data?.mixes &&
      //   data?.data?.mixes.map((item) => {
      //     setMixesData([
      //       ...mixesData,
      //       {
      //         uomId: item.uom.id,
      //         quantityUsed: item.targetBatch.totalQuantity,
      //         sourceLotId: item.sourceLot.id,
      //       },
      //     ]);
      //   });

      if (data?.data && SorceCategoryArray) {
        // if (data?.data?.state?.id == 4) setLoader1(true);
        setMaterialData(data?.data?.mixes);
        setFileBody(() =>
          data?.data?.batchDocs.map((document) => {
            return {
              name: document?.categoryDoc?.id ? "" : document?.name,
              categoryDocId: document?.categoryDoc?.id
                ? document?.categoryDoc?.id
                : undefined,
              path: document?.path,
              id: document.id
            };
          })
        );
        setSourceCategoryData(SorceCategoryArray.data);

        setCreateFileUploadFields(() => [
          ...prepareSourceCategoryExtraDocs(
            data?.data?.batchDocs,
            SorceCategoryArray.data.documents.map((item) => item.docType)
          ),
        ]);

        let percentagePremix;
        let percentagePremixId;
        let batchName;
        let batchNameId;
        let CropYearId;
        let RiceTypeId;
        let GradeId;
        let VarietyId;
        let UserDefinedBatchNo;
        let rawMaterialType;
        let rawMaterialTypeOther;
        let rawMaterialName;
        let parsedSelfDeclaredTestsArr;
        let parsedSelfDeclaredMaterialsArr;
        let testedLabNameStr = ''
        let testedLabTcNumberStr = ''
        let selfDeclaredSampleTestedDateStr = ''
        const expdate = data?.data?.dateOfExpiry?.slice(0, 10);
        const mnfdate = data?.data?.dateOfManufacture?.slice(0, 10);
        var VendorDataObj = {};

        // setComments(data?.data?.comments)

        data.data.batchProperties.map((newData) => {
          switch (newData.name) {
            case "manufacture_name":
              VendorDataObj["name"] = newData.value;
              VendorDataObj["nameId"] = newData.id || null;
              // code block
              break;
            case "manufacture_type":
              VendorDataObj["vendorType"] = newData.value;
              VendorDataObj["vendorTypeId"] = newData.id || null;
              break;
            case "manufacture_accreditedByAgency":
              VendorDataObj["accreditedByAgencyId"] = newData.id || null;
              VendorDataObj["accreditedByAgency"] = newData.value || false;
              break;
            case "manufacture_licenseNumber":
              VendorDataObj["licenseNumber"] = newData.value;
              VendorDataObj["licenseNumberId"] = newData.id || null;
              break;
            case "manufacture_completeAddress":
              VendorDataObj["completeAddress"] = newData.value;
              VendorDataObj["completeAddressId"] = newData.id || null;
              break;
            case "percentage_of_premix_required":
              percentagePremix =
                newData.value && newData.value !== "undefined"
                  ? Number(newData.value)
                  : "";
               percentagePremixId = newData.id || null;
              break;
            case "manufacture_batchNumber":
              UserDefinedBatchNo = newData?.value;
              break;
              case "raw_material_type":
                rawMaterialType = newData?.value;
                break;
            case "raw_material_type_other":
              rawMaterialTypeOther = newData?.value;
            break;
            case "raw_material_name":
              rawMaterialName = newData?.value;
              break;
            case "batch_name":
              batchName = newData.value;
              batchNameId = newData.id || null
              break;
            case "rice_CropYear":
              CropYearId = newData.id;
              break;
            case "rice_RiceType":
              RiceTypeId = newData.id;
              break;
            case "rice_Grade":
              GradeId = newData.id;
              break;
            case "rice_Variety":
              VarietyId = newData.id;
              break;
            case "batchTestDetails":
              parsedSelfDeclaredTestsArr = JSON.parse(newData.value)
              break;
            case "rawMaterialDetails":
              parsedSelfDeclaredMaterialsArr = JSON.parse(newData.value)
              break;
            case "testedAtLab":
              testedLabNameStr = newData.value;
              break;
            case "testedLabTCNumber":
              testedLabTcNumberStr = newData.value;
              break;
            case "sampleTestedDate":
              selfDeclaredSampleTestedDateStr = newData.value;
              break;
          }
        });

        setSelfDeclaredTestsArr(parsedSelfDeclaredTestsArr);
        setSelfDeclaredMaterialsArr(parsedSelfDeclaredMaterialsArr)
        setTestedLabTcNumber(testedLabTcNumberStr)
        setTestedLabName(testedLabNameStr)
        setSelfDeclaredSampleTestedDate(selfDeclaredSampleTestedDateStr)

        if(getId.type == 'lot'){
          if(!independentBatch){

            UserDefinedBatchNo = ''
          }
          if(data?.data?.lotProperties.find((item=>item.name=='manufacture_lotNumber'))?.value){
            UserDefinedBatchNo = data?.data?.lotProperties.find((item=>item.name=='manufacture_lotNumber'))?.value
          }
        }

        setVendorData({
          name: VendorDataObj.name,
          nameId: VendorDataObj.nameId,
          vendorType: VendorDataObj.vendorType,
          vendorTypeId: VendorDataObj.vendorTypeId,
          licenseNumber: VendorDataObj.licenseNumber,
          licenseNumberId: VendorDataObj.licenseNumberId,
          accreditedByAgency:
            VendorDataObj.accreditedByAgency === "true" ? true : false,
          accreditedByAgencyId: VendorDataObj.accreditedByAgencyId,
          completeAddress: VendorDataObj.completeAddress,
          completeAddressId: VendorDataObj.completeAddressId,
          QR: qr ? URL.createObjectURL(qr.data) : null,
        });
        setRemaningQuantity(data?.data?.remainingQuantity);
        const data1 = {
          BatchName: batchName,
          BatchNameId: batchNameId,
          BatchNoOfPremixManufracturer: data?.data?.batchNo,
          PremixPercentageNeededForFRK: percentagePremix,
          UserDefinedBatchNo:UserDefinedBatchNo,
          RawMaterialType: rawMaterialType,
          RawMaterialTypeOther: rawMaterialTypeOther,
          RawMaterialName: rawMaterialName,
          PremixPercentageNeededForFRKId: percentagePremixId,
          ManufacturingDate: mnfdate,
          ExpiryDate: expdate,
          lot: [...data?.data?.sizeUnits],
          batchDocs: [...data?.data?.batchDocs],
          TotalQuantity: data?.data?.totalQuantity,
          RemainingQuantity: data?.data?.remainingQuantity,
          manufacturerId: data?.data?.manufacturerId,
          CropYearId,
          RiceTypeId,
          GradeId,
          VarietyId,
        };
        if (!data1.PremixPercentageNeededForFRK) {
          delete data1.PremixPercentageNeededForFRK;
        }
        setManufacturerId(data1.manufacturerId)
        setLabManufacturerId(data1.manufacturerId)
        setBatchData(data1);

        const usageData = [];
        const usageReferenceData = data?.data?.lots || data?.data?.usage;
        if (usageReferenceData?.targetBatch)
          setColumnDefs(() => USAGE_LOT_HEADERS(translate));
        usageReferenceData.forEach((data) => {
          let item;
          usageReferenceData[0]?.targetBatch
            ? (item = data.targetBatch)
            : (item = data);
          usageData.push({
            state: item.state,
            uom: usageReferenceData[0]?.targetBatch
              ? data?.uom?.name
              : item.uom,
            totalQuantity: usageReferenceData[0]?.targetBatch
              ? data?.quantityUsed
              : item?.totalQuantity,
            dateOfManufacture: getDate
              .filter((date) => date.title === translate('manufacturingDate'))
              .reverse()[0]?.date,

            dateOfExpiry: getDate
              .filter((date) => date.title === translate('expiryDate'))
              .reverse()[0]?.date,

            licenseNumber: item?.licenseNumber,
            dateOfDispatch: item?.dateOfDispatch,
            manufacturerLotNumber: item?.lotProperties?.find(item => item.name == 'manufacture_lotNumber')?.value,

            batchNo: item?.batchNo,
            lotNo: item?.lotNo,
            id: item?.id,
            manufacturerDetails: `${item?.manufacturerName} ${formatAddress(
              item?.manufacturerAddress
            )}`,
            batchName:usageReferenceData[0].targetBatch?usageReferenceData[0]?.targetBatch?.category?.name: "",
            batchId:usageReferenceData[0].targetBatch?usageReferenceData[0]?.targetBatch?.category?.id : "",
     
          });
        });
        const packageInfoData = [];
        data?.data?.sizeUnits?.forEach((data) => {
          packageInfoData.push({
            size: data.size,
            quantity: data.quantity,
            uom: data.uom.name,
            uomId: data.uom.id,
            conversionFactorKg: data?.uom?.conversionFactorKg,
          isDispatched:data?.isDispatched

          });
        });
      setPackageInfoNotDispatched(packageInfoData.filter((data) => data.isDispatched === false ))
      setPackageInfoData((data) => packageInfoData);
        // (!packageInfoData || packageInfoData.length === 0) &&
        //   setDisableVendorData(false);
        const wastageData = [];
        data?.data?.wastes?.forEach((data) => {
          wastageData.push({
            wastageQuantity: data.wastageQuantity,
            comments: data.comments,
            uom: data.uom.name,
            reportedDate: moment(data.reportedDate).local().format("DD-MMM-YYYY"),
          });
        });
        setRowWastageData((date) => wastageData);
        setRowData((data) => usageData);
      }
      if (
        (data?.data?.state?.name === "batchToDispatch" ||
          data?.data?.state?.name === "partiallyDispatched") &&
        data?.data?.remainingQuantity > 0
      ) {
        setIsBatchReadyForDispatch(true);
        setBatchRemainingQuantity({
          value: data?.data?.remainingQuantity,
          name: data?.data?.uom?.name,
          conversionFactorKg: data?.data?.uom?.conversionFactorKg,
        });
        // fetchFrkManufacturers(1);
        fetchUOMData();
      }
      else{
      setIsBatchReadyForDispatch(false);
      }
      setLoader(false);
    }
    dispatchShowLoader(false);
  };

  const getBatchSizeLot = (data) => {
    setMixesData(
      data.map((material) => ({
        id: material.id,
        sourceLotId: material.size,
        quantityUsed: material.quantity,
        uomId: material.uomId,
        batchName: material.batchName
      }))
    );
  };

  const getBatchDetailLot = (data) => {
    setBatchLotDetails(data);
  };
  const handleEditMixDelete = () => { };

  const createFetchData = async () => {
    let VendorArray = null;
    if (independentBatch == false) {
      VendorArray = await getAutopopulatedData();
    }

    const SorceCategoryArray = await getSourceCategory(categoryId);
    SorceCategoryArray && setSourceCategoryData(SorceCategoryArray.data);
    // setSourceCategoryData(SourceData);

    VendorArray && setVendorData({ ...VendorArray?.data });

    SorceCategoryArray &&
      setCreateFileUploadFields(
        SorceCategoryArray.data.documents.map((item) => item)
      );

    setLoader(false);
  };
  const removeDeletedFiles = () => {
    // let tempFileBody = [...fileBody];
    if(!fileBody) return []
    let tempFileBody = [
      ...fileBody.filter((obj) => !(obj.name == "" && obj.categoryDocId == "")),
    ];
    let tempFileBodyDeepCopy = _.cloneDeep(tempFileBody)
    removedNames.forEach((removedName) => {
      const deleteFileIndexesArray = fileBody.filter(
        (file) => !file?.categoryDocId && file.name === removedName
      );
      deleteFileIndexesArray.forEach((deleteItem) => {
        const deleteFileIndex = tempFileBodyDeepCopy.findIndex(
          (file) => file.name === deleteItem.name
        );
        if (deleteFileIndex !== -1) {
          tempFileBodyDeepCopy.splice(deleteFileIndex, 1);
        }
      });
    });

    deleteFiles.forEach((deleteItem) => {
      const deleteFileIndex = tempFileBodyDeepCopy.findIndex(
        (file) =>
          file.path === deleteItem.path &&
          file.name === deleteItem.name &&
          file.categoryDocId === deleteItem.categoryDocId
      );
      if (deleteFileIndex !== -1) {

        tempFileBodyDeepCopy.splice(deleteFileIndex, 1);
      }
    });

    // changed the delete file logic to fix bug of not removing the last file
    //tempFileBody = fileBody.filter((data) => !deleteFiles?.includes(data));
    return tempFileBodyDeepCopy;
  };

  const addPath = async (file, idx, callType) => {
    const fileUploaded = await UploadFile({
      file: file,
    });

    setSendBatchDocs({
      id: null,
      name: "",
      categoryDocId: idx,
      path: fileUploaded.data,
      expiry: "2023-02-07T14:45:31.465Z",
    });
  };

  const fetchCreateFileUploadFields = async () => {
    const CategoryDocsArray = await getCategoryDocs(categoryId);
    CategoryDocsArray &&
      setCreateFileUploadFields(
        CategoryDocsArray.data.data.map((item) => item)
      );
  };

  const setSaveData = (e) => {
    let lot = [];

    batchSizeLot?.map((batch) => {
      lot.push({
        uomId: batch.uomId,
        size: batch.size,
        quantity: batch.quantity,
      });
    });
  };

  const sendFormData = async (e, setSaveOrCreateDisable) => {
    let lot = [];
    dispatchShowLoader(true);
    batchSizeLot?.map((batch) => {
      lot.push({
        uomId: batch.uomId,
        size: batch.size,
        quantity: batch.quantity,
      });
    });

    let batchPropArr = [
      {
        name: "percentage_of_premix_required",
        value: `${e.PremixPercentageNeededForFRK ? e.PremixPercentageNeededForFRK : ""
          }`,
        id: e.PremixPercentageNeededForFRKId || null
      },
      {
        name: "manufacture_name",
        value: `${VendorData.name}`,
        id: VendorData.nameId || null,
      },
      {
        name: "manufacturer_id",
        value: `${VendorData.manufacturerId}`,
        id:  null,
      },
      {
        name: "manufacture_type",
        value: `${VendorData.vendorType}`,
        id: VendorData.vendorTypeId || null,
      },
      {
        name: "manufacture_licenseNumber",
        value: `${VendorData.licenseNumber}`,
        id: VendorData.licenseNumberId || null,
      },
      {
        name: "manufacture_accreditedByAgency",
        value: VendorData.accreditedByAgency || false,
        id: VendorData.accreditedByAgencyId || null,
      },
      {
        name: "manufacture_completeAddress",
        value: `${VendorData.completeAddress}`,
        id: VendorData.completeAddressId || null,
      },

      {
        name: "manufacture_batchNumber",
        value: `${(e.UserDefinedBatchNo) || ''}`,
        id: e.UserDefinedBatchId || null,
      },
      {
        name: "raw_material_type",
        // value: `${(e.RawMaterialType === 'other' ? e.RawMaterialTypeOther : e.RawMaterialType) || ''}`,
        value: `${(e.RawMaterialName === 'Premix Base' ? e.RawMaterialTypeOther : e.RawMaterialType) || ''}`,
        id: e.RawMaterialTypeId || null,
      },
      {
        name: "rice_CropYear",
        value: `${e.CropYear}`,
        id: e.CropYearId || null,
      },
      {
        name: "rice_RiceType",
        value: `${e.RiceType}`,
        id: e.RiceTypeId || null,
      },
      {
        name: "rice_Grade",
        value: `${e.Grade}`,
        id: e.GradeId || null,
      },
      {
        name: "rice_Variety",
        value: `${e.Variety}`,
        id: e.VarietyId || null,
      },
    ]

    if(location.pathname.split("/")[1] === 'raw-material'){
      batchPropArr.push(
        {
          name: "raw_material_name",
          value: `${(e.RawMaterialName) || ''}`,
          id: e.RawMaterialNameId || null,
        }, {
          name: "batch_name",
          value: `${e.RawMaterialName}`,
          id: e.BatchNameId || null
        }
      )

    } else{
      batchPropArr.push(
        {
          name: "batch_name",
          value: `${e.BatchName}`,
          id: e.BatchNameId || null
        }
      )
    }

    const batchData = {
      categoryId: categoryId,
      batchNo: e.BatchNoOfPremixManufracturer,
      dateOfManufacture:
        getDate &&
        getDate
          .filter((date) => date.title === translate('manufacturingDate'))
          .reverse()[0]?.date,
      dateOfExpiry:
        getDate &&
        getDate.filter((date) => date.title === translate('expiryDate')).reverse()[0]
          ?.date,
      comments: comments,
      totalQuantity: e.TotalQuantity,

      batchProperties: batchPropArr,
      batchDocs: removeDeletedFiles(),
      sizeUnits: [...lot],
      batchWastes: [],
      mixes: [...mixesData],
      targetManufacturerId: 0,
      isReceivedAtTarget: true,
      isTargetAcknowledgedReport: true,
      isTargetAccepted: true,
      dispatchedAtSource: true,
      stateId: 1,
    };

    if (getId.id) {
      const res = await putBatch(
        categoryId,
        !stateData ? getId.id : entireDocData?.batch?.id,
        batchData
      );
      dispatchShowLoader(false);
      setSaveOrCreateDisable(false);
      if (res?.status === 200 || res?.status === 201) {
        dipatchNotification("success", [`${translate(getId.type?.toLowerCase())} ${translate('hasBeenUpdated')}`]);
        setShowButton(false);
        editFetchData();
        getBatchHistoryById();
        stateData
          ? navigate(`${currentPath}`)
          : navigate(`${currentPath}/details/${getId.id}`);
      }
    } else {
      const res = await createBatch(categoryId, batchData);
      dispatchShowLoader(false);
      setSaveOrCreateDisable(false);

      if (res?.status === 201) {
        dipatchNotification("success", [translate(`${getId.type} has been created`)]);

        if (stateData) navigate(`${currentPath}`);
        else {
          navigate(`${currentPath}/details/${res?.data}`);
          setShowButton(false);
          const actions = await getAllActionData(
            categoryId,
            getId.type,
            res?.data
          );
          actions && setActionButtons(actions.data);
          window.scrollTo(0, document.body.scrollHeight);
          setChipList(batchData?.mixes?.map((elem) => elem.sourceLotId));
          editFetchData(res?.data);
          getBatchHistoryById(res?.data);
        }
      } else if (res?.status === 400) {
        dipatchNotification("error", [`${res?.msg} `]);
      } else if (res?.status === 500) {
        dipatchNotification("error", [`${getId.type} has 500 `]);
      }
    }
  };

  const getTestResultsData = async (dataId, type, nonGeneric) => {
    const data = await labTestDetails(dataId || getId.id, type);
    if (data?.data?.data) {
      const genericData = data.data.data;
      const inspectedTestDetails = genericData.filter((elem) => elem.isInspectionSample == true);
      const labTestedDetails = genericData.filter((elem) => elem.isInspectionSample == false)

      genericData.map((elem) => elem);

      if (!nonGeneric) {
        setTableData((data) => labTestedDetails);
        setInspectBatchTestDate((data) => inspectedTestDetails)
        setSampleId(labTestedDetails[0]?.id);
      } else {
        setLotTableData((data) => labTestedDetails);
        setInspectLotTestDate((data) => inspectedTestDetails)
        setSampleId(labTestedDetails[0]?.id);
        setLoader1(false);
      }
    }
  };
  const getTestResultStatus = async (dataId, type) => {
    if(isInspector){
      const data = await labTestStatus(dataId || getId.id, type);
      if(data){
        setInspectButton(data?.data)
      }
    }
  };
  const filterDataRedux = useSelector((state) => state.filterData);

  const setFilters = (filterData) => {
    var json = filterDataRedux.searchCriteriaList;
    var json1 = structuredClone(json);

    json1[0].value = "";
    json1[1].value = "";
    json1[2].value = "";

    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };

  const prepareSourceCategoryExtraDocs = (extraDocs, requiredDocs) => {
    if (
      (extraDocs && extraDocs.length > 0) ||
      (requiredDocs && requiredDocs.length > 0)
    ) {
      const docCategories = _.groupBy(
        extraDocs,
        (data) => data?.name || data.categoryDoc?.docType?.name
      );
      const formattedExtras = [];
      Object.entries(docCategories).forEach((item) => {
        const formattedObject = {
          paths: item[1],
        };
        if (item[1][0]?.categoryDoc) {
          formattedObject.id = item[1][0]?.categoryDoc?.id;
          formattedObject.name = item[0];

          formattedObject.isMandatory = item[1][0]?.categoryDoc?.isMandatory;
          formattedObject.paths = item[1].map((docItem) => {
            return { ...docItem, categoryDocId: docItem.categoryDoc?.id };
          });
        } else {
          formattedObject.name = item[0];
        }
        formattedExtras.push(formattedObject);
      });
      return customSortDocuments(
        addRequiredDocs(formattedExtras, requiredDocs)
      );
    }
    return [];
  };

  const addRequiredDocs = (currentDocs, requiredDocs) => {
    requiredDocs.forEach((doc) => {
      if (currentDocs.findIndex((elem) => elem.name === doc.name) === -1) {
        currentDocs.push({ ...doc, categoryId: doc.id, paths: [] });
      }
    });
    return currentDocs;
  };

  const customSortDocuments = (initialArray) => {
    return initialArray.sort((a, b) => {
      if (a.id && !b.id) {
        return -1; // a comes first
      } else if (!a.id && b.id) {
        return 1; // b comes first
      } else {
        return 0; // no change in order
      }
    });
  };

  const fetchUOMData = async () => {
    const dropdownData = await getUomData();
    if (dropdownData?.data?.data)
      setUnitsOfMeasurement(dropdownData?.data?.data);
  };

  const fetchFrkManufacturers = async (pageNumber1) => {
    const manId = manufacturerId || ""
    if(manId){
      setSizeLoading(true);
      setPageNumber(pageNumber1);
      // const prev = [...categoryDropdownData[id]?.data];
      const manufacturerCategories = await getManufacturerCategoryDetails(
        frkType,
        searchString,
        getId?.categoryId,
        pageNumber1,
        pageSize,
        manId,
        "CREATION"
      );
  
      if (manufacturerCategories?.data) 
      {
        setManufacturerCount(manufacturerCategories?.data?.count)
        const manufacturerCategoryNames = manufacturerCategories?.data?.data?.map(
          (item) => {
            return {
              id: item.id,
              name: item.name,
            };
          }
        );
  
        setFrkManufacturerDetails((d)=> pageNumber1!==1  ? [...d, ...manufacturerCategoryNames] : manufacturerCategoryNames);
      }
    }
  };

  const submitLotData = async (data) => {
    dispatchShowLoader(true);
    const lotCreated = await createLot(categoryId, {
      ...data,
      batchId: getId.id,
    });
    if (lotCreated?.status === 201) {
      dipatchNotification("success", [translate('lotDispatchedSuccessfully')]);
      setModalState(false);
      const actions = await getAllActionData(categoryId, getId.type, getId.id);
      actions && setActionButtons(actions.data);
      editFetchData();
      getBatchHistoryById();
    }
    dispatchShowLoader(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return moment(dateString).local().format("yyyy-MM-DD");
  };
  const getBatchHistoryById = async (itemId) => {
    let res = await getHistory(getId.type.toUpperCase(), itemId || getId.id);
    if (res) {
      const history = res.data[itemId || getId.id].map((obj) => ({
        label: obj.state,
        description: formatDate(obj.dateOfAction),
        comments: obj?.comments,
        toManufacturerName: obj?.target?.name,
        toManufacturerLicenseNo: obj?.target?.licenseNo
      }));
      setBatchHistory(history);
    }
  };

  const updateActionState = async (statusConstant , result ="") => {
    const actionUpdateResult = await updateBatchStatus(
      categoryId,
      getId.id,
      statusConstant,
      getId?.type,
      result
    );
    if (actionUpdateResult?.data) {
      const snackMessage = SuccessMessageSelector(
        statusConstant?.currentAction?.display,
        getId.type
      ).map(item => translate(item));
      if (Array.isArray(snackMessage) && snackMessage.length > 0) {
        dipatchNotification("success", snackMessage);
      }
      const actions = await getAllActionData(categoryId, getId.type, getId.id);
      actions && setActionButtons(actions.data);
      editFetchData();
      getBatchHistoryById();
    }
    return actionUpdateResult;
  };

  const generateSourceBatchTree = (obj, lotId = null)=> {
    const {category} = obj;
    const name = (category.name === 'Raw Material' ? obj.name : category?.name.replace('MILLER',MILLER_UI_TEXT)) + ' - ' + (obj.batchNo || obj.lotNo) + (obj.prefetchedInstructions ? (`- ${obj.prefetchedInstructions}`): '');
      const sourceBatchTree = {
          name: addToolTip( name),
          children: []
      }
      if (!nodeDataMap.nodeName) {
        nodeDataMap[name] = {
          batchId: obj.id,
          lotId: lotId,
        };
      }

      if(obj.mixes){
        obj.mixes.forEach(mix=>{
          const child = generateSourceLotTree(mix.sourceLot);
          if(child){
            sourceBatchTree.children.push(child)
          }
        })
      }

      return sourceBatchTree;
  }

  const generateSourceLotTree = (obj) => {
    const {category} = obj;
      const sourceLotNode = {
          name: addToolTip((category.name === 'Raw Material' ? obj.sourceBatch.name : category?.name.replace('MILLER',MILLER_UI_TEXT)) + ' - ' + (obj.batchNo || obj.lotNo) + (obj.prefetchedInstructions ? (`- ${obj.prefetchedInstructions}`): '')),
          children : []
      }

      if(obj.sourceBatch){
        const mChild = generateSourceBatchTree(obj.sourceBatch, obj.id);
        sourceLotNode.name = mChild.name;
        sourceLotNode.children = mChild.children
          
      }else if(obj.sourceLots && obj.sourceLots.length >0){
        const name = category?.name.replace('MILLER',MILLER_UI_TEXT) + ' - ' +  obj.lotNo;
          const lotChildren = {
              name: addToolTip ((category.name === 'Raw Material' ? obj.name : category?.name.replace('MILLER',MILLER_UI_TEXT)) + ' - ' +  obj.lotNo),
              children: []
          }
          if (!nodeDataMap[name]) {
            nodeDataMap[name] = {
              lotId: obj.id,
            };
          }
          obj.sourceLots.forEach(sLot => {
              const sChild = generateSourceLotTree(sLot);
              if(sChild){
                  lotChildren.children.push(sChild)
              }
          })
          return lotChildren.children.length > 0 ? lotChildren : {name: lotChildren.name};

      }
      return sourceLotNode.children.length > 0 ? sourceLotNode : {name: sourceLotNode.name}
  }

  const sliceNode = (name) => {
    if (name.length > 15) {
      return name.slice(0, 15) + '...';
    }
    return name;

  }
  const addToolTip = (nodeName) => {
    return (
      <svg key={nodeName} width="auto" height="15">
        <g>
          <Tooltip title={nodeName}>
            <text x="10" y="15">
              {sliceNode(nodeName)}
            </text>
          </Tooltip>
        </g>
      </svg>
    );
  };

  const getLotTreeData = (data) => {
    let children = [];
    let child;
    if(data.sourceBatch){
      child = formatTreeData(data.sourceBatch)
      children.push(child)
    }else{
      data.sourceLots?.forEach((sLot)=>{
        child = getLotTreeData(sLot);
        children.push(child);
      })
    }
    let nodeName = data.category.name + " - " + data.lotNo.toString();
    if (!nodeDataMap.nodeName) {
      nodeDataMap[nodeName] = {
        lotId: data.id,
      };
    }
    return {
      name: addToolTip(nodeName),
      textProps: { x: -10, y: 25, linkLength: "100px" }, children
    }
  }
  const formatTreeData = (obj, lotId = null) => {
    let children = [];
    let child;
    if (obj.mixes) {
      for (let i = 0; i < obj.mixes.length; i++) {
        if (obj.mixes[i].sourceLot) {
          child = formatTreeData(obj.mixes[i].sourceLot)
          children.push(child)
        }
      }
    }
    else if (obj.sourceBatch) {
      child = formatTreeData(obj.sourceBatch)
      children.push(child)
    } else if (obj.sourceLots) {
      obj.sourceLots?.forEach((sLot) => {
        if(sLot.sourceBatch){
          child = formatTreeData(sLot.sourceBatch);
          children.push(child);
        }
        else{
          child = formatTreeData(sLot);
          children.push(child);
        }
      })
    }
    const name = obj.lotNo ? obj?.lotNo?.toString() : obj?.batchNo?.toString();
    let nodeName = obj?.category?.name + " - " + name;
    if (!nodeDataMap.nodeName) {
      nodeDataMap[nodeName] = {
        batchId: obj.id,
        lotId: lotId,
      };
    }
    let x_shift = (-1 * nodeName.length * 7) / 2;
    return children.length > 0
      ? {
        name: addToolTip(nodeName),
        textProps: { x: lotId ? x_shift : -10, y: 25 },
        children: children,
      }
      : {
        name: addToolTip(nodeName)        ,
        textProps: { x: -10, y: 25 },
      };
  };
  const formatAddress = (addressJsonString) => {
    try {
      let addressJson = JSON.parse(addressJsonString);
      if (!addressJson) {
        throw new Error("Null Address");
      }
      let { laneOne, laneTwo, country, state, district, village, pinCode } =
        addressJson;
      if(!addressJson.hasOwnProperty('pinCode')){
        pinCode = addressJson.pincode
      }
      return (
        (laneOne ? `${laneOne}, ` : "") +
        (laneTwo ? `${laneTwo}, ` : "") +
        (village?.name ? `${village?.name}, ` : village ? `${village}, ` : "") +
        (district?.name
          ? `${district?.name}, `
          : district
            ? `${district}, `
            : "") +
        (state?.name ? `${state?.name}, ` : state ? `${state}, ` : "") +
        (country?.name ? `${country?.name}, ` : country ? `${country}, ` : "") +
        (pinCode || "")
      );
    } catch (error) {
      return "";
    }
  };

  const getBatchTreeDataById = async (dataId) => {
    let res = await getBatchTree(categoryId, dataId);
    let temp = generateSourceBatchTree(res.data);
    setbatchTreeData(temp);
    setnodeDataMap(nodeDataMap);
  };

  const getLotTreeDataById = async () => {
    let res = await getLotTree(categoryId, getId.id);
    let temp = generateSourceLotTree(res.data);
    setbatchTreeData(temp);
    setnodeDataMap(nodeDataMap);
  };

  const clickHandler = async (event, node) => {
    dispatchShowLoader(true);
    let selectedNode = nodeDataMap[node];
    let res = await getNodeModalData(nodeDataMap[node]);
    let data =
      res.data.history;
    let temp = node.split("- ");
    let batchNo = temp[temp.length - 1].trim();

    if(selectedNode?.batchId){
      const labDetailsForBatch = await labTestDetails(selectedNode.batchId, 'batch',undefined) 
      setBatchTestDocs(labDetailsForBatch?.data?.data && labDetailsForBatch?.data?.data[0]?.sampleTestDocuments)

      let categoryOfSelectedNode = categoryNameLowerToIdMapRedux[node.split("-")[0].trim().toLowerCase()]

      const docsData = await getFortificationCategoryDocTypeByIDnew(categoryOfSelectedNode, selectedNode?.batchId);

      if(docsData?.data?.data && docsData?.data?.data.filter(doc => (doc.name == 'Test Report')).length > 0){
        setSelfDeclaredTestDocs(docsData?.data?.data.filter(doc => (doc.name == 'Test Report')))
      }


    } else {
      setBatchTestDocs(null)
      setSelfDeclaredTestDocs(null)
    }
    if(selectedNode?.lotId){
      const labDetailsForLot = await labTestDetails(selectedNode.lotId, 'lot',undefined) 
      setLotTestDocs(labDetailsForLot?.data?.data && labDetailsForLot?.data?.data[0]?.sampleTestDocuments)
    } else{
      setLotTestDocs(null)
    }

    let stepperData = data.map((obj) => ({
      label: obj.state,
      description: formatDate(obj.dateOfAction),
      batchNo: batchNo,
      manufacturerName: obj.manufacturerName,
      manufacturerAddress: formatAddress(obj.manufacturerAddress),
      licenseNumber: obj.licenseNumber,
      toManufacturerName: obj?.target?.name,
      toManufacturerLicenseNo: obj?.target?.licenseNo
    }));
    setnodeStepperData(stepperData);
    setshowBatchStepper(batchNo);
    dispatchShowLoader(false);
  };
  function closeModal() {
    setnodeStepperData([]);
    setshowBatchStepper(null);
  }
  const useStyles = makeStyles(() => ({
    formTitle: {
      height: "50px",
      background: "var(--primary-blue)",
      color: "#fff",
      margin: "0 24px",
      padding: "16px 24px",
    },
  }));

  const refreshPackagingInfo = async () => {
    if(getId.type !== 'batch'){
      return;
    }
    const packagingInfo = await getPackagingInfo(getId.categoryId, getId.id);
    const packageInfoDataArray = [];
    packagingInfo?.data?.data?.forEach((data) => {
      packageInfoDataArray.push({
        size: data?.size,
        quantity: data?.quantity,
        uom: data?.uom?.name,
        conversionFactorKg: data?.uom?.conversionFactorKg,
        uomId: data?.uom?.id,
          isDispatched:data?.isDispatched

      });
    });
    setPackageInfoNotDispatched(packageInfoDataArray.filter((data) => data.isDispatched === false ))
    setPackageInfoData((data) => packageInfoDataArray);
  };  
  const refreshWastageInfo = async () => {
    const wastageInfo = await getWastageInfo(getId.categoryId, getId.type,getId.id);
    const wastageData = [];
    if (wastageInfo?.data?.wastes) {
    }
    wastageInfo?.data?.wastes?.forEach((data) => {
      wastageData.push({
        wastageQuantity: data.wastageQuantity,
        comments: data.comments,
        uom: data.uom.name,
        reportedDate: moment(data.reportedDate).local().format("DD-MMM-YYYY"),
        uomId: data?.uom?.id,
      });
    });
    editFetchData();
    setRowWastageData((date) => wastageData);
  };
  const showLabMenu = async () =>{
    const data = await selectLabAccess(labManufacturerId, categoryId , getId.type)
    setShowLabDropDown(data?.data)
    return showLabDropDown
  }
  const showPackackagingMsg =() =>{
    if(packageInfoData){
      if(packageInfoNotDispatched.length === 0) {
        return true
      }
      else return false
    }
    else{
      if(packageInfoData.length === 0){
      return true
    }
    else{return false}
  }
  
}
// const fetchPurchaseOrder = async (pageNumber1) => {
//   setSizeLoading(true);
//   const manId = manufacturerId || ""
//   const purchaseOrderData = await getPurchaseOrderDetails(
//     manId,targetManfId)
//   if (purchaseOrderData?.data) 
//   {
//     setPurchaseOrderList(purchaseOrderData?.data)
//   }
// };

  return (
    <div className="main-content-overlay">
      {getId.id ? (
        <Typography
          align="center"
          mt={2}
          variant="h6"
          sx={{ color: MAIN_BLUE, fontWeight: "600" }}
        >
          {translate(pathNameCapitalize.replace('miller',MILLER_UI_TEXT)).toUpperCase()} {translate(getId.type).toUpperCase()}
          {" " + translate('details').toUpperCase()}
        </Typography>
      ) : (
        <Typography
          align="center"
          mt={2}
          variant="h6"
          sx={{ color: MAIN_BLUE, fontWeight: "600" }}
        >
          {translate(pathNameCapitalize.replace('miller',MILLER_UI_TEXT)).toUpperCase()} {translate(getId.type).toUpperCase()} {translate('create').toUpperCase()}
        </Typography>
      )}
      <StepperModal
        batchNo={showBatchStepper}
        closeModal={closeModal}
        stepperData={nodeStepperData}
        batchTestDocs={batchTestDocs}
        lotTestDocs={lotTestDocs}
        selfDeclaredTestDocs={selfDeclaredTestDocs}
      />
      <Box className="main-content-div" style={{ position: "relative" }}>
        {/* //TODO make this dynamic */}


        {hasHistory &&
        <Button
          onClick={(e) => {
            if(userdetails?.isMonitor) {navigate(-1);}
            else if(window.location.pathname == `/premix/${getId.categoryId}/${getId.type}/details/${getId.id}`){
              navigate(`/premix/${getId.categoryId}/${getId.type}`);
            } else if (window.location.pathname == `/frk/${getId.categoryId}/${getId.type}/details/${getId.id}`){
              navigate(`/frk/${getId.categoryId}/${getId.type}`);
            } else if (window.location.pathname == `/miller/${getId.categoryId}/${getId.type}/details/${getId.id}`){
              navigate(`/miller/${getId.categoryId}/${getId.type}`);
            } else{
              navigate(-1);
            }
            // if (getId.categoryId === "1")
            //   navigate(`/raw-material/${getId.categoryId}/${getId.type}`);
            // else if (getId.categoryId === "2")
            //   navigate(`/premix/${getId.categoryId}/${getId.type}`);
            // else {
            //   navigate(`/frk/${getId.categoryId}/${getId.type}`);
            // }
            setFilters();
          }}
          sx={{ margin: 1 }}
        >
          <ArrowBackIcon />
          <Typography
            sx={{
              fontSize: "13px",
              textDecoration: "underline",

              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#0075FF",
            }}
          >
            {translate(BACK_BUTTON)}
          </Typography>
        </Button>
        }

        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded1(expanded);
          }}
          // defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className="formTitle">
              <Typography className="formBox-text"> {translate('vendorDetails')}</Typography>
              {isExpanded1 ? (
                <Typography className="formBox-helperText">
                  {translate("clickToCollapse")}
                </Typography>
              ) : (
                <Typography className="formBox-helperText">
                  {translate("clickToExpand")}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {loader ? (
              <LoaderComponent />
            ) : (
              <VendorDetails
                VendorData={VendorData}
                getId={getId.id}
                img={img}
                qrData={qrData}
                setFormData={setFormData}
                formData={formData}
                handleData={handleData}
                disableVendorData={disableVendorData}
                independentBatch={independentBatch}
                setShowButton={setShowButton}
                vendorList={vendorList}
                categoryId={categoryId}
                fetchVendorList={fetchVendorList}
                isInspector={isInspector}

              />
            )}
          </AccordionDetails>
        </Accordion>

        {!(loader || stateData) && !canSkipRawMaterial && (
          <Accordion
            onChange={(e, expanded) => {
              setIsExpanded2(expanded);
            }}
            // defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
                />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box className="formTitle">
                <Typography className="formBox-text">
                  {translate('materialDetails')}
                </Typography>
                {isExpanded2 ? (
                  <Typography className="formBox-helperText">
                    {translate("clickToCollapse")}
                  </Typography>
                ) : (
                  <Typography className="formBox-helperText">
                    {translate("clickToExpand")}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {loader || stateData ? (
                <LoaderComponent />
              ) : (
                <>
                {selfDeclaredMaterialsArr?.length > 0 ? 
                (
                  <SelfDeclaredMaterials
                  rawMaterialArr={selfDeclaredMaterialsArr}
                  />
                ) : (
                  <SourceCategory
                    sourceCategoryData={sourceCategoryData}
                    getBatchSizeLot={getBatchSizeLot}
                    comments={comments}
                    chipList={chipList}
                    setComments={setComments}
                    mixComments={mixComments}
                    MaterialData={MaterialData}
                    categoryId={categoryId}
                    showButton={showButton}
                    setShowButtonAction={setShowButtonAction}
                    setShowButton={setShowButton}
                    disableVendorData={disableVendorData}
                    getBatchDetailLot={getBatchDetailLot}
                    handleEditMixDelete={handleEditMixDelete}
                    setHandleMixDelete={setHandleMixDelete}
                    sendFormData={sendFormData}
                    isInspector={isInspector}
                    setSaveButtonCondition={setSaveButtonCondition}
                    getBatchTreeDataById={(id)=>getBatchTreeDataById(id)}

                  />
                )
                }         
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {sourceLots.length > 0 && (
          <Accordion
            onChange={(e, expanded) => {
              setIsSourceLotsExpanded(expanded);
            }}
            // defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#fff",
                    background: "#0075FF",
                    height: "35px",
                  }}
                />
              }
              sx={{ color: "0075FF" }}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Box className="formTitle">
                <Typography className="formBox-text">
                  {translate(SOURCE_LOTS)}
                </Typography>
                {isSourceLotsExpanded ? (
                  <Typography className="formBox-helperText">
                    {translate("clickToCollapse")}
                  </Typography>
                ) : (
                  <Typography className="formBox-helperText">
                    {translate("clickToExpand")}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              
              {sourceLots.length > 0 && (
                <>
                  <Usage rowData={sourceLots} columnDefs={SOURCE_LOTS_HEADERS(translate)} batchNo={BatchData['BatchNoOfPremixManufracturer']}
                isInspector={isInspector} title={'source_lots'}
                  
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}


        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded3(expanded);
          }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
              />
            }
            sx={{ color: "0075FF" }}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Box className="formTitle">
              <div>
                <Typography className="formBox-text">
                  {getId?.type === "batch" ? translate("batchDetails") : translate("lotDetails")} -
                  {currentDetailChipLabel && (
                    <Chip
                      label={translate(currentDetailChipLabel.toLowerCase())}
                      variant="outlined"
                      size="small"
                      sx={{
                        backgroundColor: ChipColorSelector(
                          currentDetailChipLabel
                        ),
                        ml: 1,
                      }}
                    />
                  )}
                </Typography>
              </div>
              {isExpanded3 ? (
                <Typography className="formBox-helperText">
                  {translate("clickToCollapse")}
                </Typography>
              ) : (
                <Typography className="formBox-helperText">
                  {translate("clickToExpand")}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {loader ? (
              <LoaderComponent />
            ) : (
              <>
                <BatchDetails
                  getId={getId}
                  BatchData={BatchData}
                  sendFormData={sendFormData}
                  setBatchSizeLot={setBatchSizeLot}
                  addPath={addPath}
                  createFileUploadFields={createFileUploadFields}
                  setSaveData={setSaveData}
                  getFileData={setFileBody}
                  handleFileDelete={setDeleteFiles}
                  setRemovedNames={setRemovedNames}
                  showButton={showButton}
                  setShowButton={setShowButton}
                  disableVendorData={disableVendorData}
                  setGetDate={setGetDate}
                  comments={comments}
                  VendorData={VendorData}
                  getDate={getDate}
                  currentDetailChipLabel={currentDetailChipLabel}
                  batchLotDetails={batchLotDetails}
                  fileUploadData={removeDeletedFiles}
                  sourceCategoryData={sourceCategoryData}
                  independentBatch={independentBatch}
                  handleMixDelete={handleMixDelete}
                  isInspector={isInspector}
                  canSkipRawMaterial={canSkipRawMaterial}
                  hideLotDetail={hideLotDetail}
                  batchState={batchState}
                  // getDate={getDate}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {getId.id && (
          <>
          {((getId.type === "batch" && tableData?.length > 0) ||
              (getId.type === "lot" && lotTableData?.length > 0) || 
                (selfDeclaredTestsArr?.length > 0)) && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded4(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      {translate('batchTestDetails')}
                    </Typography>
                    {isExpanded4 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {
                  selfDeclaredTestsArr?.length > 0 ? 
                  <LabTestDataTable
                          selfDeclaredTestsArr={selfDeclaredTestsArr}
                          testedLabName={testedLabName}
                          testedLabTcNumber={testedLabTcNumber}
                          selfDeclaredSampleTestedDate={selfDeclaredSampleTestedDate}

                        /> :
                  lotTableData?.length > 0
                    ? lotTableData.map((data) => (
                        <LabTestDataTable
                          sampleId={sampleId}
                          tableData={data}
                          isInspector={isInspector}
                          batchOrLotCategory={batchOrLotCategory}


                        />
                      ))
                    : tableData.map((data) => (
                        <LabTestDataTable
                          sampleId={sampleId}
                          tableData={data}
                          isInspector={isInspector}
                          batchOrLotCategory={batchOrLotCategory}

                        />
                      ))}
                </AccordionDetails>
              </Accordion>
            )}

            {getId.type === "lot" && tableData?.length > 0 && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded5(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      {translate('lotTestDetails')}
                    </Typography>
                    {isExpanded5 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {tableData.map((data) => (
                    <LabTestDataTable sampleId={sampleId} tableData={data} 
                    isInspector={isInspector}
                    batchOrLotCategory={batchOrLotCategory}
                    
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
            {((getId.type === "batch" && inspectBatchTestData?.length > 0) ||
              (getId.type === "lot" && inspectLotTestData?.length > 0)) && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded11(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      Inspection {BATCH_TEST_DETAIL}
                    </Typography>
                    {isExpanded11 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {inspectLotTestData?.length > 0
                    ? inspectLotTestData.map((data) => (
                        <LabTestDataTable
                          sampleId={sampleId}
                          tableData={data}
                          isInspector={isInspector}
                          batchOrLotCategory={batchOrLotCategory}

                        />
                      ))
                    : inspectBatchTestData.map((data) => (
                        <LabTestDataTable
                          sampleId={sampleId}
                          tableData={data}
                          isInspector={isInspector}
                          batchOrLotCategory={batchOrLotCategory}

                        />
                      ))}
                </AccordionDetails>
              </Accordion>
            )}

            {getId.type === "lot" && inspectBatchTestData?.length > 0 && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded12(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      Inspection{LOT_TEST_DETAIL}
                    </Typography>
                    {isExpanded12 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {inspectBatchTestData.map((data) => (
                    <LabTestDataTable sampleId={sampleId} tableData={data} 
                    isInspector={isInspector}
                    batchOrLotCategory={batchOrLotCategory}
                    
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            )}


            {batchHistory.length > 0 && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded6(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <>
                    <Box className="formTitle">
                      <Typography className="formBox-text">
                        {translate('history')}
                      </Typography>
                      {isExpanded6 ? (
                        <Typography className="formBox-helperText">
                          {translate("clickToCollapse")}
                        </Typography>
                      ) : (
                        <Typography className="formBox-helperText">
                          {translate("clickToExpand")}
                        </Typography>
                      )}
                    </Box>
                  </>
                </AccordionSummary>
                <AccordionDetails>
                  {batchHistory.length > 0 && (
                    <div style={{ overflow: "auto" }}>
                      <BasicStepper
                        steps={batchHistory}
                        orientation={"horizontal"}
                      ></BasicStepper>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {Object.keys(batchTreeData).length > 0 && !independentBatch && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded7(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      {translate('batchHistoryTree')}
                    </Typography>
                    {isExpanded7 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.keys(batchTreeData).length > 0 && (
                    <>
                      <Horizontal_Tree
                        clickHandler={clickHandler}
                        treeData={batchTreeData}
                    isInspector={isInspector}

                      ></Horizontal_Tree>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            {rowData.length > 0 && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded8(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      {translate('usageDetails')}
                    </Typography>
                    {isExpanded8 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {rowData.length > 0 && (
                    <>
                      <Usage rowData={rowData} columnDefs={columnDefs}  batchNo={BatchData['BatchNoOfPremixManufracturer']}
                    isInspector={isInspector} title={'usage_details'}
                      
                      />
                    </>
                  )}
                  {sourceLots.length > 0 && (
                    <>
                      <Usage rowData={sourceLots} columnDefs={columnDefs} batchNo={BatchData['BatchNoOfPremixManufracturer']}
                    isInspector={isInspector} title={'usage_details'}
                      
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )}

{targetLots.length > 0 && (
          <Accordion
            onChange={(e, expanded) => {
              setIsTargetLotsExpanded(expanded);
            }}
            // defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#fff",
                    background: "#0075FF",
                    height: "35px",
                  }}
                />
              }
              sx={{ color: "0075FF" }}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Box className="formTitle">
                <Typography className="formBox-text">
                  {translate(TARGET_LOTS)}
                </Typography>
                {isTargetLotsExpanded ? (
                  <Typography className="formBox-helperText">
                    {translate("clickToCollapse")}
                  </Typography>
                ) : (
                  <Typography className="formBox-helperText">
                    {translate("clickToExpand")}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              
              {targetLots.length > 0 && (
                <>
                  <Usage rowData={targetLots} columnDefs={SOURCE_LOTS_HEADERS(translate)} batchNo={BatchData['BatchNoOfPremixManufracturer']}
                isInspector={isInspector} title={'target_lots'}
                  
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}

            {!independentBatch && getId.type === "batch" && (
              <Accordion
                onChange={(e, expanded) => {
                  setIsExpanded9(expanded);
                }}
                // defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                        background: "#0075FF",
                        height: "35px",
                      }}
                    />
                  }
                  sx={{ color: "0075FF" }}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Box className="formTitle">
                    <Typography className="formBox-text">
                      {translate('packagingInfo')}
                    </Typography>
                    {isExpanded9 ? (
                      <Typography className="formBox-helperText">
                        {translate("clickToCollapse")}
                      </Typography>
                    ) : (
                      <Typography className="formBox-helperText">
                        {translate("clickToExpand")}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <SorceCategoryInfo
                    rowInfoData={packageInfoData}
                    columnDefs={columnInfoDefs}
                    disableVendorData={disableVendorData}
                    remainingQuantity={remainingQuantity}
                    refreshPackagingInfo={refreshPackagingInfo}
                    isInspector={isInspector}
                    setIsDispatchDisable={setIsDispatchDisable}
                    disableQuantityEdit={disableQuantityEdit}
                    disablePackageInfo={disablePackageInfo}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
        {getId.id && (
          <Accordion
            onChange={(e, expanded) => {
              setIsExpanded10(expanded);
            }}
            // defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#fff", background: "#0075FF", height: "35px" }}
                />
              }
              sx={{ color: "0075FF" }}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Box className="formTitle">
                <Typography className="formBox-text">
                  {translate('wastageDetails')}
                </Typography>
                {isExpanded10 ? (
                  <Typography className="formBox-helperText">
                    {translate("clickToCollapse")}
                  </Typography>
                ) : (
                  <Typography className="formBox-helperText">
                    {translate("clickToExpand")}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Wastage
                remainingQuantity={remainingQuantity}
                rowWastageData={rowWastageData}
                columnDefs={columnWastageDefs}
                unitsOfMeasurement={unitsOfMeasurement}
                refreshWastageInfo={refreshWastageInfo}
                showButton={showAddWastage}
                isInspector={isInspector}
                inspectModalData={inspectModalData}
                disableVendorData={disableVendorData}
                isSuperAdmin={isSuperAdmin}
                isMonitor={isMonitor}

              />
            </AccordionDetails>
          </Accordion>
        )}

        <Box className="action-buttons-container">
          {
            <ActionsModal
              batchId={getId.id}
              actions={actionButtons}
              categoryId={categoryId}
              batchData={entireDocData}
              updateActionState={updateActionState}
              showButton={showButton}
              showButtonAction={showButtonAction}
              requestBody={BatchData}
              lastActionDate={lastActionDate}
              canInspect={canInspect}
              inspectModalData={inspectModalData}
              remainingQuantity={remainingQuantity}
              inspectButton={inspectButton}
              getTestResultStatus={getTestResultStatus}
              showLabDropDown={showLabDropDown}
              disableVendorData={disableVendorData}
              saveButtonCondition={saveButtonCondition}
            />
          }
        </Box>
        <Box sx={{ height: "5px" }}></Box>
        {isBatchReadyForDispatch && !isInspector && showPackackagingMsg() && (
            <Box
              display="flex"
              sx={{
                justifyContent: "center",
                marginBottom: "15px",
                fontSize: 13,
              }}
            >
              *{translate('packagingInfoReqMsg')}
            </Box>
          )}
        {isBatchReadyForDispatch && !isInspector && !isSuperAdmin && !isMonitor && !showPackackagingMsg() && (
          <Box
            display="flex"
            sx={{ justifyContent: "center", marginBottom: "15px" }}
          >
            <Button variant="contained" onClick={() => setModalState(true)} disabled={isDispatchDisable}>
              {translate('dispatch')}
            </Button>
          </Box>
        )}
        {
          <Modal open={modalState} onClose={() => setModalState(false)}>
            <DropDownFRk
              manufacturerCount={manufacturerCount}
              pageNumber={pageNumber}
              fetchFrkManufacturers={fetchFrkManufacturers}
              manuFacturerNames={frkManufacturerDetails}
              unitsOfMeasurement={unitsOfMeasurement}
              batchRemainingQuantity={batchRemainingQuantity}
              submitLotData={submitLotData}
              categoryId={categoryId}
              batchNo={entireDocData?.batchNo}
              batchSizeUnits={packageInfoData}
              setFRKType={setFRKType}
              frkType={frkType}
              setQueryparam={setQueryparam}
              lastActionDate={lastActionDate}
              purchaseOrderList={purchaseOrderList}
              setTargetManfId={setTargetManfId}
              getId={getId}
              batchOrLotCategory={batchOrLotCategory}
            />
          </Modal>
        }
      </Box>
    </div>
  );
}

export default PremixSections;
