import moment from "moment";
import { BATCH_NUMBER, EXP_DATE, HEADER_NAME, MANF_DATE, REMAINING_QUANTITY, STATUS, TOTAL_QUANTITY } from "../../textConstant";
import Columnchip from "../../components/Table/Columnchip";
import UnitConverterWeight from "../../utils/UnitConverter";

export const LISTING_BATCHES_HEADER = (translate) => [
  {
    field: "batchNo",
    headerName: translate('batchNumber'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
  },

  {
    field: "dateOfManufacture",
    headerName: translate('manufacturingDate'),
    minWidth: 150,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        // return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
        let initialDate = moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
        const parts = initialDate.split("-");
        const translatedMonth = translate(parts[1]?.toLowerCase());
        return `${parts[0]}-${translatedMonth}-${parts[2]}`;
      } else{
          return ""
      }
    },
  },
  {
    field: "dateOfExpiry",
    headerName: translate('expiryDate'),
    minWidth: 150,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },

    valueFormatter: (params) => {
      if(params.data.dateOfExpiry){
        // return moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
        let initialDate = moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
        const parts = initialDate.split("-");
        const translatedMonth = translate(parts[1]?.toLowerCase());
        return `${parts[0]}-${translatedMonth}-${parts[2]}`;
      } else {
          return ""
      }
    },
  },
  {
    field: "totalQuantity",
    headerName: translate('totalQuantity'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity, translate);
  },
  },
  {
    field: "remainingQuantity",
    headerName: translate('remainingQuantity'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.remainingQuantity, translate);
  },
  },
  {
    field: "state",
    headerName: translate('stage'),
    minWidth: 170,
    hide: false,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    filterParams: {
      buttons: ["apply", "clear"],
    },
    cellRendererFramework: (params) => (
      <Columnchip data={params.data} type="batches" />
    ),
  },
];
