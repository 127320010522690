import { DISPATCH_DATE, EXP_DATE, HEADER_NAME, MANF_DATE, QUANTITY_USED, STATUS } from "../../../textConstant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Columnchip from "../../../components/Table/Columnchip";
import Tooltip from '@mui/material/Tooltip';
import { Box } from "@mui/system";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import LinkRedirect from "./LinkRedirect";
import UnitConverterWeight from "../../../utils/UnitConverter";

export const LISTING_USAGE_HEADER = (translate) => [
  {
    field: "lotNo",
    headerName: translate('name'),
    // maxWidth: 180,
    flex:1,
    hide: false,
    cellRendererFramework: (params) => <LinkRedirect params={params.data} target ="lot"/> ,

  },
  {
    field: "manufacturerLotNumber",
    headerName: translate('manufacturerLotNo'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      return (params.data.manufacturerLotNumber ? params.data.manufacturerLotNumber : '')
    },
  },
  {
    field: "dateOfManufacture",
    headerName: translate('manufacturingDate'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        return moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY");
      } else{
          return ""
      }
    },
  },
  {
    field: "dateOfDispatch",
    headerName: translate('dispatchDate'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      return moment.utc(params.data.dateOfDispatch).format("DD-MMM-YYYY");
    },
  },
  {
    field: "dateOfExpiry",
    headerName: translate('expiryDate'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfExpiry){
        return moment.utc(params.data.dateOfExpiry).format("DD-MMM-YYYY");
      } else {
          return ""
      }
    },

  },

  {
    field: "manufacturerDetails",
    headerName: translate('manufacturer'),
    // maxWidth: 180,
    flex:1,
    hide: false,
    cellRendererFramework: (params) => <Tooltip title=
      {<Typography sx={{ fontSize: "0.8rem" }}>{params.data.manufacturerDetails}</Typography>}
      placement="top-start">
      <Box>
        {params.data.manufacturerDetails}
      </Box>
    </Tooltip>,
  },
  {
    field: "licenseNumber",
    headerName: translate('licenseNo'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    tooltipField: "licenseNumber",
    valueFormatter: (params) => {
      return params.data.licenseNumber;
    },
  },

  {
    field: "totalQuantity",
    headerName: translate(QUANTITY_USED),
    // maxWidth: 170,
    flex:1,
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity);
  },
  },
  {
    field: "state",
    headerName: translate('stage'),
    // maxWidth: 170,
    flex:1,
    hide: false,
    cellRendererFramework: (params) => <Columnchip data={params.data} />,
  },
];
