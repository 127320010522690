import React from "react";
import { 
  CircularProgress,
  LinearProgress,
  Stack
} from "@mui/material";
import useTranslatewrapper from "../useTranslateWrapper";

const LoaderComponent = (props) => {

    const { loaderType } = props;
  const {translate} = useTranslatewrapper()

    switch(loaderType) {
        case 'linear':
          return <LinearProgress />
        case 'circular':
          return (
            <Stack alignItems="center">
                <CircularProgress />
            </Stack>
          )
        case 'spinner':
          return (
            <>
            <Stack alignItems="center" className="spinner">
                <CircularProgress />
                <div>{translate('pleaseWait')}</div>
            </Stack>
            </>
          )
        default:
          return <></>
    }
};

export default LoaderComponent;