import React, { Fragment, useEffect } from "react";
import { ApplicationRoutes } from "./routes";
import { Route, Routes } from "react-router-dom";
import BatchAndVendorInfo from "./pages/PremixDetails/BatchAndVendorInfo";
import Faq from "./Faq";
import { useTranslation } from "react-i18next";


export default function AppUnprotected() {
const { t, i18n } = useTranslation();
  useEffect(()=>{    
    i18n.changeLanguage('en');
  }, [])
let relevantQrRoutes = [
    {
        key :"premix-uuid-edit for",
        title:"Premix Read Form",
        path:"/premix/:categoryId/:type/code/:uuid",
        enabled:true,
        component:BatchAndVendorInfo
      },
      {
        key :"frk-uuid-edit for",
        title:"Frk Read Form",
        path:"/frk/:categoryId/:type/code/:uuid",
        enabled:true,
        component:BatchAndVendorInfo
      },
      {
        key :"miller-uuid-edit for",
        title:"miller Read Form",
        path:"/miller/:categoryId/:type/code/:uuid",
        enabled:true,
        component:BatchAndVendorInfo
      },
      {
        key :"faq-page",
        title:"FAQ Page",
        path:"/faq",
        enabled:true,
        component:Faq
      },
]


  return (
    <>
      <Fragment>
        <div style={{width: "100%" }}>
          <div
            style={{width: "100%" }}
          >
            <Routes>
              {relevantQrRoutes.map(
                (route) =>
                    <Route
                      key={route.key}
                      path={route.path}
                      element={
                        <route.component  />
                      }
                    />
                  )
              }
            </Routes>
          </div>
        </div>
      </Fragment>
    </>
  );
}
