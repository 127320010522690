import React from 'react'

function UnitConverterWeight(value, translate = (val) => val) {
if(!value){
    return('N/A')
}
    if(value/1000000000.00 >= 1){
    return (
        ((value/1000000000.00) %1 !== 0 ? (value/1000000000.00).toFixed(3) :  (value/1000000000.00))+` ${translate('MT')}`
        )
}
if(value/1000000000.00 < 1 && value/1000.00 >= 1){
    return (
        ((value/1000.00) %1 !==0 ? (value/1000.00).toFixed(3) : (value/1000.00))+` ${translate('Ton')}`
        )
}
if(value/1000.00 < 1 && value/100.00 >= 1){
    return (
        ((value/100.00) %1 !==0 ? (value/100.00).toFixed(3) : (value/100.00))+` ${translate('Quintal')}`
        )
}
if(value/100.00 < 1 ){
    return (
        value+` ${translate('Kg')}`
        )
}
  
}

export default UnitConverterWeight
