import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useMemo } from "react";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../services/StoreHooks";

export const SelfDeclaredMaterials = (props) => {
  const { rawMaterialArr } = props;
  const { translate } = useTranslatewrapper();
  const getId = useParams();

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );
  const categoryIdToNameMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.id.toString()] = current.name;
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  const currentCategoryName = categoryIdToNameMapRedux[getId.categoryId].toLowerCase();

  return (
    <Box className="form-div sourceCategory">
      <TableContainer sx={{ border: 1, borderColor: " #E0E0E0" }}>
        <Table
          sx={{ minWidth: 900 }}
          className="form-table"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
              >
                {translate("S.No")}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                align="left"
              >
                {translate('rawMaterialName')}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                align="left"
              >
                {translate("quantity")}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                align="left"
              >
                {translate("units")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rawMaterialArr &&
              rawMaterialArr.map((data, idx) => (
                <TableRow
                  // key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 1,
                      borderColor: " #E0E0E0",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      border: 1,
                      borderColor: " #E0E0E0",
                      height: "20px",
                    }}
                    component="th"
                    scope="row"
                  >
                    {idx + 1}

                    <Chip
                      sx={{ ml: 2 }}
                      label={currentCategoryName == 'premix' ? translate('rawMaterial') : translate(data?.name?.toUpperCase())}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      border: 1,
                      borderColor: " #E0E0E0",
                      height: "20px",
                    }}
                  >
                    {translate(data?.name)}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: 1,
                      borderColor: " #E0E0E0",
                      height: "20px",
                    }}
                    align="left"
                  >
                    <TextField
                      disabled={true}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="quantity"
                      id="outlined-basic"
                      value={data.quantity}
                      type="string"
                      variant="standard"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      border: 1,
                      borderColor: " #E0E0E0",
                      height: "20px",
                    }}
                    align="left"
                  >
                    <TextField
                      disabled={true}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="unit"
                      id="outlined-basic"
                      value={data.units}
                      type="string"
                      variant="standard"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
