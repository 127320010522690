import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation();

  const handleChange = (event) => {

    localStorage.setItem('preferredLang', event.target.value)

    window.location.reload()
  };

  return (
    <div>
    <FormControl variant="outlined" style={{ width: 'auto', height: '50%' }}>
      {/* <InputLabel id="language-select-label"></InputLabel> */}
      <Select
        labelId="language-select-label"
        // value={i18n.language}
        value={props.language}
        onChange={handleChange}
        label=""
        size="small"
      >
        <MenuItem value="si">සිංහල</MenuItem>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ta">தமிழ்</MenuItem>
      </Select>
    </FormControl>
    </div>
  );
};

export default LanguageSwitcher;