import moment from "moment";
import React from "react";
import Columnchip from "../../../components/Table/Columnchip";
import { DISPATCH_DATE, HEADER_NAME, QUANTITY_USED, REMAINING_QUANTITY, STATUS, TOTAL_QUANTITY } from "../../../textConstant";
import UnitConverterWeight from "../../../utils/UnitConverter";
import LinkRedirect from "./LinkRedirect";
import { translateMonthInFormattedDate } from "../../../commonFunctions";

export const SOURCE_LOTS_HEADERS = (translate)=> [
  {
    field: "lotNo",
    headerName: translate("name"),
    hide: false,
    cellRendererFramework: (params) => <LinkRedirect params={params.data} target ="lot"/> ,

  },
  {
    field: "dateOfManufacture",
    headerName: translate('dispatchDate'),
    hide: false,
    valueFormatter: (params) => {
      if(params.data.dateOfManufacture){
        return translateMonthInFormattedDate(translate, moment.utc(params.data.dateOfManufacture).format("DD-MMM-YYYY"));
      } else{
          return ""
      }
    }
  },

  {
    field: "totalQuantity",
    headerName: translate('totalQuantity'),
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.totalQuantity, translate);
  },
  },
  {
    field: "remainingQuantity",
    headerName: translate("remainingQuantity"),
    hide: false,
    valueFormatter: (params) => {
      return UnitConverterWeight(params.data.remainingQuantity, translate);
  },
  },
  {
    field: "state",
    headerName: translate('stage'),
    hide: false,
    cellRendererFramework: (params) => <Columnchip data={params.data} />,
  },
];
