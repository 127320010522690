import { Box, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Navbar, Container } from "react-bootstrap";

const Faq = () => {
  const faqs = [
    {
      question: "What is Food Fortification?",
      answer: `“Fortification” means deliberately increasing the content of essential micronutrients in a food to improve the nutritional quality of food and to provide public health benefit with minimal risk to health; Fortification is a scientifically proven, cost-effective, scalable and sustainable global intervention that addresses the issue of micronutrient deficiencies.`,
    },
    {
      question: "What are the different classifications of Fortification?",
      answer: {
        text: "World Health Organization (WHO) classifies food fortification into: ",
        points: [
          "Large Scale Food Fortification that is aimed at correcting widespread micronutrient deficiencies in the general population by fortifying staple foods, ",
          "Targeted fortification that focuses on specific groups (children, pregnant women) to address their unique nutritional needs and Point-of-use fortification where micronutrients are added just before consumption (e.g., at home or schools), ",
          "Market-driven fortification focuses on processed foods and is more widespread in industrialized countries, ",
          "Bio fortification is the process that aims to increase the concentration of nutrients in edible portions of crop plants through conventional plant breeding; and",
          "Agronomic bio fortification involves applying specific micronutrient fertilizers to soil during crop growth.",
        ],
      },
    },
    {
      question:
        "Does fortifying staples undermine larger goals of diverse, healthy diets composed of whole grains, fruits, and vegetables?",
      answer:
        " One of the most effective, scalable, affordable, and sustainable ways to address micronutrient deficiencies is fortification of staple foods. It complements diet diversification to help complete a person's daily nutritional needs. It fills the gap in nutrition in an easy manner without any change in taste, texture, or flavour of food, minimizing the requirement of behavioural change. While dietary diversification represents an ideal method to combat micronutrient deficiencies, it takes long to create impact and necessitates extensive consumer education. Additionally, it may not be economically viable for all individuals. In contrast, food fortification serves as a complementary strategy to dietary diversification and supplementation, effectively bridging the gap in nutritional requirements in a cost-effective and scalable manner.",
    },
    {
      question: "Does the process of fortifying foods make them unhealthy? ",
      answer:
        "Contrary to misconceptions, fortifying foods does not render them unhealthy or unnatural; rather, it enhances their nutritional value, leading to improved health outcomes. Food fortification entails the addition of essential vitamins and minerals to improve the nutritional profile of the food, addressing deficiencies and strengthening public health efforts. This practice has been embraced for decades across more than 100 countries. Fortification with Iron, Folic acid and Vitamin B9 has been linked to a reduction in anaemia, maintenance of a healthy nervous system and support blood formation. ",
    },
    {
      question: " What kind of foods can be fortified? ",
      answer:
        "Various staple foods are fortified on a global scale, encompassing wheat flour, maize flour, rice, edible oils, salt, milk and condiments. The particular fortification practices adopted may vary from country to country, based on factors such as dietary habits, prevalent nutrient deficiencies, and public health objectives.  ",
    },
    {
      question: "Does the process of fortifying foods make them unhealthy? ",
      answer:
        "Contrary to misconceptions, fortifying foods does not render them unhealthy or unnatural; rather, it enhances their nutritional value, leading to improved health outcomes. Food fortification entails the addition of essential vitamins and minerals to improve the nutritional profile of the food, addressing deficiencies and strengthening public health efforts. This practice has been embraced for decades across more than 100 countries. Fortification with Iron, Folic acid and Vitamin B9 has been linked to a reduction in anaemia, maintenance of a healthy nervous system and support blood formation. ",
    },
  ];

  return (
    <>
      <Toolbar className="space-between-container">
        <Box>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6" noWrap component="div">
              <img
                src="/assets/images/Sri-Lanka-Government.jpg"
                alt="logoimage"
                height={120}
                width={"auto"}
                style={{ objectFit: "contain" }}
              />
            </Typography>
          </Stack>
        </Box>
        <Box flex={1} display={"flex"} justifyContent={"center"}>
          <Typography
            noWrap
            component="div"
            mx={3}
            color="#228B22"
            fontWeight={"bold"}
            fontSize={28}
          >
            FORTIFIED RICE QUALITY MANAGEMENT SYSTEM
            {/* FRQMS */}
          </Typography>
        </Box>
      </Toolbar>
      <div className="container mt-4">
        <h2 className="mb-4 text-center text-primary">
          Frequently Asked Questions
        </h2>
        <div>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="mb-4 p-4 border rounded shadow-sm bg-light"
            >
              <h5 className="text-dark fs-3 fw-bold">{faq.question}</h5>
              {typeof faq.answer === "object" ? (
                <div className="fs-4">
                  <p className="text-muted">{faq.answer.text}</p>
                  <ul className="text-muted">
                    {faq.answer.points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="text-muted fs-4">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
