import React, { useEffect, useMemo, useState } from "react";
// import BatchDetailsSchema from "./BatchDetailsSchema.json";
import useBatchDetailsSchemaFn from "../BatchDetails/BatchDetailsSchema.js"
import BatchDetailsUiSchema from "./BatchDetailsUiSchema.json";
// import BatchDetailsRiceSchema from "./BatchDetailsRiceSchema.json";
import moment from "moment";
import "./BatchDetails.css";
// import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import BatchSizeComponent from "../../../pages/Form/BatchComponent/BatchSizeComponent";
import SourceCategory from "../SourceCategory/SorceCategory";
import GetAllFiles from "../GetAllFiles/GetAllFiles";
import { Box, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  SAVE_BUTTON_TEXT,
  CREATE_NEW_BUTTON_TEXT,
} from "../../../textConstant";
import { Alert } from "@mui/material";

import NotificationSelector from "../../../../src/NotificationSelector.js";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../src/services/StoreHooks";
import ErrorSwitchCase from "../../../services/FormErrorHandler";
import DatePickerComponent from "../DatePicker/DatePickerComponent";
import { ThemeProps, withTheme } from "@rjsf/core";
import { Form } from "@rjsf/mui";
import { useParams, useLocation } from "react-router-dom";
import { set } from "lodash/fp";
import useTranslatewrapper from "../../../components/useTranslateWrapper.js";
import useBatchDetailsRiceSchemaFn from "./BatchDetailsRiceSchema.js";
import { lengthAndValidCharactersInStringCheck } from "../../../commonFunctions.js";

function BatchDetails({
  BatchData,
  sendFormData,
  setBatchSizeLot,
  addPath,
  createFileUploadFields,
  getId,
  getFileData,
  handleFileDelete,
  setSaveData,
  setShowButton,
  showButton,
  setRemovedNames,
  disableVendorData,
  setGetDate,
  getDate,
  batchLotDetails,
  fileUploadData,
  sourceCategoryData,
  VendorData,
  independentBatch,
  batchSizeLot,
  handleMixDelete,
  isInspector,
  canSkipRawMaterial,
  hideLotDetail,
  batchState,
  comments
}) {

  const {translate, language} = useTranslatewrapper()

  const [newBatchDetailsSchema, setNewBatchDetailsSchema] = useState(useBatchDetailsSchemaFn())
  const [newBatchDetailsRiceSchema, setNewBatchDetailsRiceSchema] = useState(useBatchDetailsRiceSchemaFn())


  // let tempBatchDetailsSchema = useBatchDetailsSchemaFn()
  // let tempBatchDetailsRiceSchema = useBatchDetailsRiceSchemaFn()

  const [errMsg, setErrMsg] = useState("");
  const [batchDetailsUiSchema, setbatchDetailsUiSchema] =
    useState(BatchDetailsUiSchema);
  const [batchMfgDate, setBatchMfgDate] = useState("");
  const [batchExpDate, setBatchExpDate] = useState("");
  const [mixesValidated, setMixesValidated] = useState(false);
  const [fileUploadValidated, setFileUploadValidated] = useState(false);
  const [createOrSaveDisabled, setCreateOrSaveDisable] = useState(false);
  const [batchStateValue, setBatchStateValue] = useState(batchState)
  const event = new Date();
  const location = useLocation();
  const getIdx = useParams();
  const categoryLocation = isInspector ? location.pathname.split("/")[2]:location.pathname.split("/")[1];
  const categoryType = isInspector ? location.pathname.split("/")[4]:location.pathname.split("/")[3];
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState({
    ManufacturingDate: moment().local().format("yyyy-MM-DD"),
    PremixPercentageNeededForFRK: 
    ''
    // (categoryLocation === 'premix' && categoryType === 'batch' && (!getIdx.id || batchStateValue==="created") ) ?
    //  2 
    //  : ''

  });
  const [mixErrorMsg, setMixErrorMsg] = useState([]);

  const dispatch = useAppDispatch();

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // useEffect(() => {
  //   mixesValidation()

  // },[batchLotDetails])
  // useEffect(()=>{
  //   fileUploadValidation()

  // },[fileUploadData])

  const categories = useAppSelector(state => state.categoriesData)
  let iscategoryRawMaterial = location.pathname.split("/")[1] === 'raw-material';

  useEffect(() => {

    let BatchDetailsSchema = newBatchDetailsSchema
    let BatchDetailsRiceSchema = newBatchDetailsRiceSchema

    // console.log('location changed')
    const categoryLocation = location.pathname.split("/")[1];
    iscategoryRawMaterial = categoryLocation === 'raw-material';
    const category = categories?.categories?.find(cat=> cat.id == getId.categoryId);
    //setting default configurations for batchDetails on switching b/w multiple categories
    BatchDetailsSchema.properties.PremixPercentageNeededForFRK = {
      "type": "number",
      "title": translate('percentInMix'),
      "maximum": 100,
      "minimum": 0

    }

    // if(independentBatch){
    //   BatchDetailsSchema.properties.BatchNoOfPremixManufracturer = {
    //     "type": "string",
    //     "title": translate('autoGeneratedLotNo'),
    //     "readOnly": true
    //   }
    // } else{
    //   BatchDetailsSchema.properties.BatchNoOfPremixManufracturer = {
    //     "type": "string",
    //     "title": translate('autoGeneratedBatchNo'),
    //     "readOnly": true
    //   }

    // }




    BatchDetailsSchema.properties.BatchName = {
      "type": "string",
      "title": translate('name')
    }

    BatchDetailsSchema.properties.UserDefinedBatchNo = {
      "type": "string",
      "title": translate('manufacturerBatchNo')
    }
    batchDetailsUiSchema["BatchName"] = {};
    batchDetailsUiSchema["RawMaterialType"] = {
      "ui:widget": "hidden",
    };

    batchDetailsUiSchema['RawMaterialTypeOther']['ui:widget'] = 'hidden'
    batchDetailsUiSchema["RawMaterialName"] = {
      "ui:widget": "hidden",
    };
    BatchDetailsUiSchema["PremixPercentageNeededForFRK"]["ui:help"] =  "";

    if ((categoryLocation === "miller" || category?.name?.toLowerCase() === "miller") && (categoryType === "batch" || categoryType === "lot") || (hideLotDetail)) {
      BatchDetailsSchema["required"] = BatchDetailsSchema["required"].filter(
        (data) => data !== "PremixPercentageNeededForFRK"
      );
      batchDetailsUiSchema["PremixPercentageNeededForFRK"] = {
        "ui:widget": "hidden",
      };
    } else {
      batchDetailsUiSchema["PremixPercentageNeededForFRK"] = {};
      if(categoryLocation === 'premix' && categoryType === 'batch' && (!getIdx.id || batchStateValue==="created") ){
        
        BatchDetailsSchema.properties.PremixPercentageNeededForFRK = 
        {
          "type": "number",
          "title": translate('percentInFrk'),
          // "readOnly": true,
          // "default": 2
          // "enum": [
          //   1,
          //   1.5,
          //   2,
          //   2.5,
          //   3,
          //   3.5,
          //   4,
          //   4.5, 
          //   5
          // ]
        };
      } else if(categoryLocation === 'premix'){
        BatchDetailsSchema.properties.PremixPercentageNeededForFRK = {
          ...BatchDetailsSchema.properties.PremixPercentageNeededForFRK,
          "title": translate('percentInFrk')
        }
      } else if(categoryLocation === 'raw-material'){
        BatchDetailsSchema.properties.PremixPercentageNeededForFRK = {
          ...BatchDetailsSchema.properties.PremixPercentageNeededForFRK,
          "title": translate('percentInPremix')
        }
        // if(!getIdx.id){
        //   BatchDetailsSchema.properties.BatchName = {
        //     // ...BatchDetailsSchema.properties.BatchName,
        //     "title": "Raw Material Name",
        //     "type": 'string',
        //     "enum": [
        //       "Premix Base",
        //       "Iron",
        //       "Vitamin B12",
        //       "Vitamin B9-Folic acid"
        //     ]
        //   }
        // } else{
        //   BatchDetailsSchema.properties.BatchName = {
        //     "type": "string",
        //     "title": "Name"
        //   }
        // }
        if(getIdx.id){
          BatchDetailsSchema.properties.RawMaterialType = {
            title: translate('rawMaterialType'),
            type: 'string'
          }
        }else{
          BatchDetailsSchema.properties.RawMaterialType = {
            title: translate('rawMaterialType'),
            enum: ['']
          }
        }
        batchDetailsUiSchema["RawMaterialName"] = {};
        batchDetailsUiSchema["BatchName"] = {"ui:widget": "hidden" };
        batchDetailsUiSchema["RawMaterialType"] = {};
      }else if(categoryLocation === 'rice-mix'){
        BatchDetailsSchema.properties.PremixPercentageNeededForFRK = {
          ...BatchDetailsSchema.properties.PremixPercentageNeededForFRK,
          "title": translate('percentInFrk')
        }
      }else if(categoryLocation === 'frk'){
        // BatchDetailsUiSchema["PremixPercentageNeededForFRK"]["ui:help"] =  "Recommended 0.9 - 1.2%";
      
      }else if(categoryLocation === 'rice'){
        // BatchDetailsUiSchema["PremixPercentageNeededForFRK"]["ui:help"] =  "Recommended 98.9 - 99.1%";
      }
      else {
        BatchDetailsSchema.properties.PremixPercentageNeededForFRK = {
        "type": "number",
        "title": translate('percentInMix'),
        "maximum": 100,
        "minimum": 0,
        };
      }
    }

    if(categoryType === 'lot'){
      BatchDetailsSchema.properties.UserDefinedBatchNo = {
        "type": "string",
        "title": translate('manufacturerLotNo')
      }
    }


    if (categoryLocation === "rice") {
      // BatchDetailsSchema["required"].push(
      //   ...["CropYear", "RiceType", "Grade", "Variety"].filter(
      //     (data) => !BatchDetailsSchema["required"]?.includes(data)
      //   )
      // );
      // BatchDetailsSchema["required"].reduce((data)=>data!=) ;
      batchDetailsUiSchema["CropYear"] = {};
      batchDetailsUiSchema["RiceType"] = {};
      // batchDetailsUiSchema["Grade"] = {};
      batchDetailsUiSchema["Variety"] = {};
    }else if(categoryLocation === "rice-mix"){
      batchDetailsUiSchema['RiceType']={}
      batchDetailsUiSchema["CropYear"] = {
        "ui:widget": "hidden",
      };
      batchDetailsUiSchema["Grade"] = {
        "ui:widget": "hidden",
      };
      batchDetailsUiSchema["Variety"] = {
        "ui:widget": "hidden",
      };
    } else {
      // BatchDetailsSchema["required"] =
      // BatchDetailsSchema["required"].reduce(
      //   (data) => !["CropYear", "RiceType", "Grade", "Variety"].includes(data)
      // );
      batchDetailsUiSchema["CropYear"] = {
        "ui:widget": "hidden",
      };

      batchDetailsUiSchema["RiceType"] = {
        "ui:widget": "hidden",
      };
      batchDetailsUiSchema["Grade"] = {
        "ui:widget": "hidden",
      };
      batchDetailsUiSchema["Variety"] = {
        "ui:widget": "hidden",
      };
    }
    if(hideLotDetail){
      batchDetailsUiSchema["ExpiryDate"] = {"ui:widget": "hidden",};
      batchDetailsUiSchema["BatchNoOfPremixManufracturer"] = {"ui:widget": "hidden",};
      batchDetailsUiSchema["DispatchDate"] = {
        "ui:classNames": "date custom-date-field",
        "ui:widget": "memoizedDateWidget",
      };
      batchDetailsUiSchema.ManufacturingDate = {
        "ui:widget": "hidden",
      };
    }
    else{
      batchDetailsUiSchema["DispatchDate"] = {
        "ui:widget": "hidden",
      };
      batchDetailsUiSchema["BatchNoOfPremixManufracturer"] = {};
      batchDetailsUiSchema.ManufacturingDate = {
        "ui:classNames": "date custom-date-field",
        "ui:widget": "memoizedDateWidget"
    
      };
      batchDetailsUiSchema["ExpiryDate"] = {
        "ui:classNames": "date custom-date-field",
        "ui:widget": "memoizedDateWidget",
        "ui:showError": false 
    
      };
    }

    setNewBatchDetailsSchema(BatchDetailsSchema)
    setNewBatchDetailsRiceSchema(BatchDetailsRiceSchema)

  }, [location]);

  useEffect(() => {
    setFormData(BatchData);
  }, [BatchData]);
  useEffect(() => {
    if (independentBatch || getIdx.type === "batch") {
      batchDetailsUiSchema["BatchSize"] = { "ui:widget": "hidden" };
      if (!getIdx.id) {
        var newUiSchema = set(
          ["RemainingQuantity", "ui:widget"],
          "hidden",
          batchDetailsUiSchema
        );
        newUiSchema = set(
          ["LotNo", "ui:widget"],
          "hidden",
          newUiSchema
        );

      } else {
        var newUiSchema = set(
          ["RemainingQuantity", "ui:widget"],
          {},
          batchDetailsUiSchema
        );
        if(getIdx.type === 'batch'){
          newUiSchema = set(
            ["LotNo", "ui:widget"],
            "hidden",
            newUiSchema
          );
        } else{
          newUiSchema = set(
            ["LotNo", "ui:widget"],
            {},
            newUiSchema
          );
        }

      }
      setbatchDetailsUiSchema(newUiSchema);
    } else {
      BatchDetailsUiSchema["BatchSize"] = { "ui:widget": "BatchSizeWidget" };
      BatchDetailsUiSchema["RemainingQuantity"] = {};
      BatchDetailsUiSchema["LotNo"] = {...BatchDetailsUiSchema["LotNo"], "ui:widget": {} };
    }
  }, [getIdx, independentBatch]);

  const errorArrayEditMix = () => {
    function addError(msg) {
      setMixErrorMsg([...mixErrorMsg, msg]);
    }
  };

  useEffect(() => {
    handleMixDelete && validateFunction(formData, errorArrayEditMix());

    handleMixDelete && sendFormData(formData);
  }, [handleMixDelete]);

  let arrId = [];
  let newArr;

  const fileUploadValidation = () => {
    sourceCategoryData?.documents?.map((data) => {
      if (data.isMandatory) {
        if (
          fileUploadData().findIndex(
            (elem) => data.id === elem.categoryDocId && elem.path !== ""
          ) !== -1
        ) {
          arrId.push({
            name: data.docType.name,
            value: true,
          });
        } else {
          arrId.push({
            name: data.docType.name,
            value: false,
          });
        }
      }
    });

    newArr = arrId.filter((ele) => ele.value == false);

    if (arrId.length === 0 || newArr.length == 0) {
      setFileUploadValidated(true);
      return true;
    } else {
      setFileUploadValidated(false);
      return false;
    }
  };

  let newArrMixes;
  const mixesValidation = () => {
    let arrMixes = [];

    sourceCategoryData?.sourceCategories?.map((data) => {
      if (data.name) {
        if (
          batchLotDetails.length === 0 ||
          batchLotDetails.findIndex((elem) => data.name === elem.chipName) ===
            -1
        ) {
          arrMixes.push({
            name: data.name,
            value: false,
          });
        } else {
          if (
            batchLotDetails.findIndex(
              (elem) =>
                data.name === elem.chipName &&
                (elem.size === "" ||
                  elem.quantity === "" ||
                  elem.uomId === "" ||
                  elem.unit === "")
            ) !== -1
          ) {
            arrMixes.push({
              name: data.name,
              value: false,
            });
          } else {
            if (
              batchLotDetails.findIndex(
                (elem) =>
                  data.name === elem.chipName && Number(elem.quantity) <= 0
              ) !== -1
            ) {
              arrMixes.push({
                name: `${translate(data.name)}, ${translate('quantityMustBeGreaterThanZero')}`,
                value: false,
                isFullErrMsg: true 
              });
            }
            if (
              batchLotDetails.findIndex(
                (elem) =>
                  data.name === elem.chipName &&
                  Number(elem.quantity) > elem.remaining
              ) !== -1
            ) {
              arrMixes.push({
                name: `${translate(data.name)}: ${translate('quantityCannotBeGreaterThanRemQty')}`,
                value: false,
                isFullErrMsg: true 
              });
            }
            arrMixes.push({
              name: data.name,
              value: true,
            });
          }
        }
      }
    });

    newArrMixes = arrMixes.filter((ele) => ele.value == false);
    if (newArrMixes.length == 0) {
      setMixesValidated(true);
      return true;
    } else {
      setMixesValidated(false);
      return false;
    }
  };

  let newArrVendor;

  const vendorDetailsValidation = () => {
    let arrVendor = [];
    VendorData.name && VendorData.name !== ""
      ? arrVendor.push({ name: "nameOfVendor", value: true })
      : arrVendor.push({ name: "nameOfVendor", value: false });
    VendorData.vendorType && VendorData.vendorType !== ""
      ? // && VendorData.type !== "State License"
        arrVendor.push({ name: "vendorType", value: true })
      : arrVendor.push({ name: "vendorType", value: false });

    VendorData.completeAddress && VendorData.completeAddress !== ""
      ? arrVendor.push({ name: "address", value: true })
      : arrVendor.push({ name: "address", value: false });

    newArrVendor = arrVendor.filter((ele) => ele.value == false);
    if (newArrVendor.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const setBatchDate = (data) => {
    if (data.title === translate('manufacturingDate')) {
      setBatchMfgDate(data.date);
    }
    if (data.title === translate('expiryDate')) {
      setBatchExpDate(data?.date);
    }
  };

  const validationAfterSubmitting = () => {
    let arrErr = []
    let errorsList = []

    if(formData?.UserDefinedBatchNo != null){

      if(!/^[a-zA-Z0-9 _-]*$/.test(formData?.UserDefinedBatchNo)){
        arrErr.push(translate('commasNotAllowedInManufacturerBatchOrLotNo', {batchOrLot: translate(getIdx.type)}));
        errorsList.push(translate('commasNotAllowedInManufacturerBatchOrLotNo', {batchOrLot: translate(getIdx.type)}))
      }
    }

    if(sourceCategoryData?.name?.toLowerCase() == 'premix'){
      let listOfRawMaterialsRequired = newBatchDetailsSchema.properties.RawMaterialName.enum


      let setOfRawMaterialsInSelectedMixes = new Set()
      batchLotDetails.forEach((ele)=>{
        setOfRawMaterialsInSelectedMixes.add(ele?.batchName)
      })

      const elementsNotInSet = listOfRawMaterialsRequired.filter(item=> !setOfRawMaterialsInSelectedMixes.has(item))

      if(elementsNotInSet.length > 0){
        // elementsNotInSet.map((ele) => arrErr.push(`Raw material: ${ele} is not selected`));
        elementsNotInSet.map((ele) => arrErr.push(`${translate('rawMaterial')}: ${translate(ele.toLowerCase())} ${translate('isNotSelected')}`));
        errorsList.push(translate('necessaryRawMaterialsNotSelected'))
      }
    }

    if(iscategoryRawMaterial){
      if(!formData?.RawMaterialName){
        // arrErr.push("Raw material name is mandatory");
        arrErr.push(translate('rawMaterialNameMandatory'));
        errorsList.push(translate('rawMaterialNameMandatory'))
      } else {
        let validationResult = lengthAndValidCharactersInStringCheck(formData?.RawMaterialName, translate)
        if(!validationResult['status']){
          arrErr.push(translate('rawMaterialName') + ": " +validationResult['message'])
        }
      }
      if(formData.RawMaterialTypeOther){
        let validationResult = lengthAndValidCharactersInStringCheck(formData.RawMaterialTypeOther, translate)
        if(!validationResult['status']){
          arrErr.push(translate('rawMaterialType') + ": " + validationResult['message'])
        }
      }
    } else{
      if(formData.BatchName){
        let validationResult = lengthAndValidCharactersInStringCheck(formData.BatchName, translate)
        if(!validationResult['status']){
          arrErr.push(translate('batchName') +": "+ validationResult['message'])
        }
      }
      
    }

    if (!vendorDetailsValidation()) {
      newArrVendor.map((ele) => arrErr.push(`${translate(ele.name)} ${translate('isNotSelected')}`));
      errorsList.push(translate('vendorDetailsNotSelected'))
    }

    if(formData?.UserDefinedBatchNo){
      let validationResult =  lengthAndValidCharactersInStringCheck(formData?.UserDefinedBatchNo, translate)
      if(!validationResult['status']){
        arrErr.push(newBatchDetailsSchema.properties.UserDefinedBatchNo.title +": "+ validationResult['message'])
      }
    }

    if(comments){
      let validationResult =  lengthAndValidCharactersInStringCheck(comments, translate)
      if(!validationResult['status']){
        arrErr.push(translate('instructions') + ": " + validationResult['message'])
      }
    }

    if (!canSkipRawMaterial && !mixesValidation() ) {
      // newArrMixes.map((ele) => arrErr.push(`${ele.name} is not selected`));
      newArrMixes.map((ele) => arrErr.push(`${translate(ele.name)} ${ele.isFullErrMsg ? "" : translate('isNotSelected')}`));
      errorsList.push(translate('mixesNotSelected'))
    }

    if (!fileUploadValidation()) {
      // newArr.map((ele) => arrErr.push(`${ele.name} is not selected`));
      newArr.map((ele) => arrErr.push(`${translate(ele?.name)} ${translate('isNotSelected')}`));
      errorsList.push(translate('fileUploadIsNotSelected'))
    }

    let msg = "";
    setErrors(errorsList)
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
      return false;
    } else{ 
      return true;
    }

  }

  function validateFunction(formData, errors) {
    let arrErr = [];

    // if(sourceCategoryData?.name?.toLowerCase() == 'premix'){
    //   let listOfRawMaterialsRequired = newBatchDetailsSchema.properties.RawMaterialName.enum


    //   let setOfRawMaterialsInSelectedMixes = new Set()
    //   batchLotDetails.forEach((ele)=>{
    //     setOfRawMaterialsInSelectedMixes.add(ele?.batchName)
    //   })

    //   const elementsNotInSet = listOfRawMaterialsRequired.filter(item=> !setOfRawMaterialsInSelectedMixes.has(item))

    //   if(elementsNotInSet.length > 0){
    //     elementsNotInSet.map((ele) => arrErr.push(`Raw material: ${ele} is not selected`));
    //     errors?.addError("Necessary Raw materials are not selected");
    //   }
    // }

    // if(iscategoryRawMaterial && !formData.RawMaterialName){
    //   arrErr.push("Raw material name is mandatory");
      
    // }
    if (formData?.ExpiryDate && (formData?.ExpiryDate < formData?.ManufacturingDate)) {
      errors?.addError("Selected Expiry Date less than Manufacturing Date");
      arrErr.push("Selected Expiry Date less than Manufacturing Date");
      setErrMsg(errors?.ExpiryDate?.__errors[0]);
    }

    // if (!vendorDetailsValidation()) {
    //   errors?.addError("Vendor Details are not selected");

    //   newArrVendor.map((ele) => arrErr.push(`${translate(ele.name)} ${translate('isNotSelected')}`));
    // }

    if (batchMfgDate == "") {
      errors?.addError("Manufacturing Date is not selected");

      // setErrMsg(errors?.ManufacturingDate?.__errors[0]);
      arrErr.push("Manufacturing Date is not selected");
      // dispatchNotification("error", "Manufacturing Date is not selected");
    }
    // if (batchExpDate == "") {
    //   errors?.addError("Expiry Date is not selected");

    //   // setErrMsg(errors?.ExpiryDate?.__errors[0]);
    //   arrErr.push("Expiry Date is not selected");
    //   // dispatchNotification("error", "Expiry Date is not selected");
    // }
    // if (!canSkipRawMaterial && !mixesValidation() ) {
    //   errors?.addError("Mixes is not selected");

    //   newArrMixes.map((ele) => arrErr.push(`${ele.name} is not selected`));
    // }
    // if (!fileUploadValidation()) {
    //   errors?.addError("File Upload is not selected");

    //   newArr.map((ele) => arrErr.push(`${ele.name} is not selected`));
    // }

    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      dispatchNotification("error", arrErr);
      // getFileData([])
    } else{ 
      setFormData(formData);
    }
    return errors;
  }

  const BatchSizeWidget = () => {
    return (
      <BatchSizeComponent
        batchSizeUnits={batchSizeLot}
        setBatchSizeLot={setBatchSizeLot}
        lotSize={BatchData?.lot}
        setShowButton={setShowButton}
        disableVendorData={disableVendorData || isInspector}
      />
    );
  };
  const GetDateWidget = (newBatchDetailsSchema) => {
    return (
      <DatePickerComponent
        populateDate={BatchData}
        schema={newBatchDetailsSchema}
        setGetDate={setGetDate}
        setBatchDate={setBatchDate}
        getDate={getDate}
        disableVendorData={disableVendorData || isInspector}
        setShowButton={setShowButton}
      />
    );
  };

  const memoizedDateWidget = useMemo(()=>{
    return (newBatchDetailsSchema) => (
      <DatePickerComponent
      populateDate={BatchData}
      schema={newBatchDetailsSchema}
      setGetDate={setGetDate}
      setBatchDate={setBatchDate}
      getDate={getDate}
      disableVendorData={disableVendorData || isInspector}
      setShowButton={setShowButton}
    />
    )
  },[BatchData,getDate, disableVendorData])

  const GetAllFilesWidget = () => {
    return (
      <GetAllFiles
        getId={getId}
        addPath={addPath}
        createFileUploadFields={createFileUploadFields}
        getFileData={getFileData}
        handleFileDelete={handleFileDelete}
        setRemovedNames={setRemovedNames}
        setShowButton={setShowButton}
        disableVendorData={disableVendorData || isInspector}
        sourceCategoryData={sourceCategoryData}
      />
    );
  };

  const memoizedGetAllFilesWidget = useMemo(()=>{

    return ()=>(
      <GetAllFiles
        getId={getId}
        addPath={addPath}
        createFileUploadFields={createFileUploadFields}
        getFileData={getFileData}
        handleFileDelete={handleFileDelete}
        setRemovedNames={setRemovedNames}
        setShowButton={setShowButton}
        disableVendorData={disableVendorData || isInspector}
        sourceCategoryData={sourceCategoryData}
      />
    );
  },[disableVendorData,createFileUploadFields, sourceCategoryData])
  const widgets = {
    BatchSizeWidget,
    // GetAllFilesWidget,
    memoizedGetAllFilesWidget,
    // GetDateWidget,
    memoizedDateWidget
    // ReqCatergoryWidget
  };

  const rawMaterialTypes = {
    // "Vitamin B12": {
    //   enum: ["Cyanocobalamine", "Hydroxycobalamine"],
    //   enumNames:[translate("cyanocobalamine"),translate("hydroxycobalamine")]
    //   // enum: ["Cyanocobalamine", "Hydroxycobalamine", "other"],
    //   // recommendedPremixPercentage: "Recommended 0.5 - 1%",
    // },
    "Vitamin B9": {
      enum: ["Folic acid"],
      enumNames:[translate('folicAcid')]
      // enum: ["Folic acid", "other"],
      // recommendedPremixPercentage: "Recommended 0.5 - 1%",
    },
    "Iron": {
      enum: ["Ferric pyrophosphate", "Sodium feredetate-Na Fe EDTA"],
      enumNames:[translate("ferricPyrophosphate"),translate("sodiumFeredetate")]
      // enum: ["Ferric pyrophosphate", "Sodium feredetate-Na Fe EDTA", "other"],
      // recommendedPremixPercentage: "Recommended 78 - 84%",
    },
    "Premix Base": {
      enum: ["other"],
      // recommendedPremixPercentage: "Recommended 15 - 20%",
    },
  };
  
  return (
    <Box id="BatchDetails" className="form-div ">
      <Box sx={{ padding: "0 16px" }} id="rjsf">
        <div id="batch-detail-form">
          {
            <>
            {errors.length > 0 && (
              <div style={{ color: "red", marginTop: "20px", fontSize: "medium" }}>
                {/* <h4>Validation Errors:</h4> */}
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <Form
              schema={
                categoryLocation === "rice"
                  ? newBatchDetailsRiceSchema
                  : newBatchDetailsSchema
              }
              validator={validator}
              // key={language}
              transformErrors={(errors)=>{return []}}
              uiSchema={batchDetailsUiSchema}
              formData={formData}
              widgets={widgets}
              disabled={disableVendorData || isInspector}
              customValidate={validateFunction}
              className="batch-form-styling"
              onChange={(e) => {
                setShowButton(true);
                if(iscategoryRawMaterial){
                  if (
                    (e.formData.RawMaterialName != undefined) &&
                    e.formData.RawMaterialName !== formData.RawMaterialName
                  ) {
                    // batchDetailsUiSchema["PremixPercentageNeededForFRK"] = {
                    //   "ui:help":
                    //     rawMaterialTypes[e.formData.RawMaterialName]
                    //       .recommendedPremixPercentage,
                    // };
                    newBatchDetailsSchema["properties"]["RawMaterialType"] = {
                      title: translate('rawMaterialType'),
                      enum: rawMaterialTypes[e.formData.RawMaterialName].enum,
                      enumNames: rawMaterialTypes[e.formData.RawMaterialName].enumNames,
                    };
                    if ("RawMaterialType" in e.formData) {
                      delete e.formData["RawMaterialType"];
                    }
                    if("RawMaterialTypeOther" in e.formData){
                      delete e.formData["RawMaterialTypeOther"]
                    }
                    if(e.formData.RawMaterialName === "Premix Base"){
                      batchDetailsUiSchema['RawMaterialTypeOther'] = {}
                      batchDetailsUiSchema['RawMaterialType']['ui:widget'] = 'hidden'
                    } else{
                      batchDetailsUiSchema['RawMaterialTypeOther']['ui:widget'] = 'hidden'
                      batchDetailsUiSchema['RawMaterialType'] = {}
                    }
                  } else if(e.formData.RawMaterialName == undefined){
                    if("RawMaterialType" in e.formData){
                      delete e.formData["RawMaterialType"]
                    }
                    if("RawMaterialTypeOther" in e.formData){
                      delete e.formData["RawMaterialTypeOther"]
                    }
                    batchDetailsUiSchema['RawMaterialTypeOther']['ui:widget'] = 'hidden'
                    batchDetailsUiSchema['RawMaterialType'] = {}
                  } 
                  // if(e.formData.RawMaterialType === 'other'){
                  //   batchDetailsUiSchema['RawMaterialTypeOther'] = {}
                  // }else{
                  //   batchDetailsUiSchema['RawMaterialTypeOther']['ui:widget'] = 'hidden'
                  // }
                } else{
                  batchDetailsUiSchema['RawMaterialTypeOther']['ui:widget'] = 'hidden'
                }
                e.formData.BatchName = !e.formData.BatchName?.trim() ? '': e.formData.BatchName;
                setFormData(e.formData);
                setSaveData(e.formData);
              }}
              onSubmit={(e) => {
                if(!validationAfterSubmitting()){
                  return;
                }
                setCreateOrSaveDisable(true);
                sendFormData(e.formData, setCreateOrSaveDisable);
              }}
            >
              {getId.id ? (
                <div
                  className={`save-button-div${showButton ? "" : "-disabled"}`}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={createOrSaveDisabled}
                  >
                    {translate('saveBatchDetails')}
                  </Button>
                </div>
              ) : (
                <div className="save-button-div">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={createOrSaveDisabled}
                  >
                    {translate('create')}
                  </Button>
                </div>
              )}
            </Form>
            </>
          }
        </div>
      </Box>
    </Box>
  );
}

export default BatchDetails;
