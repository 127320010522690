import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputAdornment, Input, IconButton ,OutlinedInput} from "@mui/material";
import * as React from "react";
import "./search.css"
import useTranslatewrapper from "../useTranslateWrapper";
import { lengthAndValidCharactersInStringCheck } from "../../commonFunctions";
import { useAppDispatch } from "../../services/StoreHooks";

export default function SearchBar(props) {
  const { translate } = useTranslatewrapper();

  const { onChange } = props;
  const [value, setValue] = React.useState("");
  const dispatch = useAppDispatch();

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  return (
    <FormControl  variant="outlined" sx={{ minheight: "1rem", width:"80%" ,margin:"8px"}}>
      <OutlinedInput
        id="search-input-box"
        onChange={(e) => {
          if(e.target.value){
            let arrErr = [];
            let validationResult = lengthAndValidCharactersInStringCheck(e.target.value, translate);
            if (!validationResult['status']) {
              arrErr.push(translate('search') + ": " + validationResult['message'])
              dispatchNotification("error", arrErr);
              return;
            }
          }
          setValue(e.target.value);
        }}
        onKeyDown={(e)=> {
          if(e.key === 'Enter'){
            onChange({ label: value });
            setValue("") ;
          } 
        }}
        placeholder={`${translate('search')}...`}
        sx={{padding:"0px 15px" ,borderRadius:"50px"}}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton m={1} onClick={() => {onChange({ label: value }) ; setValue("") }} edge="end">
              <SearchIcon sx={{color:"#0075ff"}}/>
            </IconButton>
          </InputAdornment>
        }
         variant="outlined" 
      />
    </FormControl>
  );
}
