import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Button, TextField, MenuItem, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Chip from "@mui/material/Chip";
import { getUomData } from "../../../services/FortificationService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

function StackComponent({
  setBatchSizeLot,
  lotSize,
  sizeUnitsDropdownData,
  setShowButton,
  disableVendorData,
  submit,
}) {
  const {translate} = useTranslatewrapper()
  const [uomData, setUomData] = useState();
  const [chipData, setChipData] = useState(
    lotSize?.map(
      (data) =>
      ({
        size: data?.size,
        unit: data?.uom?.name,
        quantity: data?.quantity,
        uomId: data?.uom?.id,
      } || [])
    )
  );
  const [fields, setFields] = React.useState({
    size: "",
    units: "",
    quantity: "",
  });
  const [size, setSize] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const sizeUnitsDropdown = Object.keys(sizeUnitsDropdownData).map(
    (d) => sizeUnitsDropdownData[d]
  );

  const fetchData = async () => {
    const SizeUnit = await getUomData();
    setUomData(SizeUnit.data.data);
  };
  useEffect(() => {
    fetchData();
  }, []);






  const handleAddItem = () => {
    if (chipData && chipData.length > 0) {
      setChipData([
        ...chipData,
        { size: "", unit: "", quantity: "", uomId: "" },
      ]);
    } else {
      setChipData([{ size: "", unit: "", quantity: "" }]);
    }
  };

  const handleDeleteRow = (idx) => {
    if (chipData.length > 0) {
      const data = [...chipData];
      data.splice(idx, 1);
      setChipData(data);
      setBatchSizeLot(data);
      // sizeUnitsDropdown = [...data]
    }
  };

  return (
    <Box sx={{ marginTop: "1rem !importatn" }}>
      <TableContainer>
        <Table
          sx={{ width: 800 }}
          className="form-table"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: "black" }}
              >
                {translate("S.No")}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " black" }}
                align="left"
              >
                {translate('bagSizes')}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " black" }}
                align="left"
              >
                {translate('quantity')}
              </TableCell>
              <TableCell
                className="table-head"
                sx={{ border: 1, borderColor: " black" }}
                align="left"
              >
                {translate('actions')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {chipData &&
              chipData.map((data, idx) => (
                <TableRow
                  // key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 1,
                      borderColor: " black",
                    },
                  }}
                >
                  <TableCell
                    sx={{ border: 1, borderColor: " black" }}
                    component="th"
                    scope="row"
                  >
                    {idx + 1}
                  </TableCell>
                  <TableCell
                    sx={{ border: 1, borderColor: " black" }}
                    align="left"
                  >
                    <TextField
                      disabled={disableVendorData}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="unit"
                      key={data.sizeInKg}
                      id="outlined-basic"
                      value={data.sizeInKg}
                      variant="standard"
                      error={submit && !data.sizeInKg && true}
                      select
                      onChange={(event) => {
                        const { value } = event.target;
                        chipData[idx]["sizeInKg"] =
                          sizeUnitsDropdownData[value]?.sizeInKg;
                        chipData[idx]["size"] =
                          sizeUnitsDropdownData[value]?.size;
                        chipData[idx]["unit"] =
                          sizeUnitsDropdownData[value]?.uom;
                        chipData[idx]["uom"] =
                          sizeUnitsDropdownData[value]?.uom;
                        let unit = uomData.filter(
                          (e) => e.name === chipData[idx]["unit"]
                        )[0];
                        chipData[idx]["uomId"] = unit?.id;
                        chipData[idx]["conversionFactorKg"] =
                          unit?.conversionFactorKg;

                        setBatchSizeLot([...chipData]);

                        setUnit(chipData[idx]["unit"]);
                      }}
                    >
                      {Array.isArray(sizeUnitsDropdown) &&
                        sizeUnitsDropdown?.map((batchSizeUnit, index) => {
                          const addedSizeIndex =  chipData.findIndex(data => `${data?.size} ${data?.uom}` === `${batchSizeUnit?.size} ${batchSizeUnit?.uom}`);
                          if(addedSizeIndex === -1 || addedSizeIndex === idx){
                            
                            return (
                              <MenuItem value={batchSizeUnit?.sizeInKg}>
                                {batchSizeUnit?.size} {translate(batchSizeUnit?.uom)}
                              </MenuItem>
                            );
                          }
                        })}
                    </TextField>
                  </TableCell>
                  <TableCell
                    sx={{ border: 1, borderColor: " black" }}
                    align="left"
                  >
                    <TextField
                      disabled={disableVendorData}
                      sx={{ width: "100%", backgroundColor: "#fff" }}
                      name="quantity"
                      id="outlined-basic"
                      value={data.quantity}
                      type="number"
                      variant="standard"
                      error={submit && data.quantity === "" && true}
                      onChange={(event) => {
                        chipData[idx]["quantity"] = event.target.value;
                        setBatchSizeLot([...chipData]);

                        setQuantity(event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 1, borderColor: " black" }}
                    align="left"
                  >
                    <Button
                      disabled={disableVendorData}
                      sx={{ textDecoration: "underline" }}
                      onClick={() => handleDeleteRow(idx)}
                    >
                      {translate('delete')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={chipData?.length >= sizeUnitsDropdown?.length}
        sx={{ textDecoration: "underline" }}
        onClick={() => handleAddItem()}
      >
        <AddCircleOutlineIcon />
        {translate('addItem')}
      </Button>
    </Box>
  );
}

export default StackComponent;
