import useTranslatewrapper from "../../../components/useTranslateWrapper";

export default function useBatchDetailsRiceSchemaFn() {
  // const {t, i18n} = useTranslation();
  const { translate } = useTranslatewrapper();
  return {
    properties: {
      BatchName: {
        type: "string",
        title: translate('name'),
      },
      UserDefinedBatchNo: {
        type: "string",
        title: translate('manufacturerLotNo'),
      },
      BatchNoOfPremixManufracturer: {
        type: "string",
        title: translate('autoGeneratedBatchNo'),
        readOnly: true,
      },
      LotNo: {
        type: "string",
        title: translate('autoGeneratedLotNo'),
        readOnly: true,
      },
      ManufacturingDate: {
        type: "string",
        title: translate("manufacturingDate"),
      },
      ExpiryDate: {
        title: translate("expiryDate"),
        type: "string",
      },
      PremixPercentageNeededForFRK: {
        type: "number",
        title: translate("percentInMix"),
        maximum: 100,
        minimum: 0,
      },
      TotalQuantity: {
        title: translate("totalQuantityInKgs"),
        type: "number",
        minimum: 0.01,
      },
      RemainingQuantity: {
        title: translate("remainingQuantityInKgs"),
        type: "number",
        minimum: 0,
      },
      BatchSize: {
        type: "string",
        title: "Bag Sizes",
      },

      CropYear: {
        title: translate('cropYear'),
        type: "number",
        minimum: 0,
      },
      RiceType: {
        title: translate("riceType"),
        enum: ["Steam rice naadu variety", "Raw", "Boil"],
        enumNames: [translate('steamRiceNaaduVariety'), translate('raw'),translate('boil')]
      },
      // Grade: {
      //   title: translate('grade'),
      //   type: "string",
      // },
      Variety: {
        title: translate('variety'),
        type: "string",
      },
      GetAllFiles: {
        title: "Upload File",
        type: "string",
      },
    },
    required: [
      "BatchName",
      // "PremixPercentageNeededForFRK",
      "TotalQuantity",
      "CropYear",
      "RiceType",
      "Variety",
      // "Grade",
    ],
  };
}
