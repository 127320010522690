import React, { useEffect, useState } from 'react'
import { Form } from '@rjsf/mui'
import validator from "@rjsf/validator-ajv8";
import { getDocTypeFormDropdown, adminCategoryDocDropDown, editIamCatDocTypeByID, getIamCategoryDocTypeByID, createStateLabAccess, getState, getStateLabAccessByID, editStateLabAccessByID } from "../../../../services/adminService.js"
import { Box, Button, Typography } from '@mui/material';
import "../../../../../src/App.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import { BACK_BUTTON, MILLER_UI_TEXT } from '../../../../textConstant.js';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../services/StoreHooks.js';
import "../../../../App.css";
import useTranslatewrapper from '../../../../components/useTranslateWrapper.js';

function StateForm() {
  const {translate} = useTranslatewrapper()

  const [catDropDown, setCatDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const entity = ["lot", "batch"];
  const navigate = useNavigate()
  const [formData, setFormData] = useState({stateId:'',categoryId:'' , entity:'',labSelectionAllowed:true,blockWorkflowForTest:true});
  const params = useParams()
  const fetchDropdown = async () => {
    const dropdownData = await adminCategoryDocDropDown();
    dropdownData && setCatDropDown(dropdownData.data.data);
  }
  const ftechStateDropDown = async () => {
    const stateDropDownData = await getState(2);
    stateDropDownData && setStateDropDown(stateDropDownData.data.data);
  }
  const fetchFormData = async () => {
    const formDataApi = await getStateLabAccessByID(params.categoryId,params.entityType,params.id);
    formDataApi && setFormData(formDataApi?.data)
    if (formDataApi.status === 200) {
      if (formDataApi.data) {

          setFormData(d => {
              d.stateId = formDataApi.data.stateResponseDto?.id
              d.categoryId = formDataApi.data.categoryId
              d.entityType = formDataApi.data.entityType
              d.labSelectionAllowed = formDataApi.data.labSelectionAllowed
              d.blockWorkflowForTest =formDataApi.data.blockWorkflowForTest
              return { ...d }
          });
      }}
  }
  useEffect(() => {
    fetchDropdown();
    ftechStateDropDown();
  }, [])
  useEffect(() => {
    params.id && fetchFormData();
  }, [params])

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
      dispatch({
          type: "SHOW_NOTIFICATION",
          data: {
              open: true,
              type: typ,
              message: msg,
          },
      });
  };

  const createForm = async (data) => {
  let Response;
  console.log(data, formData)
  if (params.id) {
    Response = await editStateLabAccessByID(params.categoryId,params.entityType,params.id ,{
      stateId: data.stateId,
      categoryId: data.categoryId,
      entityType:data.entityType,
      labSelectionAllowed: data?.labSelectionAllowed ? data.labSelectionAllowed:false,
      blockWorkflowForTest:data?.blockWorkflowForTest ? data.blockWorkflowForTest :false,
    });
  } else {
    Response = await createStateLabAccess({
      stateId: data.stateId,
      categoryId: data.categoryId,
      entityType:data.entityType,
      labSelectionAllowed: data?.labSelectionAllowed ? data.labSelectionAllowed:false,
      blockWorkflowForTest:data?.blockWorkflowForTest ? data.blockWorkflowForTest :false,
    });
  }

  if (Response?.status === 201) {
    navigate(-1);
    dispatchNotification("success", ["Created Successfully"]);
}
if (Response?.status === 202 || Response?.status === 200) {
    navigate(-1);
    dispatchNotification("success", ["Updated Successfully"]);
}
  };

  const manageStateSchema = {
    properties: {
     stateId: {
        title: translate('states'),
        enumNames: stateDropDown.map((item) => item.name),
        enum: stateDropDown?.map((item) => item.id),
        readOnly: params?.id ? true : false,
      },

      categoryId: {
        title: translate('category'),
        enumNames: catDropDown.map((item) => item.name.replace('MILLER',MILLER_UI_TEXT)),
        enum: catDropDown?.map((item) => item.id),
        readOnly: params?.id ? true : false,
      },
      entityType: {
        title: "Entity",
        enumNames: entity.map((item) => item),
        enum: entity?.map((item) => item),
        readOnly: params?.id ? true : false,
      },
      labSelectionAllowed: {
        type: "boolean",
        default: "false",
        title: "Allow Lab access ?",
      },
      blockWorkflowForTest: {
        type: "boolean",
        default: "false",
        title: "Block Workflow ?",
      },
    },
  };
  const manageStateUiSchema = {};
  const manageStatedata = {
    stateId: formData?.stateId,
    categoryId: formData?.categoryId,
    entityType:formData?.entityType,
    labSelectionAllowed: formData?.labSelectionAllowed,
    blockWorkflowForTest:formData?.blockWorkflowForTest,
  };
  return (
    <div>
      <div className="main-content-overlay">
       State Management
        <Box className="main-content-div" style={{ position: "relative" }}>

          <Box id="VendorDetails" className="form-div ">
            <Box p={2} id="rjsf">
              <Button
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#0075FF",
                  }}
                >
                  {BACK_BUTTON}
                </Typography>
              </Button>
              {catDropDown && <Form
                schema={manageStateSchema}
                validator={validator}
                uiSchema={manageStateUiSchema}
                formData={manageStatedata}
                className="batch-form-styling"
                onChange={(e) => {
                  console.log("e", e)
                  setFormData({ ...e.formData })
                }}
                onSubmit={(e) => {
                  console.log(e.formData)
                  createForm(e.formData);
                }}
              ></Form>
              }

            </Box>
          </Box>
        </Box>
      </div>


    </div>
  )
}

export default StateForm