import { Box, Button, ListItem, Modal, Tooltip, Typography } from "@mui/material";
import React from "react";
import BasicStepper from "../Timeline/BasicStepper";
import {
  getDownloadedDocument,
  getLabDownloadedDocument,
} from "../../services/FortificationService";
import FileIcon from "../FileIcon/FileIconList";
import { saveAs } from 'file-saver'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import useTranslatewrapper from "../useTranslateWrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "90%",
};

const gridContainer = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 50%)",
  gap: "10px" 
}

const gridItem = {
  padding: "5px 5px",
  border: "1px dashed #0075ff",
  borderRadius:"5px",
  height: "30px",
  justifyContent: "flex-start"
}

const StepperModal = (props) => {
  const { translate } = useTranslatewrapper();

  const handleFileDownload = async (path, isFortification) => {
    if (isFortification) {
      const blob = await getDownloadedDocument(path);

      blob.data && saveAs(blob.data, path);
    } else {
      const blob = await getLabDownloadedDocument(path);

      blob.data && saveAs(blob.data, path);
    }
  };
  return (
    <Modal
      open={props.batchNo != null}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {props.stepperData == 0 ? (
          <Typography style={{ textAlign: "center" }}>
            No Records Found for {props.batchNo}
          </Typography>
        ) : (
          <>
            <Typography style={{ textAlign: "center" }}>
              Batch History of {props.batchNo}
            </Typography>
            <BasicStepper
              steps={props.stepperData}
              orientation={"vertical"}
            ></BasicStepper>
            <>
              <>
                {props.batchTestDocs &&
                  Array.isArray(props.batchTestDocs) &&
                  props.batchTestDocs.length > 0 && (
                    <>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Batch Test Reports"}
                      </div>
                      <div style={gridContainer}>
                        {props.batchTestDocs.map((ele) => (
                          <ListItem key={ele.path || ele.name} sx={gridItem}>
                            <FileIcon filename={ele.path} />
                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {ele?.categoryDoc?.docType?.name ||
                                    ele.name ||
                                    ele.path}
                                </Typography>
                              }
                              placement="top"
                            >
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: "#0075ff",
                                  textDecoration: "underline",
                                }}
                              >
                                {ele?.categoryDoc?.docType?.name ||
                                  ele.name ||
                                  ele.path}
                              </span>
                            </Tooltip>

                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {translate("download")}
                                </Typography>
                              }
                              placement="top"
                            >
                              <Button
                                sx={{
                                  padding: "0px",
                                  minWidth: "1px",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  handleFileDownload(ele.path, false);
                                }}
                              >
                                <FileDownloadOutlinedIcon
                                  sx={{ fontSize: "1.2rem", color: "#0075ff" }}
                                  m={1}
                                />
                              </Button>
                            </Tooltip>
                          </ListItem>
                        ))}
                      </div>
                    </>
                  )}
                {props.lotTestDocs &&
                  Array.isArray(props.lotTestDocs) &&
                  props.lotTestDocs.length > 0 && (
                    <>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Lot Test Reports"}
                      </div>
                      <div style={gridContainer}>
                        {props.lotTestDocs.map((ele) => (
                          <ListItem key={ele.path || ele.name} sx={gridItem}>
                            <FileIcon filename={ele.path} />
                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {ele?.categoryDoc?.docType?.name ||
                                    ele.name ||
                                    ele.path}
                                </Typography>
                              }
                              placement="top"
                            >
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: "#0075ff",
                                  textDecoration: "underline",
                                }}
                              >
                                {ele?.categoryDoc?.docType?.name ||
                                  ele.name ||
                                  ele.path}
                              </span>
                            </Tooltip>

                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {translate("download")}
                                </Typography>
                              }
                              placement="top"
                            >
                              <Button
                                sx={{
                                  padding: "0px",
                                  minWidth: "1px",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  handleFileDownload(ele.path, false);
                                }}
                              >
                                <FileDownloadOutlinedIcon
                                  sx={{ fontSize: "1.2rem", color: "#0075ff" }}
                                  m={1}
                                />
                              </Button>
                            </Tooltip>
                          </ListItem>
                        ))}
                      </div>
                    </>
                  )}
                {props.selfDeclaredTestDocs &&
                  Array.isArray(props.selfDeclaredTestDocs) &&
                  props.selfDeclaredTestDocs.length > 0 && (
                    <>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Self Declared Test Reports"}
                      </div>
                      <div style={gridContainer}>
                        {props.selfDeclaredTestDocs.map((ele) => (
                          <ListItem key={ele.path} sx={gridItem}>
                            <FileIcon filename={ele.path} />
                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {"Test Report"}
                                </Typography>
                              }
                              placement="top"
                            >
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: "#0075ff",
                                  textDecoration: "underline",
                                }}
                              >
                                {"Test Report"}
                              </span>
                            </Tooltip>

                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {translate("download")}
                                </Typography>
                              }
                              placement="top"
                            >
                              <Button
                                sx={{
                                  padding: "0px",
                                  minWidth: "1px",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  handleFileDownload(ele.path, true);
                                }}
                              >
                                <FileDownloadOutlinedIcon
                                  sx={{ fontSize: "1.2rem", color: "#0075ff" }}
                                  m={1}
                                />
                              </Button>
                            </Tooltip>
                          </ListItem>
                        ))}
                      </div>
                    </>
                  )}
              </>
            </>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default StepperModal;
