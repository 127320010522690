import PremixGrid from "./pages/Dashboard/PremixGrid";
import InventoryPage from "./pages/Inventory/InventoryPage";
import UserList from "./pages/Manage Page/UsersList";
import UserDetails from './pages/Manage Page/UserDetails/UserDetails'
import PremixDetails from "./pages/PremixDetails/PremixDetails";
import RawMaterialDetails from "./pages/RawMaterialDetails/RawMaterialDetails";
import RawMaterialsList from "./pages/RawMaterialsList/RawMaterialsList";
import SupplyDemandPage from "./pages/SupplyDemand/SupplyDemandPage";
import UomForm from "./pages/Admin/fortificationService/uomService/uomForm";
import UomGrid from "./pages/Admin/fortificationService/uomService/uomGrid";
import categoryDoctypeForm from "./pages/Admin/labService/categoryDocType/CategoryDoctypeForm.js";
import CategoryDocTypeGrid from "./pages/Admin/labService/categoryDocType/categoryDoctypeGrid";
import manufacturersForm from "./pages/Admin/iamService/manufacturers/manufacturersForm.js";
import manufacturersGrid from "./pages/Admin/iamService/manufacturers/manufacturersGrid.js";

import labPageForm from "./pages/Admin/labService/labPage/labPageForm.js";
import labPageGrid from "./pages/Admin/labService/labPage/labPageGrid.js";

import TestTypesGrid from './pages/Admin/labService/TestType/TestTypesGrid'
import AddEditTesTypes from './pages/Admin/labService/TestType/TestReferenceMethod'
import categoryDocType from "./pages/Admin/iamService/catgoery Doc Type/categoryIamDoctypeGrid";
import categoryIamDoctypeForm from "./pages/Admin/iamService/catgoery Doc Type/categoryIamDoctypeForm";
import UserGrid from "./pages/Admin/iamService/Users/UserGrid";

import UserDetailsIam from "./pages/Admin/iamService/Users/UserDetails/UserDetailsIam";
import DocTypeGrid from "./pages/Admin/iamService/docType/docTypeGrid";
import DoctypeForm from "./pages/Admin/iamService/docType/docTypeForm";
import MapPage from "./pages/MapPage/MapPage";
import DoctypeFormLab from "./pages/Admin/labService/docType/docTypeForm";
import DocTypeGridLab from "./pages/Admin/labService/docType/docTypeGrid";
import CategoryFortificationDoctypeForm from "./pages/Admin/fortificationService/catgoery Doc Type/categoryDoctypeForm";
import CategoryFortificatioDocType from "./pages/Admin/fortificationService/catgoery Doc Type/categoryDoctypeGrid";
import DoctypeFortificationForm from "./pages/Admin/fortificationService/docType/docTypeForm";
import DocTypeFortificationGrid from "./pages/Admin/fortificationService/docType/docTypeGrid";
import UserDetailsLab from "./pages/Admin/labService/Users/UserDetails/UserDetaiLab";
import UserGridLab from "./pages/Admin/labService/Users/UserGrid";
import LabTesDoctypeForm from "./pages/Admin/labService/LabTest/LabTesDoctypeForm";
import LabDoctypeGrid from "./pages/Admin/labService/LabTest/LabDoctypeGrid";
import ManufacturerPageForm from "./pages/Form/Inspection/ManufacturerPage/ManufacturerPageForm";
import ManufacturerPageGrid from "./pages/Form/Inspection/ManufacturerPage/ManufacturePageGrid"
import StateForm from "./pages/Admin/iamService/State Management/StateForm";
import StateGrid from "./pages/Admin/iamService/State Management/StateGrid";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import InspectionGrid from "./pages/Admin/InspectionService/User/InspectionGird";
import NotificationPage from "./pages/Notification Page/NotificationPage";
import BatchAndVendorInfo from "./pages/PremixDetails/BatchAndVendorInfo";
import ReportingGrid from "./components/ReportingGrids/ReportingGrid";
import TokenLoginComponent from "./components/TokenLoginComponent.js";
import { LOGIN_PATH_USING_TOKEN } from "./constants.js";
import PremixCreate from "./pages/Form/PremixSections/PremixCreate.js";
import { ErrorPage } from "./ErrorPage.js";

export const ApplicationRoutes = [
  {
    key: "tokenLogin",
    title: "Token Login",
    path: LOGIN_PATH_USING_TOKEN,
    enabled: true,
    component: TokenLoginComponent,
  },
  {
    key: "premix",
    title: "Premix Grid",
    path: "/premix/:categoryId/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key: "frk",
    title: "FRK Grid",
    path: "/frk/:categoryId/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key: "miller",
    title: "Miller Grid",
    path: "/miller/:categoryId/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key :"frk-details-generic",
    title:"FRK Create Form",
    path:"/frk/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"miller-details-generic",
    title:"MIller Create Form",
    path:"/miller/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"premix-details-generic",
    title:"Premix Create Form",
    path:"/premix/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"premix-details-individual",
    title:"Premix Edit Form",
    path:"/premix/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"miller-details-individual",
    title:"Miller Edit Form",
    path:"/miller/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"frk-details-individual",
    title:"FRK Edit Form",
    path:"/frk/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"frk-details-individual",
    title:"FRK Edit Form",
    path:"/reporting/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  // {
  //   key :"premix-details-individual",
  //   title:"premix Edit Form",
  //   path:"/reporting/:categoryId/:type/details/:id",
  //   enabled:true,
  //   component:PremixDetails
  // },
  // {
  //   key :"frk-details-individual",
  //   title:"FRK Edit Form",
  //   path:"/reporting/:categoryId/:type/details/:id",
  //   enabled:true,
  //   component:PremixDetails
  // },
  {
    key :"premix-reporting-grid",
    title:"Reporting Grid for premix",
    path:"/reporting/premix/:categoryId/:reportType",
    enabled:true,
    component:ReportingGrid
  },
  {
    key :"frk-reporting-grid",
    title:"Reporting Grid for FRK",
    path:"/reporting/frk/:categoryId/:reportType",
    enabled:true,
    component:ReportingGrid
  },
  {
    key :"nfpb-reporting-grid",
    title:"Reporting Grid for NFPB",
    path:"/reporting/nfpb/:categoryId/:reportType",
    enabled:true,
    component:ReportingGrid
  },
  {
    key :"miller-reporting-grid",
    title:"Reporting Grid for miller",
    path:"/reporting/miller/:categoryId/:reportType",
    enabled:true,
    component:ReportingGrid
  },
  {
    key :"school-reporting-grid",
    title:"Reporting Grid for school",
    path:"/reporting/school/:categoryId/:reportType",
    enabled:true,
    component:ReportingGrid
  },
  {
    key :"inventory",
    title:"inventory",
    path:"/inventory/:categoryId/:type",
    enabled:true,
    component:InventoryPage
  },
  {
    key :"supply-demand",
    title:"Supply-Demand",
    path:"/supply-demand",
    enabled:true,
    component:SupplyDemandPage
  },
  {
    key :"raw-material",
    title:"Raw Materials List",
    path:"/raw-material/:categoryId/:type",
    enabled:true,
    component:RawMaterialsList
  },
  {
    key :"rice-material",
    title:"Rice Materials List",
    path:"/rice/:categoryId/:type",
    enabled:true,
    component:RawMaterialsList
  },
  {
    key :"rice-mix",
    title:"Broken Rice List",
    path:"/rice-mix/:categoryId/:type",
    enabled:true,
    component:RawMaterialsList
  },
  {
    key :"raw-material-details",
    title:"Raw-Material Create Form",
    path:"raw-material/:categoryId/:type/details",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"rice-mix-details",
    title:"Rice-Mix Create Form",
    path:"rice-mix/:categoryId/:type/details",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"rice-details",
    title:"Rice Create Form",
    path:"rice/:categoryId/:type/details",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"raw-material-specific-details",
    title:"Raw-Material Update Form",
    path:"raw-material/:categoryId/:type/details/:id",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"rice-mix-specific-details",
    title:"Rice-Mix Update Form",
    path:"rice-mix/:categoryId/:type/details/:id",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"rice-specific-details",
    title:"Rice Update Form",
    path:"rice/:categoryId/:type/details/:id",
    enabled:true,
    component:RawMaterialDetails
  },
  {
    key :"default route",
    title:"default",
    path:"/",
    enabled:true,
    component:PremixGrid
  },
  {
    key :"premix-uuid-edit for",
    title:"Premix Read Form",
    path:"/premix/:categoryId/:type/code/:uuid",
    enabled:true,
    component:BatchAndVendorInfo
  },
  {
    key :"frk-uuid-edit for",
    title:"Frk Read Form",
    path:"/frk/:categoryId/:type/code/:uuid",
    enabled:true,
    component:BatchAndVendorInfo
  },
  {
    key :"miller-uuid-edit for",
    title:"miller Read Form",
    path:"/miller/:categoryId/:type/code/:uuid",
    enabled:true,
    component:BatchAndVendorInfo
  },
  {
    key :"manage",
    title:"Manage Page",
    path:"/manage",
    enabled:true,
    component:UserList
  },
  {
    key :"userlist",
    title:"User List",
    path:"/manage/user",
    enabled:true,
    component:UserList
  },
  {
    key :"adduser",
    title:"Add User Details",
    path:"/manage/user/details",
    enabled:true,
    component:UserDetails
  },
  {
    key :"userDetails",
    title:"User Details",
    path:"/manage/user/details/:id",
    enabled:true,
    component:UserDetails
  },
  {
    key :"admin-details-individual",
    title:"Admin Edit Form",
    path:"/admin/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"doctypeForm",
    title:"Doctype Form",
    path:"/admin/iam/doctype/details",
    enabled:true,
    component:DoctypeForm
  },
  {
    key :"doctypeForm",
    title:"Doctype Form",
    path:"/admin/iam/doctype/details/:id",
    enabled:true,
    component:DoctypeForm
  },
  {
    key: "uomGrid",
    title : "UOM List",
    path : "/admin/fortification/uom",
    component: UomGrid
  },
  {
    key: "uomService",
    title : "UOM File",
    path : "/admin/fortification/uom/details/",
    component: UomForm
  },
  {
    key: "uomService",
    title : "UOM File",
    path : "/admin/fortification/uom/details/:id",
    component: UomForm
  },
  // {
  //   key:"dashboard",
  //   title:"Dashboard Page",
  //   path:"/admin/dashboard/:categoryname/:categoryId",
  //   component: MapPage
  //   },
  
  {
    key :"iamDocType",
    title:"IAM Doc Type",
    path:"/admin/iam/doctype",
    enabled:true,
    component:DocTypeGrid
  },
 
  {
    key :"categoryIamDoctypeForm",
    title:"category Doctype Form",
    path:"/admin/iam/categorydoc/details",
    enabled:true,
    component:categoryIamDoctypeForm
  },
  {
    key :"categoryIamDoctypeForm",
    title:"category Doctype Form",
    path:"/admin/iam/categorydoc/details/:id",
    enabled:true,
    component:categoryIamDoctypeForm
  },
  {
    key :"iamCatDocType",
    title:"category Doctype Grid",
    path:"/admin/iam/categorydoc",
    enabled:true,
    component:categoryDocType
  },
  {
    key :"iamuserlist",
    title:"Iam User List",
    path:"/admin/iam/users",
    enabled:true,
    component:UserGrid
  },
  {
    key :"iamadduser",
    title:"Iam Add User Details",
    path:"/admin/iam/users/details",
    enabled:true,
    component:UserDetailsIam
  },
  {
    key :"iamuserDetails",
    title:"Iam User Details",
    path:"/admin/iam/users/details/:id",
    enabled:true,
    component:UserDetailsIam
  },
  // {
  //   key :"userlist",
  //   title:"User List",
  //   path:"/admin/iam/manufacturer",
  //   enabled:true,
  //   component:ManufactuterGrid
  // },
  // {
  //   key :"adduser",
  //   title:"Add User Details",
  //   path:"/admin/iam/manufacturer/details",
  //   enabled:true,
  //   component:ManufacturerForm
  // },
  // {
  //   key :"userDetails",
  //   title:"User Details",
  //   path:"/admin/iam/manufacturer/details/:id",
  //   enabled:true,
  //   component:ManufacturerForm
  // },
  {
    key :"categoryDoctypeForm",
    title:"category Doctype Form",
    path:"/admin/lab/categorydoc/details/:categoryDocRequirementType",
    enabled:true,
    component:categoryDoctypeForm
  },
  {
    key :"categoryDoctypeForm",
    title:"category Doctype Form",
    path:"/admin/lab/categorydoc/details/:categoryDocRequirementType/:id",
    enabled:true,
    component:categoryDoctypeForm
  },
  {
    key :"labDocType",
    title:"category Doctype Grid",
    path:"/admin/lab/categorydoc",
    enabled:true,
    component:CategoryDocTypeGrid
  },
  {
    key :"labtesttype",
    title:"Lab Test Type List",
    path:"/admin/lab/labtesttype",
    enabled:true,
    component:TestTypesGrid
  },
  {
    key :"addlabtesttype",
    title:"Add Lab Test Type",
    path:"/admin/lab/labtesttype/details",
    enabled:true,
    component:AddEditTesTypes
  },
  {
    key :"editlabtesttype",
    title:"Edit Lab Test Type",
    path:"/admin/lab/labtesttype/details/:id",
    enabled:true,
    component:AddEditTesTypes
  },
  {
    key :"doctypeLabForm",
    title:"doctype lab Form",
    path:"/admin/lab/doctype/details",
    enabled:true,
    component:DoctypeFormLab
  },
  {
    key :"doctypeLabForm",
    title:"doctype lab Form",
    path:"/admin/lab/doctype/details/:id",
    enabled:true,
    component:DoctypeFormLab
  },
  {
    key :"doctypeLabGrid",
    title:"doctype lab Grid",
    path:"/admin/lab/doctype",
    enabled:true,
    component:DocTypeGridLab
  },
  {
    key :"categoryDocFortificationForm",
    title:"category Doctype Form",
    path:"/admin/fortification/categorymapping/details",
    enabled:true,
    component:CategoryFortificationDoctypeForm
  },
  {
    key :"categoryDocFortificationForm",
    title:"category Doctype Form",
    path:"/admin/fortification/categorymapping/:categoryId/details/:id",
    enabled:true,
    component:CategoryFortificationDoctypeForm
  },
  {
    key :"categoryDocFortificationGrid",
    title:"category Doctype Grid",
    path:"/admin/fortification/categorymapping",
    enabled:true,
    component:CategoryFortificatioDocType
  },
  {
    key :"doctypeFortificationForm",
    title:"doctype fortification Form",
    path:"/admin/fortification/doctype/details",
    enabled:true,
    component:DoctypeFortificationForm
  },
  {
    key :"doctypeFortificationForm",
    title:"doctype fortification Form",
    path:"/admin/fortification/doctype/details/:id",
    enabled:true,
    component:DoctypeFortificationForm
  },
  {
    key :"doctypeFortificationForm",
    title:"doctype fortification Grid",
    path:"/admin/fortification/doctype",
    enabled:true,
    component:DocTypeFortificationGrid
  },
  {
    key :"iamuserlist",
    title:"Iam User List",
    path:"/admin/lab/users",
    enabled:true,
    component:UserGridLab
  },
  {
    key :"iamadduser",
    title:"Iam Add User Details",
    path:"/admin/lab/users/details",
    enabled:true,
    component:UserDetailsLab
  },
  {
    key :"iamuserDetails",
    title:"Iam User Details",
    path:"/admin/lab/users/details/:id",
    enabled:true,
    component:UserDetailsLab
  },
  {
    key :"labtestLabForm",
    title:"labtest lab Form",
    path:"/admin/lab/testdoctype/details/:categoryDocRequirementType",
    enabled:true,
    component:LabTesDoctypeForm
  },
  {
    key :"labtestLabForm",
    title:"labtest lab Form",
    path:"/admin/lab/testdoctype/details/:categoryDocRequirementType/:id",
    enabled:true,
    component:LabTesDoctypeForm
  },
  {
    key :"labtestLabGrid",
    title:"labtest lab Grid",
    path:"/admin/lab/testdoctype",
    enabled:true,
    component:LabDoctypeGrid
  },
  {
    key: "admin-lot",
    title: "Lot Grid",
    path: "/admin/fortification/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key :"premix-details-individual",
    title:"Premix Edit Form",
    path:"/admin/fortification/:type/:categoryId/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"manufacturersForm",
    title:"category Doctype Form",
    path:"/admin/iam/manufacturer/details",
    enabled:true,
    component:manufacturersForm
  },
  {
    key :"manufacturersForm",
    title:"category Doctype Form",
    path:"/admin/iam/manufacturer/details/:id",
    enabled:true,
    component:manufacturersForm
  },
  {
    key:"manufacturers",
    title:"category Doctype Grid",
    path:"/admin/iam/manufacturer",
    enabled:true,
    component:manufacturersGrid
  },
  {
    key :"labPageForm",
    title:"lab Form",
    path:"/admin/lab/labs/details",
    enabled:true,
    component:labPageForm
  },
  {
    key :"labPageForm",
    title:"category Doctype Form",
    path:"/admin/lab/labs/details/:id",
    enabled:true,
    component:labPageForm
  },
  {
    key:"labPage",
    title:"category Doctype Grid",
    path:"/admin/lab/labs",
    enabled:true,
    component:labPageGrid
  },
  {
    key:"inspectPage",
    title:"Inspect page",
    path:"/inspect/premix/:categoryId/:type",
    enabled:true,
    component:PremixGrid
  },
  {
    key: "frk",
    title: "FRK Grid",
    path: "/inspect/frk/:categoryId/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key: "miller",
    title: "Miller Grid",
    path: "/inspect/miller/:categoryId/:type",
    enabled: true,
    component: PremixGrid,
  },
  {
    key :"frk-details-generic",
    title:"FRK Create Form",
    path:"/inspect/frk/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"miller-details-generic",
    title:"MIller Create Form",
    path:"/inspect/miller/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"premix-details-generic",
    title:"Premix Create Form",
    path:"/inspect/premix/:categoryId/:type/details",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"premix-details-individual",
    title:"Premix Edit Form",
    path:"/inspect/premix/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"miller-details-individual",
    title:"Miller Edit Form",
    path:"/inspect/miller/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"frk-details-individual",
    title:"FRK Edit Form",
    path:"/inspect/frk/:categoryId/:type/details/:id",
    enabled:true,
    component:PremixDetails
  },
  {
    key :"manufacture-details-individual",
    title:"Manufacture Details",
    path:"/inspect/manage/manufacturer/",
    enabled:true,
    component:ManufacturerPageGrid
  },
  {
    key :"manufacture-details-individual",
    title:"Manufacture Details",
    path:"/inspect/manage/manufacturer/details/:id",
    enabled:true,
    component:ManufacturerPageForm
  },
  {
    key :"manufacture-details-individual",
    title:"Manufacture Details",
    path:"/inspect/manage",
    enabled:true,
    component:ManufacturerPageGrid
  },
  {
    key :"stateManagementForm",
    title:"state Management Form",
    path:"/admin/iam/state/details/:categoryId/:entityType/:id",
    enabled:true,
    component:StateForm
  },
  {
    key :"stateManagementForm",
    title:"state Management Form",
    path:"/admin/iam/state/details",
    enabled:true,
    component:StateForm
  },
  {
    key:"dashboards",
    title:"Dashboards",
    path:"admin/dashboard/:categoryname/:categoryId",
    enabled:true,
    component: DashboardPage,
    defaultParams: { geoId: 1, view: '', level: 'country' }
  
  },
  {
    key:"stateManagementPage",
    title:"state Management Grid",
    path:"/admin/iam/state",
    enabled:true,
    component:StateGrid
  },
  {
    key :"inspectionuserlist",
    title:"Inspection User List",
    path:"/admin/iam/inspectionusers",
    enabled:true,
    component:InspectionGrid
  },
  {
    key :"inspectionuserlist",
    title:"Inspection User List",
    path:"/admin/iam/inspectionusers/details",
    enabled:true,
    component:UserDetails
  },
  {
    key :"inspectionuserlist",
    title:"Inspection User List",
    path:"/admin/iam/inspectionusers/details/:id",
    enabled:true,
    component:UserDetails
  },
  {
    key:"notificationPage",
    title:"notification Page",
    path:"/notification",
    enabled:true,
    component:NotificationPage
  },
  {
    key:"premixCreateByFrk",
    title:"Premix create Page",
    path:":categoryname/self-declared",
    enabled:true,
    component: PremixCreate
  },
  {
    key:"errorPage",
    title:"Error Page",
    path:"/error",
    enabled:true,
    component: ErrorPage
  },
];
