import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, MenuItem } from "@mui/material";
import {
  getUomData,
  addPackagingInfo,
  editPackagingInfo,
} from "../../../services/FortificationService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../services/StoreHooks";
import { convert, sumArray } from "./TotalQuantityValidatew";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
function PakcageInfo({ rowInfoData, remainingQuantity, refreshPackagingInfo,isInspector, setIsDispatchDisable, disablePackageInfo }) {
  const {translate} = useTranslatewrapper()
  const navigate = useNavigate();
  const param = useParams();
  const [uomData, setUomData] = useState();
  const [chipData, setChipData] = useState([]);
  const [size, setSize] = React.useState([]);
  const [unit, setUnit] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [saveButton, setSaveButton] = useState(false);
  const dispatch = useAppDispatch();
  const pathNameCapitalize = useLocation().pathname.split("/")[1];
  let currentPath="";
  if(pathNameCapitalize ==='admin'){
    currentPath = `/${pathNameCapitalize}/fortification/${param.type}/${param.categoryId}`
  }else{
    currentPath = `/${pathNameCapitalize}/${param.categoryId}/${param.type}`;
  }
 
  const [generatedKey, setGeneratedKey] = useState(1000);
  const [open, setOpen] = React.useState(false);
  let arrSucc = []


  useEffect(() => {
    fetchUom();
  }, []);

  useEffect(() => {
  
      setChipData(
        rowInfoData?.map((data) => {
          return {
            size: data.size,
            unit: data.uom,
            quantity: data.quantity,
            uomId: data.uomId,
            conversionFactorKg: data.conversionFactorKg,
          isDispatched:data?.isDispatched

          };
        }).sort((a, b) => {
          if (a.isDispatched && !b.isDispatched) {
            return -1;
          } else if (!a.isDispatched && b.isDispatched) {
            return 1;
          }
          return 0;
        })
      );
  }, [rowInfoData]);

  const fetchUom = async () => {
    const uomData = await getUomData();
    uomData && setUomData(uomData.data.data);
  };


  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handleAddItem = () => {
    setChipData([
      ...chipData,
      {
        size: "",
        unit: "",
        quantity: "",
        uomId: "",
        key: generatedKey
      },
    ]);
    setGeneratedKey(generatedKey + 1);
  };
  const checkValidate = () => {
    let arrErr = [];
    let sum = 0
    chipData.map((data) => {
      (data.size == "") &&
        arrErr.push("Please Select all the Size");
      
      data.size != "" && (data.size == 0 || data.size < 1 || (data.size % 1 !==0 )) && 
        arrErr.push("Please enter valid sizes");

      data.uomId == "" &&
        arrErr.push("Please Select all the unit");
      (data.quantity == "" || data.quantity == 0 || data.quantity <= 0) &&
        arrErr.push(`Please Select all the Quantity`)
    }

    )
    sum = sumArray(chipData.filter((data)=> data.isDispatched === false).map((data) => convert(data.size, data.quantity, data.conversionFactorKg)));
    if (isNaN(sum)) {
      arrErr.push("Invalid values")
    }
    if (sum > remainingQuantity) {
      arrErr.push("Quantity exceeds batch remaining quantity")
    }

    if (arrErr.length > 0) {
      dispatchNotification("error", arrErr);
      return
    }
    else {
      setOpen(true)
    }
  }
  const addInfo = async () => {

    if (rowInfoData.length > 0) {
      const res = await editPackagingInfo(
        param.categoryId,
        param.type,
        param.id,
        chipData.filter((item)=>!item.isDispatched)?.map((data) => ({
          batchId: param.id,
          quantity: data.quantity,
          size: data.size,
          uomId: data.uomId,

        }))
      );
      if (res?.status === 200) {
        arrSucc.push(translate('packagingInfoSavedSuccessfully'));
        setSaveButton(false)
        navigate(`${currentPath}/details/${param.id}`);
        refreshPackagingInfo()
        dispatchNotification("success", arrSucc);
        setIsDispatchDisable(false)
      }
    }
    else {
      const res = await addPackagingInfo(
        param.categoryId,
        param.type,
        param.id,
        chipData.filter((item)=>!item.isDispatched)?.map((data) => ({
          batchId: param.id,
          quantity: data.quantity,
          size: data.size,
          uomId: data.uomId,
        }))
      );
      if (res?.status === 201) {
        setSaveButton(false)
        arrSucc.push(translate('addedPackingInfoToBatch'));
        refreshPackagingInfo()
        navigate(`${currentPath}/details/${param.id}`);
        dispatchNotification("success", arrSucc);
        setIsDispatchDisable(false)
      }
    }
  };
  const handleDeleteRow = (idx) => {
    if (chipData.length > 0) {
      const data = [...chipData];

      data.splice(idx, 1);
      setChipData(data);
    }
  };
  const handleClickCloseDialog = () => {
    setOpen(false);
  };
  const handleClickYes = () => {
    setOpen(false);
    addInfo()
  };
  return (
    <Box>
      <Box>
        <TableContainer sx={{ border: 1, borderColor: " #E0E0E0" }}>
          <Table
            sx={{ minWidth: 900 }}
            className="form-table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                >
                  {translate("S.No")}
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('size')}
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('units')}
                </TableCell>
                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('quantity')}
                </TableCell>

                <TableCell
                  className="table-head"
                  sx={{ border: 1, borderColor: " #E0E0E0", height: "20px" }}
                  align="left"
                >
                  {translate('actions')}
                </TableCell>

              </TableRow>
            </TableHead>

            <TableBody>
              {chipData &&
                chipData.map((data, idx) => (
                  <TableRow
                    key={data.key}
                    sx={{
                      backgroundColor:data.isDispatched?"#DDE6ED":null,
                      "&:last-child td, &:last-child th": {
                        border: 1,
                        borderColor: " #E0E0E0",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      backgroundColor:data.isDispatched?"#DDE6ED":null,

                      }}
                      component="th"
                      scope="row"
                    >
                      {idx + 1}
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      backgroundColor:data.isDispatched?"#DDE6ED":null,

                      }}
                      align="left"
                    >
                      <TextField
                        disabled={isInspector|| data.isDispatched || disablePackageInfo}

                        sx={{ width: "100%", backgroundColor: "#fff",
                        backgroundColor:data.isDispatched?"#DDE6ED":null,
                      
                      }}
                        name="size"
                        id={`outlined-basic-${data.key}`}
                        value={data.size}
                        type="number"
                        variant="standard"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) => {
                          chipData[idx]["size"] = event.target.value;
                          setChipData([...chipData])
                          setSize(event.target.value);
                          setSaveButton(true);
                          setIsDispatchDisable(true)
                        }}
                      />
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      backgroundColor:data.isDispatched?"#DDE6ED":null,

                      }}
                      align="left"
                    >
                      <TextField
                        disabled={isInspector || data.isDispatched || disablePackageInfo}

                        sx={{ width: "100%", backgroundColor: "#fff",
                        backgroundColor:data.isDispatched?"#DDE6ED":null,
                      
                      }}
                        name="unit"
                        id={`outlined-basic-${idx}`}
                        value={data.unit}
                        variant="standard"
                        select
                        onChange={(event) => {
                          setUnit(event.target.value);
                          setSaveButton(true);
                          chipData[idx]["unit"] = event.target.value;

                          chipData[idx]["uomId"] = uomData.filter(
                            (e) => e.name === event.target.value
                          )[0].id;
                          chipData[idx]["conversionFactorKg"] = uomData.filter(
                            (e) => e.name === event.target.value

                          )[0].conversionFactorKg;
                          setChipData([...chipData]);
                          setIsDispatchDisable(true)
                        }}
                      >
                        {Array.isArray(uomData) &&
                          uomData?.map((uom, idx) => {
                            return (
                              <MenuItem value={uom.name} key={uom.name}> {translate(uom.name)}</MenuItem>
                            );
                          })}
                      </TextField>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      backgroundColor:data.isDispatched?"#DDE6ED":null,

                      }}
                      align="left"
                    >
                      <TextField
                        disabled={isInspector || data.isDispatched || disablePackageInfo}

                        sx={{ width: "100%", backgroundColor: "#fff",
                      backgroundColor:data.isDispatched?"#DDE6ED":null,
                      backgroundColor:data.isDispatched?"#DDE6ED":null,
                      
                      }}
                        name="quantity"
                        id="outlined-basic"
                        value={data.quantity}
                        type="number"
                        variant="standard"
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) => {
                          chipData[idx]["quantity"] = event.target.value;
                          setChipData([...chipData])
                          setQuantity(event.target.value);
                          setSaveButton(true);
                          setIsDispatchDisable(true)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: " #E0E0E0",
                        height: "20px",
                      }}
                      align="left"
                    >
                    { !data.isDispatched && <Button
                        disabled={isInspector || disablePackageInfo}
                        sx={{ textDecoration: "underline" }}
                        onClick={() => {
                          handleDeleteRow(idx);
                          setSaveButton(true);
                          setIsDispatchDisable(true)
                        }}
                      >
                        {translate('delete')}
                      </Button>}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
                        disabled={isInspector || disablePackageInfo}

          sx={{ textDecoration: "underline" }}
          onClick={() => {
            handleAddItem();
          }}
        >
          <AddCircleOutlineIcon />
          {translate('add')}
        </Button>

        {saveButton == true &&
          <Button
          disabled={isInspector || disablePackageInfo}
          
          onClick={() => { checkValidate(); }}>
            {translate('savePkgInfoBtn')}
          </Button>}

          <Box  sx={{display:"flex",alignItems:"center",gap:4,mt:2}}>
          <Box sx={{display:"flex",alignItems:"center",gap:1}}><Box sx={{width:12,background:"#DDE6ED",height:12}}></Box><span style={{overflow: "hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",fontSize:14}}>{translate('dispatched')}</span></Box>

        <Box sx={{display:"flex",alignItems:"center",gap:1}}><Box sx={{border:"0.1px solid black",width:12,background:"white",height:12}}></Box> <span style={{overflow: "hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",fontSize:14}}>{translate('toBeDispatched')}</span></Box> 
          

          </Box>
      </Box>
      <Box>
        <Dialog
          open={open}
          onClose={handleClickCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {translate('confirmationMsg')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {translate("confirmSavePackagingInfo")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
                        disabled={isInspector || disablePackageInfo}
            
            onClick={handleClickCloseDialog} variant="contained">
              {translate('no')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleClickYes}
              autoFocus
            >
              {translate('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default PakcageInfo;
