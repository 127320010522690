import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton, ListItemText, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../services/StoreHooks";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Label from "@mui/icons-material/Label";
import StyledTreeItem from "../StyledTree/StyledTreeItem";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import "./MenuListComponentNew.css";
import { MILLER_UI_TEXT } from "../../textConstant";
import useTranslatewrapper from "../useTranslateWrapper";

export default function MenuListComponentNew({ menuDetails, isSuperAdmin, isMonitor , isInspector , searchAdmin}) {
  const {translate} = useTranslatewrapper()
  const filterDataRedux = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const [idList, setIdList] = useState([]);

  useEffect(() => {
    if (menuDetails?.length > 0) {
      const arrayOfNodeIds = Array.from(
        Array(
          Number(menuDetails[menuDetails.length - 1].nodeId) +
            menuDetails.length -
            1
        ).keys()
      );
      setIdList(() => arrayOfNodeIds.map((item) => JSON.stringify(item + 1)));
    } else {
    }
  }, [menuDetails]);

  const setIndependentBatch = (param) => {
    dispatch({
      type: "UPDATE_STATE",
      data: param,
    });
  };

  const setFilters = (id, name) => {
    const json = filterDataRedux.searchCriteriaList;
    const json1 = structuredClone(json);
    if(isInspector){
      json1[0].value = "";
      json1[1].value = "";
      json1[2].value = "";
      json1[3].value = [""];
      json1[4].value = [""];
      json1[5].value = [""];


      json1[6].value = [name];
      json1[7].value = [""];
      json1[8].value = [""];
      json1[9].value = [""];

    }
    else{
      if (id.length > 1) id = [null];
      if (id.length == 0) id = [null];
      json1[3].value = id;
    }
    dispatch({
      type: "UPDATE_FILTER",
      data: json1,
    });
  };
  const generateRouteComponentSuperAdmin = (routeDetail, state) => {
    if (state) {
      return state.route;
    } else {
      if (routeDetail.states) {
        return routeDetail.states[0].route;
      } else if (routeDetail.entityStates) {
        return routeDetail.entityStates[0].states[0].route;
      } else {
        return `/admin/iam/categorydoc`;
      }
    }
  };
  const generateRouteComponent = (routeDetail, state=null, headingLevel) => {
    setIndependentBatch(false);
    if (isSuperAdmin || isMonitor) {
      // navigate(generateRouteComponentSuperAdmin(routeDetail, state));
      let routeToNavigate = generateRouteComponentSuperAdmin(routeDetail, state)
      if(window.location.pathname.startsWith('/admin/dashboard') && routeToNavigate.startsWith('/admin/dashboard')
      ){
      window.location.href = ('/admin/dashboard/all/0')
      return;
      } else{
        navigate(routeToNavigate)
      }

    } else {

      if(routeDetail.categoryName == 'Self Declared Premix Batch'){
        navigate('premix/self-declared')
        return;
      }
      if(routeDetail.categoryName == 'Self Declared FRK Batch'){
        navigate('frk/self-declared')
        return;
      }


      const parent = menuDetails.find(
        (item) => item.entityStates[0].categoryId === routeDetail.categoryId
      );
      if (parent?.independentBatch)
        setIndependentBatch(parent?.independentBatch || false);
      if (routeDetail?.categoryName) {
        if (routeDetail?.categoryName == "Manage" && searchAdmin) {
        navigate(`/${routeDetail?.categoryName.toLowerCase()}`)
        return;
      }
      if (routeDetail?.categoryName == "Manage" && isInspector) {
        navigate(`inspect/${routeDetail?.categoryName.toLowerCase()}`)
      }
      else if (routeDetail?.categoryName === "Inventory") {
          const firstParent = menuDetails.find(
            (item) => item.entityStates[0].categoryId === routeDetail.categoryId
          );
          navigate(
            `/${routeDetail?.categoryName.toLowerCase().split(" ").join("-")}/${
              routeDetail?.categoryId
            }/${firstParent.categoryName.toLowerCase().split(" ").join("-")}`
          );
        } else if (parent?.independentBatch) {
          const path =`/${routeDetail?.categoryName.toLowerCase().split(" ").join("-")}/${
            routeDetail?.categoryId
          }/lot`;
          if(isInspector){
            navigate(`/inspect${path}`)
          }
          else{navigate(path)};
        } 
        else {
          let path=''
          if(headingLevel === '1'){
            const isIndependentForCurrentCategory = routeDetail?.entityStates?.filter(ele => ele.name === 'LOT')[0].inventory
            const isBatchOrLotInRoute = isIndependentForCurrentCategory ? 'lot' : 'batch'
            path = `/${routeDetail?.categoryName.toLowerCase().split(" ").join("-")}/${
              routeDetail?.categoryId
            }/${isBatchOrLotInRoute}`;
          } else{
            path = `/${routeDetail?.categoryName.toLowerCase().split(" ").join("-")}/${
              routeDetail?.categoryId
            }/batch`
          }
          if(isInspector){
            navigate(`/inspect${path}`)
          }
          else{navigate(path)};
        }
      } else if (routeDetail?.wildCardEntry === "1") {
        navigate(
          `/inventory/${routeDetail?.categoryId}/${routeDetail?.name.replace(MILLER_UI_TEXT, 'MILLER')
            .toLowerCase()
            .split(" ")
            .join("-")}`
        );
    }
    else if (routeDetail?.wildCardEntry === "2") {
      navigate(
        `/manage/${routeDetail?.name.replace(MILLER_UI_TEXT, 'MILLER').toLowerCase()}`
      );

      }
      else if (routeDetail?.wildCardEntry === "4") {
        navigate(
          `inspect/manage/${routeDetail?.name.replace(MILLER_UI_TEXT, 'MILLER').toLowerCase()}`
        );
  
        }
      else if (routeDetail?.name && routeDetail?.nodeId) {
        const categoryName = menuDetails.find(
          (elem) => elem.categoryId === routeDetail.categoryId
        )?.categoryName;
        const path = `/${categoryName.toLowerCase().split(" ").join("-")}/${
          routeDetail?.categoryId
        }/${routeDetail?.name.replace(MILLER_UI_TEXT, 'MILLER').toLowerCase()}`
        if(isInspector){
          navigate(`/inspect${path}`)
        }
        else{navigate(path)};
      }
    }
  };

  return (
    <>
      {idList?.length > 0 && (
        <TreeView
          defaultEndIcon={<div style={{ width: 24 }} />}
          sx={{
            height: 264,
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto",
            fontSize: "12px",
          }}
          expanded={idList}
        >
          {menuDetails &&
            menuDetails.length > 0 &&
            menuDetails.map((menuDetail) => (
              <TreeItem
              className={menuDetail.independentBatch === true ? "customClass" : ""}
                id="heading1"
                key={menuDetail.nodeId}
                nodeId={menuDetail?.nodeId}
                label={(translate(capitalize(menuDetail?.categoryName.toUpperCase())) === 'MILLER') ? translate(MILLER_UI_TEXT) : capitalize(translate(menuDetail?.categoryName.toUpperCase()))}
                onClick={() => {
                  generateRouteComponent(menuDetail, null, '1');

                  if (menuDetail?.entityStates) {
                    setFilters([]);
                  }
                }}
              >
                {/* {console.log(menuDetail, "menudetail")} */}
                {menuDetail?.entityStates &&
                (menuDetail?.categoryName !== 'Self Declared Premix Batch') &&
                (menuDetail?.categoryName !== 'Self Declared FRK Batch') &&
                  menuDetail.entityStates.length > 0 &&
                  menuDetail?.entityStates?.map((states) => (
                    <TreeItem
                    className={menuDetail.independentBatch === true ? "customClass" : ""}                
                      id="heading2"
                      key={states.nodeId}
                      nodeId={states.nodeId}
                      label={capitalize(translate(states.name.toUpperCase()))}
                      onClick={() => {
                        generateRouteComponent(states, null, '2');
                        
                        if (states?.states){
                          setFilters(states?.states[0]?.ids || []);

                        }
                          else{
                            setFilters([]);
                          }
                      }}
                    >
                      {states?.states &&
                        states?.states.length > 0 &&
                        states.states.map((state) => (
                          <StyledTreeItem
                            key={state.nodeId}
                            nodeId={state.nodeId}
                            labelText={capitalize(
                              translate(state.displayName.toLowerCase()).replace('(fr)', '(FR)')
                            )}
                            labelIcon={SupervisorAccountIcon}
                            color="black"
                            bgColor="#e8f0fe"
                            iconName={
                              state.displayName.toLowerCase() === "all"
                                ? states.name.toLowerCase()
                                : state.displayName.toLowerCase()
                            }
                            onClick={() => {
                              setFilters(state.ids,state.displayName);
                              generateRouteComponent(states,state, '3');
                            }}
                          ></StyledTreeItem>
                        ))}
                    </TreeItem>
                  ))}
              </TreeItem>
            ))}
        </TreeView>
      )}
    </>
  );
}
