import Keycloak from "keycloak-js";
import axios from "axios";
import { getRegistrationToken } from "./FireBaseService";
import HttpService from "./HttpService";
import { Navigate } from "react-router";
import { renderApp } from "..";
const IAM_URL = process.env.REACT_APP_BASE_IAM_URL;
const IAM_URL_RICE = process.env.REACT_APP_BASE_IAM_URL_RICE;


const keycloak = new Keycloak({
  url: process.env.REACT_APP_AUTH_SERVER_URL,
  realm: "RiceFortification",
  clientId: "react-ui",
});

let loggedInThroughKeycloak = 'false'

if(sessionStorage.getItem('loggedInFromKeyCloak') != null){
  loggedInThroughKeycloak = sessionStorage.getItem('loggedInFromKeyCloak')
} 

const initKeycloak = (onAuthenticatedCallback = () => { }) => {

  keycloak
    .init({
      onLoad: "login-required",
      pkceMethod: "S256",
      checkLoginIframe: false,
      // redirectUri: window.location.origin,
    })
    .then(async (authenticated) => {
      if (authenticated) {
        const locale = keycloak?.tokenParsed?.locale
        if(['en','si','ta'].includes(locale)){
          sessionStorage.setItem('langFromKcToken', locale)
        }
        sessionStorage.setItem('loggedInFromKeyCloak', 'true')
        sessionStorage.setItem("react-token", keycloak.token || "no");
        const user = await GetIAMUserDetails();
        sessionStorage.setItem("user", JSON.stringify(user.data));
        onAuthenticatedCallback();
        if (process.env.REACT_APP_FIREBASE_VAPID_KEY) {
          getRegistrationToken()
        }
      } else {
        doLogin();
      }
    });
};

const clearAllLocalstorage = () => {
  localStorage.removeItem("currentPath");
  localStorage.removeItem("role");
  localStorage.removeItem("isAdmin");
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("react-token");
  localStorage.removeItem("firebase-reg-token")
  localStorage.removeItem('preferredLang')
  sessionStorage.removeItem('loggedInFromKeyCloak')
  sessionStorage.removeItem('langFromKcToken')
};

const doLogin = keycloak.login;

const doLogout = () => {
  clearAllLocalstorage();

  try {
    keycloak.logout({ redirectUri: window.location.origin });
  } catch (error) {    
    window.location.href = '/'
    // window.location.reload();
  }
};

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = async (successCallback) => {
  await keycloak.updateToken(5).then(successCallback).catch(doLogin);
}

keycloak.onTokenExpired = () => {
  updateToken().then(() => {
    if (keycloak?.token) {
      sessionStorage.setItem("react-token", keycloak.token || "no");
    }
  });
};

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getUserEmail = () => keycloak.tokenParsed?.email;

const getEmployeeId = () => keycloak.tokenParsed?.employeeId;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const getUserDetail = () => {
  const user = sessionStorage.getItem("user");
  return JSON.parse(user);
};

const GetIAMUserDetails = async () => {
  try {
    return axios.get(`${IAM_URL_RICE}user/details`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  } catch (error) { }
};

const GetIAMUserDetailsFromToken = async (token) => {
  try {
    return axios.get(`${IAM_URL_RICE}user/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) { }
};

const setConfigurationOnLoggingInWithToken = async () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromParam = queryParams.get('token');
  if (tokenFromParam) {
    sessionStorage.setItem("react-token", tokenFromParam || "no");
    const user = await GetIAMUserDetailsFromToken(tokenFromParam);
    sessionStorage.setItem("user", JSON.stringify(user.data));
  } else {
    const user = await GetIAMUserDetailsFromToken(sessionStorage.getItem('react-token'));
    sessionStorage.setItem("user", JSON.stringify(user.data));
  }
  HttpService.configure();
  sessionStorage.setItem('loggedInFromKeyCloak', 'false')
}

const isSuperAdmin = getUserDetail()?.isSuperAdmin;
const isInspector = getUserDetail()?.isInspector;


const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserEmail,
  getEmployeeId,
  hasRole,
  getUserDetail,
  isSuperAdmin,
  isInspector,
  loggedInThroughKeycloak,
  setConfigurationOnLoggingInWithToken
};

export default KeycloakService;
