import React, { ReactElement, FC, useEffect, useCallback, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MenuItem, Select, Typography, Pagination, IconButton, InputAdornment, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MAIN_BLUE, WHITE_TEXT } from "../../color";
import exportFromJSON from 'export-from-json';
import UnitConverterWeight from "../../utils/UnitConverter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { getInventoryExcel } from "../../services/FortificationService";
import { useAppSelector } from "../../services/StoreHooks";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import useTranslatewrapper from "../../components/useTranslateWrapper";

const theme = createTheme({
  palette: {
    primary: {
      main: MAIN_BLUE,
      contrastText: WHITE_TEXT,
    },
  },
});
const rowHeight = 40;

const GridItem = (props) => {
    const {translate} = useTranslatewrapper()
  
  const routeParams = useParams();
  const [gotData, setGotData] = React.useState(false);
  const [type, setType] = React.useState("");
  const [columnHeaderMap, setColumnHeaderMap] = useState({});
  const [searchKeyWord, setSearchKeyWord] = useState("");

  const categoryNameToIdSelectorRedux = useAppSelector((state) =>
    state?.categoriesData?.categories
  );

  // const categoryNameToIdMapRedux = useMemo(()=>{
  //   return (
  //     categoryNameToIdSelectorRedux.reduce(
  //     (acc, current) => {
  //       acc[current.name] = current.id.toString();
  //       return acc;
  //     },
  //     {}
  //   ))
  // }, [categoryNameToIdSelectorRedux])

  const categoryIdToNameMapRedux = useMemo(()=>{
    return (
      categoryNameToIdSelectorRedux.reduce(
      (acc, current) => {
        acc[current.id.toString()] = current.name;
        return acc;
      },
      {}
    ))
  }, [categoryNameToIdSelectorRedux])

  useEffect(() => {
    const tempColumnHeaderMap = props.columnDefs.reduce((acc, column) => {
      if (column.field && !column.hide) {
        acc[column.field] = column.headerName;
      }
      return acc;
    }, {});
    setColumnHeaderMap(tempColumnHeaderMap);
  }, [props.columnDefs])

  const valueFormaterKeys = ['totalQuantity', 'remainingQuantity'];
  const keysToTranslate = ['state']
  let fData = []
  let fDataNoUnits = []
  const generateExcelData = async ()=> {
    let totalRowData
    if(props.getAllRowsData){
      totalRowData = await props.getAllRowsData()
    } else{
      totalRowData = props.rowData
    }
    fData = [];
    fDataNoUnits = [];
    let count = 1;

    totalRowData.forEach((x) => {
      const obj = {};
      const objNoUnits = {}
      obj[translate("S.No")] = count;
      count++;

      props.columnDefs.forEach((column) => {
        if (column.field && columnHeaderMap[column.field]) {
          let value = x[column.field];
          objNoUnits[columnHeaderMap[column.field]] = value

          // Apply valueFormatter if present
          if (column.valueFormatter) {
            const params = { data: x, value }; // Mimic AG Grid params
            value = column.valueFormatter(params);
          }
          // if (column.field === "totalQuantity" || column.field === "remainingQuantity") {
          //   value = UnitConverterWeight(value, translate);
          // } else 
          if (keysToTranslate.includes(column.field)) {
            value = translate(value?.toLowerCase());
          }

          obj[columnHeaderMap[column.field]] = value;
        }
      });

      fData.push(obj);
      fDataNoUnits.push(objNoUnits)
    });
  }
  
  

  useEffect(() => {}, []);

  const handleChange = (event, value) => {
    props.setPage(value);
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 != 0) {
      return { background: "#F8F8F8" };
    }
  };

  const formatDateToDescriptive = (dateStr)=>{
    if(!dateStr){
      return ""
    }
    const date = new Date(dateStr);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const getCurrentTimestamp = () =>{
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replaceAll('/','-');
    
    const formattedTime = now.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replaceAll(':','-');

    return (formattedDate+"Time"+formattedTime)
  }

  const getFileNameForExcel = () =>{
    let fileName;
    if(props.isLotList){
      fileName = categoryIdToNameMapRedux[props.query['categoryId']].replace('MILLER','MILLER(FR)')+'_lots_list_'+ getCurrentTimestamp()
    } else if(props.isBatchList){
      fileName = categoryIdToNameMapRedux[props.query['categoryId']].replace('MILLER','MILLER(FR)')+'_batches_list_'+ getCurrentTimestamp()
    } else if(props.title){
      fileName =props.title + "_"+getCurrentTimestamp()
    }else if(window.location.pathname.includes("inventory")){
      fileName = routeParams.type + " Inventory" +"_"+ getCurrentTimestamp()
    } else{
      fileName = 'excel'+"_"+ getCurrentTimestamp()
    }
    return fileName
  }

  const getHeaderForExcel = () =>{

    let cellTypeToTitleMap = {
      "totalProduction": "total_production",
      "availableTested": "available_quantity",
      "transitQuantity":"in-transit-quantity",
      "approvedQuantity":"accepted_quantity",
      "lotRejected":"rejected_quantity",
      "usedQuantity":"consumed_quantity",
      "sampleInTransit":"in-transit_for_testing",
      "batchTestApprovedQuantity":"tested_approved_quantity",
      "batchTestRejectedQuantity":"tested_rejected_quantity",
      "batchTestedQuantity":"tested_quantity",
      "batchNotTestedQuantity":"not_tested_quantity"

    }

    let header;
    if(props.isLotList){

      header = `${props.query['sourceManufacturerName']} dispatched the below ${categoryIdToNameMapRedux[props.query['categoryId']].replace('MILLER','MILLER(FR)')} lots to ${props.query['targetManufacturerName']} (${formatDateToDescriptive(props.query['fromDate'])} to ${formatDateToDescriptive(props.query['toDate'])})`
    } else if(props.isBatchList){
      let cellTypeToHeaderText = (!props.query['cellType'] || !cellTypeToTitleMap[props.query['cellType']]) 
      ? "" : ` (${cellTypeToTitleMap[props.query['cellType']]})`

      header = `${categoryIdToNameMapRedux[props.query['categoryId']].replace('MILLER','MILLER(FR)')} batches of ${props.query['sourceManufacturerName']}${cellTypeToHeaderText} (${formatDateToDescriptive(props.query['fromDate'])} to ${formatDateToDescriptive(props.query['toDate'])})`
    } else if(props.title){
      header =props.title + "_"+Date.now()
    }else if(window.location.pathname.includes("inventory")){
      header = routeParams.type + " Inventory" +"_"+ Date.now()
    } else{
      header = 'excel'+"_"+ Date.now()
    }
    return header;
  }

  const exportToExcel = async () => {
    // if(window.location.pathname.includes("inventory")){
    //   const lotOrBatch = props.fetchLotOrBatch()
    //   getInventoryExcel(routeParams.categoryId, lotOrBatch, props.filterDataRedux, routeParams.type)

    // } else{
      await generateExcelData();

      // const headersToSum = [
      //   "totalQuantity",
      //   "remainingQuantity",
      //   "Data",
      //   "Total Quantity",
      //   "Remaining Quantity",
      //   "Quantity Used",
      //   "Lot Approved Quantity",
      //   "Lot Rejected Quantity",
      //   "Lot Pending For Approval",
      //   "Available Quantity",
      //   "No. Of Batches"
      // ];
      const headersToSum = [
        "totalQuantity",
        "remainingQuantity",
        "Data",
        translate('totalQuantity'),
        translate('remainingQuantity'),
        translate("Quantity Used"),
        "Lot Approved Quantity",
        "Lot Rejected Quantity",
        "Lot Pending For Approval",
        "Available Quantity",
        "No. Of Batches"
      ];
      const sums = headersToSum.reduce((acc, header) => {
        const headersWithoutUnits =  ["No. Of Batches"]
        acc[header] = headersWithoutUnits.includes(header)
          ? fDataNoUnits.reduce((sum, item) => sum + (item[header] || 0), 0)
          : UnitConverterWeight(
              fDataNoUnits.reduce((sum, item) => sum + (item[header] || 0), 0), translate
            );
        return acc;
      }, {});


      const fileNameForExcel = getFileNameForExcel();
      const headerForExcel = getHeaderForExcel();

      const headers = Array.from(new Set(fData.flatMap(Object.keys)));
      const numberOfColumns = headers.length;
      
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');

      if(props.showBatchesOrLotsForMonitor){

        const endColumn = String.fromCharCode(65 + numberOfColumns - 1);
        const mergeRange = `A1:${endColumn}1`;
  
        worksheet.mergeCells(mergeRange);
        worksheet.getCell('A1').value = headerForExcel;
        worksheet.getCell('A1').alignment = { horizontal: 'left' };
        worksheet.getCell('A1').font = { bold: true, size: 12 };
  
        worksheet.getRow(3).values = headers;
  
        worksheet.getRow(3).font = { bold: true };

      } else{
        worksheet.getRow(1).values = headers;
  
        worksheet.getRow(1).font = { bold: true };
      }
      fData.forEach((item) => {
        const rowValues = headers.map(header => item[header] !== undefined ? item[header] : '');
        worksheet.addRow(rowValues);
      });

      worksheet.addRow([]);

      const footerRow = worksheet.addRow([]);
      footerRow.getCell(1).value = translate('total');
      footerRow.getCell(1).font = { bold: true };

      headersToSum.forEach((header) => {
        if(headers.includes(header)){
          const columnIndex = headers.indexOf(header) + 1; // +1 because ExcelJS is 1-based index
          footerRow.getCell(columnIndex).value = sums[header];
          footerRow.getCell(columnIndex).font = { bold: true };
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileNameForExcel}.xlsx`);

    // }
  };

  return (
    <div
      className={
        props.height == "main"
          ? "ag-theme-alpine grid-container-home"
          : "ag-theme-alpine grid-container"
      }
    >

      <div style={{ display: "flex", justifyContent: "space-between",
      margin:"0px 10px 5px 10px", 
      alignItems:"end" }}>

      {props.showUiSearchBar && (
      <div >
        <TextField
          id="outlined-required"
          label="Search by name"
          value={searchKeyWord}
          onChange={(e)=>{setSearchKeyWord(e.target.value)}}
          InputProps={{
            style: {
              borderRadius: 40,
              height: '45px',
              textAlign:"center"
            },
            endAdornment: 
              (<InputAdornment position='end'>
                <IconButton onClick={()=>{setSearchKeyWord("")}}>
                  <ClearIcon/>
                </IconButton>
              </InputAdornment>)
          }}
        />
      </div>
      )}

      {props.showDownloadButton && (
      <div>
        <a
          onClick={exportToExcel}
          href="javascript:void(0)"
        >
          <span style={{ fontSize: "1rem" }}>{translate('download')}</span>
          <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} m={1} />
        </a>
      </div>)}

      </div>


      <AgGridReact
        ref={props.gridRef}
        rowHeight={rowHeight}
        // rowData={props.rowData}
        rowData={
          props.showUiSearchBar
            ? props.isLotList
              ? props?.rowData?.filter((item) =>
                  item.lotNo
                    ?.toLowerCase()
                    .includes(searchKeyWord.toLowerCase())
                )
              : props?.rowData?.filter((item) =>
                  item.batchNo
                    ?.toLowerCase()
                    .includes(searchKeyWord.toLowerCase())
                )
            : props.rowData
        }
        columnDefs={props.columnDefs}
        defaultColDef={props.defaultColDef}
        getRowStyle={getRowStyle}
        onGridReady={props.onGridReady}
        autoGroupColumnDef={props.autoGroupColumnDef}
        suppressRowClickSelection={props.suppressRowClickSelection}
        groupSelectsChildren={props.groupSelectsChildren}
        rowSelection={props.rowSelection}
        rowGroupPanelShow={props.rowGroupPanelShow}
        pivotPanelShow={props.pivotPanelShow}
        enableRangeSelection={props.enableRangeSelection}
        pagination={props.pagination}
        onSelectionChanged={props.onSelectionChanged}
        localeText={props.localeText}
        // rowModelType="serverSide"
        // serverSideStoreType="partial"
        onFilterChanged={props.onFilterChanged}
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        enableBrowserTooltips={true}
      />
      {props.pagination !=false && (
        <ThemeProvider theme={theme}>
          <Pagination
            count={props.count}
            shape="rounded"
            color="primary"
            onChange={handleChange}
            page={props.page}
          />
        </ThemeProvider>
      )}
    </div>
  );
};

export default GridItem;
