export const SuccessMessageSelector = (status, type) => {
    switch (status) {
        case "Created":
          return [`${type} has been created`]
        case "Sample sent to Lab":
          return ["sampleSentToLabToastMsg"]
        case "Sample in Lab":
           return ["Sample has been received in Lab"]
        case "Sample tested":
          return ["Sample Reports have been updated"];
        case "Rejected":
          return [`${type} is rejected`];
        case "Batch ready to be dispatched":
          return ["batchReadyToBeDispatchedToastMsg"];
        case "Lot dispatched":
          return ["lotDispatchedToastMsg"];
        case "Lot received":
            return ["lotReceivedToastMsg"]
        case "Self tested":
            return [`Self Tested the ${type} and uploaded the results`]
        case "Accepted":
            return [`${type}AcceptedToastMsg`];
        case  "Lot to send back":
            return ["lotToSendBackToastMsg"];
        case "Lot sent back":
            return ["lotSentBackToastMsg"];
        case  ["Rejected lot received"]:
            return ["rejectedLotReceivedToastMsg"];
        default:
          return ["Success"];
    }
}